import { GET_USERS, API_ERROR } from './types';
import { apiCall } from '../helpers';
import { LAMDA_APICALL, GET_CONFIRMED_USERS, GET_ONE_USER } from '../apiurl';
import {ERRORS} from '../constants/Constants';

//action to fetch confirmed users on admin Dashboard 
//Aditya@20022020
export const getUsers = (data) => async dispatch => {
        console.log(data, 'data')
        console.time("apicall")
        await apiCall('post', LAMDA_APICALL, {
                url: GET_CONFIRMED_USERS,
                method: "POST",
                data: data
        }).then(response => {
                console.timeEnd("apicall")
                if (response.status) {
                        dispatch({
                                type: GET_USERS,
                                payload: response.data
                        })
                } else {
                        dispatch({
                                type: API_ERROR,
                                payload: ERRORS.API.USER
                        })
                }
        }).catch(() => {
                console.timeEnd("apicall")
                dispatch({
                        type: API_ERROR,
                        payload: ERRORS.API.USER
                })
        })



};



export const getOneUser = (data) => async dispatch => {
        console.log(data, 'data')
        console.time("apicall")
        await apiCall('post', LAMDA_APICALL, {
                url: GET_ONE_USER,
                method: "POST",
                data: data
        }).then(response => {
                console.timeEnd("apicall")
                if (response.status) {
                        dispatch({
                                type: GET_USERS,
                                payload: response.data
                        })
                } else {
                        dispatch({
                                type: API_ERROR,
                                payload: ERRORS.API.USER
                        })
                }
        }).catch(() => {
                console.timeEnd("apicall")
                dispatch({
                        type: API_ERROR,
                        payload: ERRORS.API.USER
                })
        })



};
