import React from 'react';
import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import { getUsers } from '../../actions/adminAction';
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ViewContext, Button, Icon, LoadingButton } from '../../components/lib';
import { ACCOUNT_TYPE as account_type, STATUS as status, TIME as time } from '../../constants/Constants';
import moment from 'moment';
import UpdateForm from '../../components/form/UpdateForm';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, GET_DASHBOARD_ITEMS, UPDATE_DASHBOARD_ITEMS } from '../../apiurl';
import Checkbox from 'components/form/checkbox/newCheckbox';


class AppHomeManagement extends React.Component {

  constructor(props) {

    super(props);
    this.access = [];
    this.component = "APP_MGMT"
    this.subComponent = "AD_APP_HOME"
    this.state = {
      search: "",
      close: false,
      current_user: {},
      data: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      searchVal: '',
      columnSort: '',
      sortType: '',
      users: [],
      account: '',
      status: '',
      time: '',
      per_page: 10,
      page: 1,
      loadmoreBtn: false,
      loadmoreValue: '',
      btnStatus: false,
      btnColorLoad: '',
      btnTextLoad: 'Export Account Data',
      buttonStyle: { display: 'block' },
      dashboardItems: [],
      otherItems: []
    };
  }

  componentDidMount = async () => {
    let partner_id = this.getPartnerFromLocal()
    // this.access = user.permissions.find(roles => roles.key == this.component).sub[1].inner;
    await axiosCall('post', LAMDA_APICALL, {
      url: GET_DASHBOARD_ITEMS,
      method: "POST",
      data: { "partner_id": partner_id }
    }, (response) => {
      console.log("resp", response)
      if (response && response.data)
        this.setState({
          dashboardItems: response.data.dashboardItems,
          otherItems: response.data.otherItems
        })
    })

  }

  componentDidUpdate(prevProps) {
    if (this.props.error != prevProps.error) {
      this.context.showNotification(this.props.error, 'error', true)
    }
  }
  getPartnerFromLocal = () => {
    var id = localStorage.partner_idd && parseInt(localStorage.partner_idd)
      ? parseInt(localStorage.partner_idd) : (localStorage.partner_id && parseInt(localStorage.partner_id)
        ? parseInt(localStorage.partner_id) : 0)

    console.log(id, 'getPartnerFromLocal--------------')
    return id
  }

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    console.log("dashboardItems", this.state.dashboardItems)
    const items = Array.from(this.state.dashboardItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({ dashboardItems: items })
  }

  removeItem = (id, index) => {
    console.log(id, index, " id number closed")
    const items = Array.from(this.state.dashboardItems);
    let newItems = items.splice(index, 1)
    console.log({ newItems, items })

    const otherItems = [...this.state.otherItems, ...newItems]
    this.setState({ dashboardItems: items, otherItems })
  }

  addItem = (id, index) => {
    console.log(id, index, " id number added")
    const items = Array.from(this.state.otherItems);
    let newItems = items.splice(index, 1)
    console.log({ newItems, items })

    const dashboardItems = [...this.state.dashboardItems, ...newItems]
    this.setState({ dashboardItems, otherItems: items })
  }

  updateItems = async () => {
    let items = this.state.dashboardItems.map((item, index) => {
      return {
        category_code: item.key,
        order: index + 1,
        enabled: true
      }
    })
    const count = items.length
    this.state.otherItems.map((item, index) => {
      items.push({
        category_code: item.key,
        order: count + index + 1,
        enabled: false
      })
    })
    const body = {
      partner_id: this.getPartnerFromLocal(),
      items
    }
    console.log("body of update", body)
    await axiosCall('post', LAMDA_APICALL, {
      url: UPDATE_DASHBOARD_ITEMS,
      method: "POST",
      data: body
    }, (response) => {
      console.log("resp", response)
    })
  }
  render() {
    const { loading, data, partner, dashboardItems, otherItems } = this.state;

    createTheme('solarized', {
      text: {
        primary: '#717171',
        secondary: '#268bd2',
      },
      background: {
        default: '#ffffff',
      },
      divider: {
        default: '#1595c5',
      },
      button: {
        default: 'rgb(38, 139, 210)',
        hover: 'rgba(0,0,0,.08)',
        focus: 'rgba(255,255,255,.12)',
        disabled: 'rgba(0,0,0,.12)',
      },
    });
    const halfLength = Math.ceil(dashboardItems.length / 2);
    const firstColumnItems = dashboardItems.slice(0, halfLength);
    const secondColumnItems = dashboardItems.slice(halfLength);
    return (
      <section className="dragdrop-wrp">
        <div>
          <Checkbox
            className="checkbox"
            defaultChecked
            onChange={this.onCheckBox}
            name={"Button 1"}
          />
          <Checkbox
            className="checkbox"
            defaultChecked
            onChange={this.onCheckBox}
            name={"Button 2"}
          />
        </div>
      </section>
    );
  }
}
AppHomeManagement.contextType = ViewContext;
const mapStateToProps = state => ({
  user: state.users.user,
  error: state.error.error,
  partner: state.admin.partner,
})
export default connect(mapStateToProps, { getUsers })(AppHomeManagement);
