/***
*
*   TABLE
*   Please view the full documentation: https://docs.usegravity.app/ui/table
*
*   PROPS
*   data: object containing header (optional) and body
*   search: true/false to show the search field
*   loading: true/false to toggle loading spinner
*   badge - object containing column name and color to add badges to column
*   showIds - true/false to render the row IDs in the table
*   naked - don't render any styling
*
**********/

import React from 'react';
import Axios from 'axios';

import { Loader, ViewContext, Search } from 'components/lib';
import { Header } from './header';
import { Body } from './body';
import './table.scss';

export class Table extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      header: [],
      body: [],
      filteredRows: false,
      loading: this.props.loading,

    };

    this.sort = this.sort.bind(this);
    this.search = this.search.bind(this);

  }

  componentDidMount(){

    // if the data wasn't passed as an
    //object, get it from the server
    if (typeof this.props.data === 'string'){

      this.getData();

    }
    else if (typeof this.props.data === 'object'){

      this.setState({

        header: this.props.data.header,
        body: this.props.data.body

      }, () => this.createHeader());
    }
  }

  componentDidUpdate(prevProps){

    if (prevProps.data === this.props.data){

      return false;

    }
    else {

      this.setState({

        header: this.props.data.header,
        body: this.props.data.body

      }, () => this.createHeader());
    }
  }

  async getData(){

    try {

      let data;
      this.setState({ loading: true });

      const res = await Axios.get(this.props.data);

      if (this.props.object)
        data = res.data[this.props.object];
      else
        data = res.data.data;

      this.setState({

        loading: false,
        body: data

      }, () => this.createHeader());
    }
    catch (err){

      this.context.handleError();

    }
  }

  createHeader(){

    let header = [];

    // if user provided headers – use them
    // otherwise, create the headers using the body
    if (!this.props.data.header){
      if (this.state.body){
        for (let key in this.state.body[0]){
          if (key === 'id'){
            if (this.props.showIds){

              let title = key.replace('_', ' ');
              header.push({ name: key, title: title, sort: true });

            }
          }
          else {

            let sort = true;
            let title = key.replace('_', ' ');
            if (key === 'actions') sort = false;
            header.push({ name: key, title: title, sort: sort });

          }
        }
      }
    }
    else {

      header = this.props.data.header;

    }

    this.setState({ header: header });

  }

  sort(column, direction){

    // sort the table
    let state = Object.assign({}, this.state);

    // sort filtered rows?
    let rows =
    state.filteredRows.length ? state.filteredRows : state.body;

    rows.sort(function(a,b){

      if (a[column] && b[column]){

        a[column].badge ?
          a = a[column].label : a = a[column];

        b[column].badge ?
          b = b[column].label : b = b[column];

        if (direction === 'desc'){

          if (a > b) return -1;
          if (a < b) return 1;
          else return 0;

        }
        else {

          if (a < b) return -1;
          if (a > b) return 1;
          else return 0;

        }
      }
      else {

        return false;

      }
    });

    if (state.filteredRows.length) state.filteredRows = rows;
    else state.body = rows;

    this.setState(state);

  }

  search(term){

    // search each cell in each row for the search term
    // then update the state to show only the filtered
    // rows that match the search term
    let rowsToShow = [];

    for (let i = 0; i < this.state.body.length; i++){

      const row = this.state.body[i];

      for (let cell in row){
        if (row[cell].toString().toLowerCase().includes(term.toLowerCase())){

          if (!rowsToShow.includes(row)){
            rowsToShow.push(row);

          }
        }
      }
    }

    this.setState({ filteredRows: rowsToShow });

  }

  render(){

    if (!this.state.header)
      return <table />

    // check if parent is loading
    if (this.props.loading || this.state.loading)
      return <Loader className='table-loader' />

    return (

      <>
      { this.props.search &&
        <Search callback={ this.search }/>
      }

      <table className={ !this.props.naked && 'styled' }>

        { this.state.header &&

          <Header
            data={ this.state.header }
            callback={ this.sort }
            actions={ this.props.children }
            showIds={ this.props.showIds }
          />

        }
        { this.state.body &&

          <Body
            data={ this.state.filteredRows ? this.state.filteredRows : this.state.body }
            showIds={ this.props.showIds }
            badge={ this.props.badge }
            actions={ this.props.children }
          />
        }

      </table>
      </>
    );
  }
}

export function DeleteTableRow(table, id){

  const index = table.findIndex(x => x.id === id);
  table.splice(index, 1);

}

export function UpdateTableRow(table, data, id){

  const index = table.findIndex(x => x.id === id);
  for (let key in data) table[index][key] = data[key].value;

}

Table.contextType = ViewContext;
