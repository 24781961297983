import { apiCall } from '../../helpers';
import { KIT_VALIDATION, LAMDA_APICALL, MUHDO_ADD_KIT,TBG_ADD_KIT,BIO_ADD_KIT,NUROKOR_ADD_KIT, RELEASE_KIT, DELETE_USER, GET_COUNTRIES, UPDATE_USER, UPDATE_ACCESS, GET_RAWDATA ,RESEND_CONFIRM_EMAIL} from '../../apiurl';
export const validateKit = async (data) => {
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: KIT_VALIDATION,
      method: "POST",
      data: data
    });
    return res.message.message;
  } catch (e) {
    return e.message.message
  }

}
export const addKit = async (data,partner_id) => {
  try {
    console.log(partner_id,"partner_id")
    let url = MUHDO_ADD_KIT
    if(partner_id == 1){
      url = MUHDO_ADD_KIT
    }else if(partner_id == 5){
      url = TBG_ADD_KIT
    }else if(partner_id == 6){
      url = NUROKOR_ADD_KIT
    }else if(partner_id == 7) {
      url = BIO_ADD_KIT
    }
    console.log(url,"urlllllllllllllllll")
    let res = await apiCall('post', LAMDA_APICALL, {
      url: url,
      method: "POST",
      data: data
    });
    return res;
  } catch (e) {
    return e.message.message
  }

}
export const resendConfirm = async (data) => {
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: RESEND_CONFIRM_EMAIL,
      method: "POST",
      data: data
    });
    return res.message;
  } catch (e) {
    return e.message.message
  }

}
export const releaseKit = async (data) => {
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: RELEASE_KIT,
      method: "POST",
      data: data
    });
    return res;
  } catch (e) {
    return e.message
  }
}

export const deleteUser= async (data) => {
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: DELETE_USER,
      method: "POST",
      data: data
    });
    return res.message;
  } catch (e) {
    return e.message.message
  }
}
export const getCountries= async () => {
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: GET_COUNTRIES,
      method: "GET"
    });
    return res.data;
  } catch (e) {
    console.log(e)
  }
}
export const updateUser= async (data) => {
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: UPDATE_USER,
      method: "POST",
      data: data
    });
    return res;
  } catch (e) {
    return e.message.message
  }
}
export const updateAccess= async (data) => {
  delete data.error;
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: UPDATE_ACCESS,
      method: "POST",
      data: data
    });
    return res;
  } catch (e) {
    return e.message.message
  }
}
export const getRawdata= async (data) => {
  try {
    let res = await apiCall('post', LAMDA_APICALL, {
      url: GET_RAWDATA,
      method: "POST",
      data: data
    }); console.log(res,'response ')
    return res.data;
   
  } catch (e) {
    return e.message.message
  }
}
