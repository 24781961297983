/***
*
*   ROW
*   Renders a new row inside a view.
*
*   PROPS
*   align: left/right/center
*   color: dark/tint/brand
*   className: apply a custom css class
*
**********/

import React from 'react';
import './layout.scss';

export class Row extends React.Component {

  render(){

    let cssClass = 'panel-row';
    if (this.props.align) cssClass += ' align-' + this.props.align;
    if (this.props.color) cssClass += ' ' + this.props.color;
    if (this.props.className) cssClass += ' ' + this.props.className;

    if (this.props.header){

      return (
        <header className={ cssClass }>
          <div className='content'>
            { this.props.children }
          </div>
        </header>
      );
    }

    return(
      <section className={ cssClass }>
        <div className='content'>
          { this.props.children }
        </div>
      </section>
    );
  }
}
