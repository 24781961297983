/***
*
*   HEADER
*   used a section break in a form
*
*   PROPS
*   title: string
*   label: optional description label
*
**********/

import React from 'react';

export class FormHeader extends React.Component {

  render(){
    return(

      <header>

        <h2>{ this.props.title }</h2>

        { this.props.label &&
          <p>{ this.props.label }</p>
        }

      </header>

    );
  }
}
