/***
*
*   LOGO
*   Replace the image in /images with your own logo.
*
**********/

import React from 'react';

// import { Link } from 'components/lib';
import muhdoLogo from './images/logo.svg';
import geneLogo from './images/logo2.png';
import trueYouLogo from './images/logo.png';
import tbgLogo from './images/tbg.png';
import bioLogo from './images/logo3.png';
import longLogo from './images/logo4.jpg';
import formulaLogo from './images/formula.png';
import nuroLogo from './images/logo4.png';
import PNLogo from './images/PN.png';
import bodyLogo from './images/BODYOLOGY.jpg';
import transformLogo from './images/TNLogo.png';
import INRG from './images/INRG.png';
import ICPM from './images/ICPM.png'
import valaLogo from './images/vala-logo.svg'
import dnaBeyond from './images/dna_beyond.png'
import optimaLogo from './images/optima_logo.png'
import lifestyleLogo from './images/lifestyle_logo.png';
import universityOfSingapore from './images/NUS.png'
import dioxymeLogo from './images/dioxyme_logo.png'
import myOriginLogo from './images/logo5.png'
import PractitionerLogo from './images/logo6.png'
import truepi from './images/TRUepigenetics.png'
import mamh from './images/mamh.png'
import kokora from './images/kokora.png'
import tohealth from './images/tohealth.png'
import carexera from './images/carexera.png'
import biopod from './images/biopod.png'

import './logo.scss';
import store from '../../store';
export class Logo extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      logo: muhdoLogo
    }



  }
  async componentDidMount() {
    // console.log("LOGO_BB componentDidMount -------------------------------------------------")
    this.setState({
      logo: this.getLogo()
    })
  }
  getLogo() {
    let userLocal = JSON.parse(localStorage.getItem('user'));
    let partnerId
    if (userLocal && userLocal.Partner && userLocal.Partner.partnerId) {
      partnerId = userLocal.Partner.partnerId
    }
    const state = store.getState()
    const partner = state.admin.user.userId ? state.admin.partner : JSON.parse(state.admin.partner)
    if (partner) {
      partnerId = partner
    }
    // console.log("LOGO_BB" , partnerId)
    if (partnerId === 3) {
      return trueYouLogo
    }
    if (partnerId === 5) {
      return tbgLogo
    }
    if (partnerId === 2) {
      return geneLogo
    }
    if(partnerId === 7){
      return bioLogo
    }
    if (partnerId === 11) {
      return formulaLogo
    }
    if (partnerId === 6) {
      return nuroLogo
    }
    if(partnerId === 8){
      return longLogo
    }
    if(partnerId === 4){
      return PNLogo
    }
    if (partnerId === 15) {
      return bodyLogo
    }
    if (partnerId === 13) {
      return transformLogo
    }
    if (partnerId === 16) {
      return valaLogo
    }
    if (partnerId === 18) {
      return INRG
    }
    if (partnerId === 20) {
      return ICPM
    }
    if(partnerId == 14){
      return dnaBeyond
    }
    if (partnerId === 19) {
      return optimaLogo
    }
    if (partnerId === 21) {
      return lifestyleLogo
    }
    if (partnerId === 23) {
      return universityOfSingapore
    }
    if(partnerId == 22){
      return dioxymeLogo
    }
    if(partnerId === 9){
      return myOriginLogo
    }
    if(partnerId === 10){
      return PractitionerLogo
    }
    if(partnerId == 26){
      return truepi
    }
    if(partnerId == 28) {
      return mamh
    }
    if(partnerId == 29) {
      return kokora
    }
    if (partnerId == 30) {
      return tohealth
    }
    if (partnerId == 38) {
      return carexera
    }
    if(partnerId == 45) {
      return biopod
    }
    return muhdoLogo
  }

  async componentDidUpdate(prevProps) {

    let logo = this.getLogo()
    if (prevProps.logo !== this.props.logo) {

      this.setState({
        logo: logo
      })
      // console.log("popopo", this.state.logo)
    }
  }
  render() {
    return (

      <div className="logo">
        <img src={this.state.logo} alt="Logo" />
      </div>

    )
  }
}
