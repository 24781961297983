import React from 'react';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';
import memoize from 'memoize-one';
import { axiosCall } from '../../helpers';
import {  UPDATE_DEMO_USER,EXPORT_USER,GET_DEMO_USERS } from '../../apiurl';
import { getDemoUser } from '../../actions/userAction';
import { connect } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { DataLoader } from 'datatableComponents/DataLoader';
import moment from 'moment';
import 'datatableComponents/style.css';
const columns = memoize(clickHandler => [
  {
    name: 'Activated Time',
    cell: (row) => <div>{new Date(row.activatedTime *1000).toLocaleString()}</div>,
    grow: 2
  },
  {
    name: 'Device ID',
    selector: 'device_id',
    hide: 'md',
    grow: 4.5
  },
  {
    name: 'Email',
    selector: 'email',
    hide: 'md',
    grow : 3.5
  },
  {
    name: 'Expiry Period',
    selector: 'expair_period',
    hide: 'md',
    minWidth: 2
    },
  {
    name: 'Platform',
    selector: 'platform',
    hide: 'md',
    minWidth: 2
  },
  {
    name: 'Access code',
    selector: 'accessCode',
    hide: 'md',
    minWidth: 2
  },
  {
    name: 'Actions',
    cell: (row) => <div><button className="tblAction fas fa-edit" onClick={clickHandler} name="edit" id={row.email} title="Edit raw data"></button></div>,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    minWidth: 2,
  },
]);

class demoUserManagement extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      data: [],
      loading: false,
      perPage: 100,
      searchVal: '',
      prevData: [],
      blackListedState: null,
      loadmoreBtn: false,
      loadmoreValue: '',
      btnStatus: false,
      btnColorLoad: 'blue',
      btnTextLoad: 'More',
      buttonStyle: { display: 'block' },
    };

    this.editUserForm = {
      key: {
        label: 'Email',
        type: 'hidden',
        required: true,
        disabled : true
      },
      expair_period: {
        label: 'Expiry Period (in hours)',
        type: 'text',
        required: true
      },
      platform : {
        label: 'platform',
        type: 'hidden',
      }
    };
     this.edit = this.edit.bind(this);
  }
  handleButtonClick = async (e) => {
    let actionName = e.target.name;
    let id = e.target.id;
    let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd'))|| parseInt(localStorage.getItem('partner_id'))
    await axiosCall('post', GET_DEMO_USERS, {"data":{"search":id,partnerId}}, (response) => {
      console.log("response",response)
      if (actionName === "edit") {
        response.data[0].active === false ? response.data[0].active = 'false' : response.data[0].active = 'true'
        response.data[0].isActive === false ? response.data[0].isActive = 'false' : response.data[0].isActive = 'true'
        let data = {
          key: response.data[0].email,
          expair_period: response.data[0].expair_period,
          isActive : response.data[0].active ? response.data[0].active : response.data[0].isActive,
          platform : response.data[0].platform
        }
        this.edit(data);
      }
    })
  }

  async componentDidMount() {
     
    this.setState({ loading: true });
    await this.getDemoDetails()
    this.setState({
      data: this.props.demoDetail,
      prevData: this.props.demoDetail
    });
    await this.checkButtonStaus();
    this.setState({ loading: false });

  }

   // edit user  
    edit(data) {
      // populate the data;
      for (let key in this.editUserForm){
      this.editUserForm[key].value = data[key];      }
      let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd'))|| parseInt(localStorage.getItem('partner_id'))
      this.editUserForm.partnerId = {value : partnerId,type : "hidden",name :"partnerId"}
      //show modal
      this.context.showModal({
        title: 'Edit User',
        text:'EMAIL :  ' + this.editUserForm.key.value,
        form: this.editUserForm,
        buttonText: 'Save',
        url: UPDATE_DEMO_USER ,
        method: 'POST'
      }, async (res) => {
        // show notification message
        this.context.showNotification(data.email + ' was updated', 'success', true);
        //set state data - to update table data
        this.setState({ loading: true,loadmoreValue:'' });
        await this.getDemoDetails();
          this.setState({
          data: this.props.demoDetail,
          prevData: this.props.demoDetail,
          loadmoreValue:this.props.demoDetail[this.props.demoDetail.length - 1].email,
          loading: false
        });
        await this.checkButtonStaus();
        console.log("this.state.loadmoreValue",this.state.loadmoreValue)
      });
  }


  getDemoDetails = async () => {
    let data = {
      ...this.state.loadmoreValue && { lastEval: { "lastkey": this.state.loadmoreValue } },
      ...this.state.searchVal && { search: this.state.searchVal.toUpperCase() },
      ...this.state.perPage && { limit: this.state.perPage },
      ...(this.state.blackListedState != null && this.state.blackListedState !== undefined) && { blacklisted: this.state.blackListedState },
    }
    let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd'))|| parseInt(localStorage.getItem('partner_id'))
    await this.props.getDemoUser({ "lastkey": this.state.loadmoreValue ,"search":this.state.searchVal , partnerId});
  }


  checkButtonStaus = async () => {

    if (this.props.demoDetail.length == 10) {
      this.setState({
        loadmoreValue: this.props.demoDetail[9].email,
        btnStatus: false,
        btnColorLoad: 'blue',
        btnTextLoad: 'More',
        buttonStyle: { display: 'block' }
      });

    } else {
      this.setState({
        btnStatus: true,
        btnColorLoad: 'red',
        btnTextLoad: 'End',
        buttonStyle: { display: 'none' }
      });
    }
  };

  loadMoreData = async () => {
    //let pData = this.state.prevData;
    this.setState({ loadmoreBtn: true });
    let count = 10;
    await this.getDemoDetails();
    if (this.props.demoDetail.length > 0) {
      this.props.demoDetail.forEach(items => {
        this.state.data.push(items);
      });
    }
    await this.checkButtonStaus();
    this.setState({
      loadmoreBtn: false
    });
  }

  searchCall = async (val) => {
    await this.setState({ searchVal: val, loadmoreValue: '' });
    console.log("search value",this.state.searchVal)
    await this.getDemoDetails();
    this.setState({ data: this.props.demoDetail });
    await this.checkButtonStaus();
  };

  export = async () => {
    this.setState({loadmoreBtn: true});
     let {email, name} = JSON.parse(localStorage.getItem('user'))
     let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd'))|| parseInt(localStorage.getItem('partner_id'))
     await axiosCall('post', EXPORT_USER, {
        data: {
          email,name,partnerId
        }
    }, (response) => {
        if (response.status) {
          this.context.showNotification('Success. The exported list has been sent to your email', 'success', true)
        } else {
            this.context.showNotification(this.props.error, 'error', true)
        }
    });
    this.setState({loadmoreBtn: false});
}

  render() {
    const { loading, data } = this.state;
    createTheme('solarized', {
      text: {
        primary: '#717171',
        secondary: '#268bd2',
      },
      background: {
        default: '#ffffff',
      },
      divider: {
        default: '#1595c5',
      },
      button: {
        default: 'rgb(38, 139, 210)',
        hover: 'rgba(0,0,0,.08)',
        focus: 'rgba(255,255,255,.12)',
        disabled: 'rgba(0,0,0,.12)',
      },
    });
    return (
      <div>
        <div className="tableHeadMain">
          <div className="tsecOne">
            <SearchDatatable searchAll={this.searchCall} />
          </div>
          <div className="tsecTwo">
          <Button className='sUserAdd' text='Export Demo Users' action={this.export} />       
          </div>
        </div>

        <div className="superDataTable kitIdTable">
          <DataTable
            // title="Users"
              columns={columns(this.handleButtonClick)}
            data={data}
            progressPending={loading}
            onSort={this.handleSort}
            sortServer
            progressComponent={<DataLoader />}
            className="superDtableWrap"
            theme="solarized"
          />
          <div className="centerLoadBtn" style={this.state.buttonStyle}>
            <LoadingButton
              disabled={this.state.btnStatus}
              color={this.state.btnColorLoad}
              className='loadMore'
              text={this.state.btnTextLoad}
              action={this.loadMoreData}
              loading={this.state.loadmoreBtn}
            />
          </div>
        </div>

      </div>
    );
  }
}
demoUserManagement.contextType = ViewContext;

const mapStateToProps = state => ({
  demoDetail: state.admin.demoDetails
})
 export default connect(mapStateToProps, { getDemoUser })(demoUserManagement); 

