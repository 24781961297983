/***
*
*   ACCOUNT NAV
*   Pre-built account sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners.
*
*   PROPS
*   cols: number of columns (default: 2)
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from 'components/lib';
import './sub.scss';

export class AccountNav extends React.Component {

  render(){
    
    let userLocal = JSON.parse(localStorage.getItem('user'));
    let prefix = ''
    if (userLocal && userLocal.Partner && userLocal.Partner.partnerId) {
      prefix = "/admin"
    }
    let accountLink =`${prefix}/account`
    let passwordtLink =`${prefix}/account/password`
    console.log(accountLink,accountLink,"---fsd")
    return(
      <AuthContext.Consumer>
        {(context) => (

          <nav className="subnav">
            <NavLink exact to={accountLink} activeClassName='active'>Profile</NavLink>
            <NavLink exact to={passwordtLink}  activeClassName='active'>Password</NavLink>
          </nav>
        )}
      </AuthContext.Consumer>
    );
  }
}
