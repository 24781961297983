import { combineReducers } from 'redux';
import superAdminReducer from './superAdminReducer';
import adminReducer from "./adminReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
    admin : superAdminReducer,
    users : adminReducer,
    error : errorReducer
});
