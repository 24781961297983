/***
 *
 *   SUB NAV
 *   The Sub navigation used inside the main application.
 *
 *   PROPS
 *   
 *   allItems: array of objects containing label and link keys
 *   item: the sub nav item
 *
 **********/

import React from 'react';
import { NavLink } from "react-router-dom";
import './app.scss'

import { connect } from 'react-redux';
import { setMenuKey } from '../../../actions/userAction';

class SubNav extends React.Component {
    className;

    constructor(props) {

        super(props);

        this.state = {
            open: this.props.type === 'fixed' ? true : false,
            isClick: false,
        }
    }

    menuClick = (key) => {
        this.props.setMenuKey(key)
    }

    render() {

        const { allItems, item } = this.props
        console.log("item", item)
        return (
            <div>
                <NavLink
                    className="account__M"
                    key={item.label}
                    style={{ width: (100 / allItems.length) + '%' }}
                    onClick={() => { this.setState({ isClick: !this.state.isClick }) }}>{item.label}</NavLink>
                {this.state.isClick ? <ul className="accountM__Sublist">
                    {
                        item.sub.map((it) => <li key={it.key}><NavLink key={it.key} onClick={this.menuClick(it.key)} to={it.link}>{it.name}</NavLink></li>)
                    }
                </ul> : null}
            </div>

        );
    }
}

const mapStateToProps = state => ({
    partner: state.admin.partner,
    partner_name: state.admin.kitDetails
});

export default connect(mapStateToProps, { setMenuKey })(SubNav);
