import React from 'react';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';
import memoize from 'memoize-one';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, KIT_SERVICE_UPLOAD, GET_KIT_SERVICE } from '../../apiurl';
import { getKitId } from '../../actions/userAction';
import { connect } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import moment from 'moment';

const columns = memoize(clickHandler => [
    {
        name: 'Kit Number',
        selector: 'kit_id',
        // sortable: true,
    },
    {
        name: 'Upload Date',
        cell: (row) => <div>{moment(row.upload_date).format("YYYY-MM-DD hh:mm A")}</div>,
        // sortable: true,
        hide: 'md',
    },

    {
        name: 'Valid',
        cell: (row) => <div>{(row.upload_successful) ? "Yes" : "No"}</div>,
        hide: 'md',
    },
    {
        name: 'Service Type',
        selector: 'service',
        // sortable: true,
    },
    {
        name: 'Message',
        cell: (row) => <div>{row.upload_response && row.upload_response.Message}</div>,
    },
]);

class adminKitManagement extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            close: {
                user_id: '',
                state: false
            },
            data: [],
            loading: false,
            reload: false,
            limit: 50,
            perPage: 50,
            skip: 0,
            searchVal: '',
            prevData: [],
            loadmoreBtn: false,
            // loadmoreValue: '',
            hasMore: true,
            btnStatus: false,
            btnColorLoad: 'blue',
            btnTextLoad: 'More',
            buttonStyle: { display: 'block' },
        };


        this.uploadKitId = this.uploadKitId.bind(this);
    }
    handleButtonClick = async (e) => {
        let actionName = e.target.name;
        let id = e.target.id;
        let className = e.target.className
        let row_id = e.target.row_id

    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getKitDetails();

        this.setState({ loading: false });
    }

    getKitDetails = async () => {
        let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd')) || parseInt(localStorage.getItem('partner_id'))

        let { limit, skip, searchVal, perPage, hasMore } = this.state;
        limit = hasMore ? limit : perPage
        let url = `${GET_KIT_SERVICE}?partner_id=${partnerId}&limit=${limit}&skip=${skip}`
        if (searchVal) url = `${url}&search=${searchVal}`

        console.log(limit, perPage, hasMore, "state data")
        await axiosCall('post', LAMDA_APICALL, {
            url,
            method: "GET",
        }, async (response) => {
            console.log(response, "kit history response")
            if (response.status) {
                const { records, hasMore } = response.data
                // const allData = [...this.state.data, ...records]

                const commonState = {
                    data: records,
                    // skip: allData.length,
                    hasMore,
                    buttonStyle: { display: hasMore ? 'block' : 'none' },
                };

                this.setState({
                    ...commonState,
                    btnStatus: !hasMore,
                    limit: hasMore ? limit + perPage : records.length,
                    btnColorLoad: hasMore ? 'blue' : 'red',
                    btnTextLoad: hasMore ? 'More' : 'End',
                });
            }
        });


    }

    loadMoreData = async () => {
        this.setState({ loadmoreBtn: true });
        await this.getKitDetails();

        this.setState({
            loadmoreBtn: false
        });
    }

    searchCall = async (val) => {
        // For handling multiple result
        if (this.state.searchVal !== val) {
            this.setState({ searchVal: val, loadmoreValue: '' });
            await this.getKitDetails();
        }
    };

    uploadKitId = async () => {
        await this.context.showModal({
            title: "Upload Kit IDs Service",
            uploadKitService: true,
            csvCallback: this.uploadCsv
        });
    }

    uploadCsv = async (json) => {
        console.log("json", json);

        if (json.length > 0) {
            let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd')) || parseInt(localStorage.getItem('partner_id'))

            await axiosCall('post', LAMDA_APICALL, {
                url: KIT_SERVICE_UPLOAD,
                method: "POST",
                data: { partner_id: partnerId, kits: json }
            }, async (response) => {
                console.log("response", response)
                let msg = "Sucessfully completed"
                if (response.status) {
                    this.context.hideModal(true);
                    if (response.data) {
                        const { uploadedKits, duplicateKits } = response.data
                        if (uploadedKits > 0)
                            msg = `${uploadedKits} kits uploaded successfully.`
                        if (duplicateKits > 0)
                            msg += ` ${duplicateKits} duplicate kits were not uploaded.`
                    }
                    this.context.showNotification(msg, 'success', true);
                    await this.getKitDetails();
                } else {
                    let msg = response.message.message || "Some error occured !..."
                    this.context.showNotification(msg, 'error', false);
                }
            });

        } else {
            this.context.showNotification("Please upload a valid CSV file", 'error', false);
        }

    }

    render() {

        const { loading, data, totalRows } = this.state;

        createTheme('solarized', {
            text: {
                primary: '#717171',
                secondary: '#268bd2',
            },
            background: {
                default: '#ffffff',
            },
            divider: {
                default: '#1595c5',
            },
            button: {
                default: 'rgb(38, 139, 210)',
                hover: 'rgba(0,0,0,.08)',
                focus: 'rgba(255,255,255,.12)',
                disabled: 'rgba(0,0,0,.12)',
            },
        });
        return (
            <div>
                <div className="tableHeadMain">
                    <div className="tsecOne">
                        <SearchDatatable searchAll={this.searchCall} />
                    </div>
                    <div className="tsecTwo">
                        <Button className='sUserAdd upKit' text=' Upload Kit ID Service' action={this.uploadKitId} />
                    </div>
                </div>

                <div className="superDataTable kitIdTable">
                    <DataTable
                        // title="Kit Service Type"
                        columns={columns(this.handleButtonClick)}
                        data={data}
                        progressPending={loading}
                        onSort={this.handleSort}
                        sortServer
                        progressComponent={<DataLoader />}
                        className="superDtableWrap"
                        theme="solarized"
                    />
                    <div className="centerLoadBtn" style={this.state.buttonStyle}>
                        <LoadingButton
                            disabled={this.state.btnStatus}
                            color={this.state.btnColorLoad}
                            className='loadMore'
                            text={this.state.btnTextLoad}
                            action={this.loadMoreData}
                            loading={this.state.loadmoreBtn}
                        />
                    </div>
                </div>

            </div>
        );
    }
}
adminKitManagement.contextType = ViewContext;

const mapStateToProps = state => ({
    kitDetails: state.admin.kitDetails
})
export default connect(mapStateToProps, { getKitId })(adminKitManagement);
