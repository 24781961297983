/***
*
*   MODAL
*   To display an overlay modal anywhere in your application call
*   this.context.showModal({ }) with an object containing the following props
*
*   PROPS
*   title: (optional)
*   text: message to the user (optional)
*   form: a form object (optional: see form docs for more information)
*   url: destination to send the form
*   method: HTTP post type
*   buttonText – text for the confirm button
*
**********/

import React from 'react';
import { Form, ViewContext, Icon } from 'components/lib';

import './modal.scss';
import { Loader, Table, Card, Grid, Child } from '../lib';
import moment from 'moment';
import { SINGLESTATUS } from '../../constants/Constants';
import SelectMulti from "react-dropdown-select";
import DateTimePicker from 'react-datetime-picker';
import Checkbox from 'components/form/checkbox/newCheckbox';

const header = [
  { name: 'status', title: 'Status', sort: true },
  { name: 'date', title: 'Date', sort: true },
]

export class ViewHistoryModal extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      kitHistory: {
        header: header,
        body: [],
      },
      kitId: '',
      loader: false,
      update_status: false,
      time: null,
      select: null,
      checkbox:false,
      checked: true,
    }
  }
  async clearState() {

    await this.setState({
      rawdata: {
        header: header,
        body: [],
      },
      kitId: '',
      loader: false,
      update_status: false,
      time: null,
      select: null,
      checkbox:false,
      checked: true
    });
    console.log(this.state)

  }

  handleclick= () => {
    console.log("Handle click")
    this.setState({update_status:!this.state.update_status})
  }

  handleclick2 = () => {
    console.log("Handle click2")
    this.setState({update_status:!this.state.update_status, time: null, select: null, checked: true})
  }

  onChangeTime = (event) => {
    console.log("onchangetime", event, this.state.select)
    if(!event){
      this.setState({time: null})
    }else{
      this.setState({time: event})
    }
  }

  selectOption = (select) =>{
    console.log("option selected", select[0].value)
    this.setState({select: select[0].value, time: new Date()})
  }

  onCheckBox = (event) => {
    console.log("checkbox", event)
    this.setState({ checked: event.target.checked })
  }

  updateKitStatus = () => {
    console.log("updatekitsat", this.state.select, this.state.time, this.props.kitId, this.state.checked)
    if (this.state.select !== null && this.state.time !== null) {
      let result = this.props.update(this.props.kitId, this.state.select, this.state.time, this.state.checked)
      console.log("REsult", result)
      this.setState({ update_status: false, time: null, select: null, checkbox: false, checked: true })
    } else {
      this.context.showNotification("Please Enter Valid Entries", 'error', true)
    }
  }

  close = async (context) => {
    context.hideTrackingHistoryModal(true)
    this.clearState()
  }

  render() {
    console.log(this.props, "props =========================")
    return (
      <ViewContext.Consumer>
        {(context) => (
          // To close escape and outside modal
          // <div className={ "modal " + context.modal.visible }
          // onClick={(event) => this.close(event, context) }>
          <div className={"modal " + context.historyModal.visible} style={{ overflow: 'scroll' }}>

            <div className='modal-content card'>

              {this.props.title &&
                <header>
                  <h1>{this.props.title} </h1>
                  <div onClick={event => this.close(context)} className="closeBtnModal"><Icon color="dark" image="x" /></div>
                </header>
              }
              {this.props.kitId &&
                <div className="table-container">
                  <div className="filter-dots" style={{ fontSize: "13px" }}>

                    <Grid cols='1'>
                      <div style={{ overflowY: 'auto', height: "400px" }}>
                        <Table
                          data={{
                            header: header,
                            body: this.props.data.map(da => ({ status: da.status, date: moment(da.date).format("YYYY-MM-DD hh:mm A") }))
                          }}
                          loading={this.state.loader}
                          badge={{ col: 'plan', color: 'blue' }}>
                        </Table>
                      </div>
                    </Grid>

                  </div>
                  <div className="container-head">
                    <button 
                      className={this.state.update_status? "hidden" :"btn btn-success"}
                      onClick={this.handleclick}
                      >
                      {this.state.update_status? "Close" :"Update Status"}
                      </button>
                  </div>
                  <div className="container-body">
                    {this.state.update_status?
                        <div className="block">
                          {
                            SINGLESTATUS? 
                                <SelectMulti
                                  options={SINGLESTATUS}
                                  values={[]}
                                  onChange={this.selectOption}
                                  placeholder="Select One"
                                  className="multiSelect"
                                  dropdownPosition="top"
                                  color="#2684FF"
                                >

                                </SelectMulti>
                            : null
                          }
                          {
                            this.state.select !== null?                            
                              <DateTimePicker 
                                className="datePicker"
                                onChange={this.onChangeTime}
                                value={this.state.time}
                                format="dd-MM-yyyy HH:mm"
                                maxDate={new Date()}
                                disableClock={true}
                              />
                              :null
                          }
                          {
                            this.state.time !== null?
                            <label>
                              <Checkbox
                                className="checkbox"
                                defaultChecked
                                onChange={this.onCheckBox}
                                name={"Notify"}
                              />
                              <p>Notify</p>
                            </label>
                            :null
                          }
                          {
                            <>
                              <button 
                              className="btn btn-success"
                              onClick={this.updateKitStatus}
                              >
                                Update
                              </button>
                              <button 
                              className={this.state.update_status? "btn btn-warning" :"btn btn-success"}
                              onClick={this.handleclick2}
                              >
                              {this.state.update_status? "Close" :"Update Status"}
                              </button>
                            </>
                          }
                        </div>
                      :null}
                  </div>
                </div>
              }
              {!this.props.kitId &&
                  <Grid cols='1'><p>KitId is empty</p></Grid>
              }

            </div>


          </div>

        )}
      </ViewContext.Consumer>
    );
  }
}
ViewHistoryModal.contextType = ViewContext;
