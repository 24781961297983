/***
*
*   EXTERNAL LAYOUT
*   Layout for external pages, such as 404
*
**********/

import React from 'react';

import { Logo } from 'components/lib';
import './external.scss'
import '../layout.scss';

export class ExternalLayout extends React.Component {

  render() {

    document.body.classList.add('color-bg');

    return (

      <main className='external'>
        <section className="panel-row">
          <div className="content" style={{minHeight: "400px"}}>
            <Logo />
            <this.props.children />
          </div>
        </section>
      </main>
    );
  }
}
