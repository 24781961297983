import React from 'react';
import moment from 'moment';
import UpdateUser from './UpdateUser';
import AccesControl from './AccesControl';
import Rawdata from './Rawdata';
import {connect} from "react-redux";
import { axiosCall } from '../../helpers';
import { validateKit, addKit, releaseKit, deleteUser, getCountries } from './services'
import {
    Card,
    Icon,
    TabView, Loader, Table
} from '../lib';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';

import { updateUserAction } from 'actions/userAction';
import {LAMDA_APICALL,KIT_TRACKING_HISTORY,ADD_KIT_TO_QUEUE, ADD_AS_PARENT } from '../../apiurl';
import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';
import { DataLoader } from 'datatableComponents/DataLoader';


const table_arr = [
    { name: 'latest_status', title: 'STATUS', sort: true },
    { name: 'kit_id', title: 'KIT_ID', sort: true },
    { name: 'date_of_scanning', title: 'Latest Tracking Date', sort: false },
    {name:'view', title: 'Action'}
    
]
const columns = memoize(clickHandler => [
    {
        name: 'Kit Number',
        selector: 'kit_id',
        sortable: true,
        hide: 'md',
      },
      {
        name: 'Upload Date',
        cell: (row) => <div>{row.createdAt ? moment(row.createdAt).format("YYYY-MM-DD hh:mm A"): ''}</div>,
      },
      {
        name: 'Latest Tracking Status',
        selector: 'latest_kit_status',
        sortable: true,
        hide: 'md',
      },
      
      {
        name: 'Valid',
        cell: (row) => <div>{(row.black_listed) ? "No" : "Yes"}</div>,
        hide: 'md',
      },
      {
        name: 'Latest Tracking Status',
        selector: 'latest_kit_status',
        sortable: true,
        hide: 'md',
      },{
        name: 'Latest Tracking Date',
        cell: (row) => <div>{row.latest_tracking_date? moment(row.latest_tracking_date).format("YYYY-MM-DD hh:mm A") : ''}</div>,
      },
      // {
      //   name: 'Raw Data',
      //   hide: 'md',
      //   cell: (row) => <div><a href="">View Data</a></div>,
      // },
      {
        name: 'Actions',
        cell: (row) => <div><button className="tblAction fas fa-history" onClick={clickHandler} name="tracking" id={row.kit_id} title="View tracking history"></button></div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
  ]);
  
class UpdateForm extends React.Component {
   
    constructor(props) {

        super(props);
    }
    state = {
        data: [],
        parent_kit_data:[],
        error: '',
        kit_ids: '',
        first_name: '',
        last_name: '',
        email: '',
        kit_id: '',
        phone: '',
        kit_relations: [],
        epigenetic_data: [],
        account_type: [],
        country: '',
        address_lane1: '',
        address_lane2: '',
        zipcode: '',
        city: '',
        newsletter: '',
        terms_and_condition: false,
        research_purpose: false,
        subscribed: false,
        birthday: '',
        gender: '',
        weight: '',
        weight_unit: '',
        height: '',
        height_unit: 'cm',
        created_at: moment().format("YYYY-MM-DD hh:mm A"),
        last_login: moment().format("YYYY-MM-DD hh:mm A"),
        loading: false,
        table: {
            header: table_arr,
            body: []
        },
        countries: [],
        parent_loader: false,
    }
    async componentDidMount() {
        let countries = await getCountries();
        this.setState({ countries: countries })
    }
    componentDidUpdate(prevProps) {
        if (this.props.user) {
            if (prevProps.user != this.props.user) {
                this.setState({
                    error: '',
                    first_name: this.props.user.first_name,
                    last_name: this.props.user.last_name,
                    email: this.props.user.email,
                    kit_id: this.props.user.kit_id,
                    phone: this.props.user.phone,
                    kit_relations: this.props.user.kit_relations,
                    epigenetic_data: this.props.user.epigenetic_data,
                    account_type: this.props.user.account_type,
                    status: this.props.user.status,
                    created_at: moment(this.props.user.createdAt).format("YYYY-MM-DD hh:mm A"),
                    last_login: moment(this.props.user.last_login).format("YYYY-MM-DD hh:mm A"),
                    country: this.props.user.country,
                    address_lane1: this.props.user.address_lane1,
                    address_lane2: this.props.user.address_lane2,
                    zipcode: this.props.user.zipcode,
                    city: this.props.user.city,
                    newsletter: this.props.user.newsletter,
                    terms_and_condition: this.props.user.terms_and_condition,
                    research_purpose: this.props.research_purpose,
                    subscribed: this.props.user.subscribed,
                    birthday: this.props.user.birthday,
                    gender: this.props.user.gender,
                    weight: this.props.user.weight,
                    weight_unit: this.props.user.weight_unit,
                    height: this.props.user.height,
                    height_unit: this.props.user.height_unit,
                    table: {
                        header: table_arr,
                        body: this.props.user.kit_relations && this.props.user.kit_relations[0] ? this.props.user.kit_relations[0].children.map(da => (
                            {
                                status: da.latest_kit_status,
                                kit_id: da.kit_id,
                                date_of_scanning: moment(da.latest_tracking_date).format('YYYY-MM-DD'),
                                view:<button className="tblAction fas fa-history" id={da.kit_id} name="viewHistory" title="Tracking History"></button>
                            }
                            
                            )) 
                            : []},
                    data:this.props.user.kit_relations && this.props.user.kit_relations[0] ?this.props.user.kit_relations[0].children : [],
                    parent_kit_data : this.props.user.latest_kit_status ? [{latest_kit_status:this.props.user.latest_kit_status,latest_tracking_date:this.props.user.latest_tracking_date,kit_id:this.props.user.kit_id}]:[],
                    parent_kit: "",
                })
            }
        }
    }
    validateKit = async (e) => {
        let val = e.target.value;
        e.persist();
        if (val) {
            await this.setState({ kit_ids: val })
            let response = await validateKit({
                "id": val,
                "user_id": this.props.user._id
            });
            await this.setState({ error: response })
        } else {
            this.setState({
                kit_ids: "",
                error: ""
            })
        }
    }
    handleButtonClick = async (e) => {
        let actionName = e.target.name;
        let id = e.target.id;
        console.log("iddddddd",id)
        if (actionName === "tracking") {
          this.viewTrackingHistory(id)
        }
      }

      async viewTrackingHistory(kitId){
        console.log(kitId, "modal kit")
        await this.trackingHistory(kitId)
        
      }
    
      updateKitStatus = async (kit_id, status, date, notify) =>{
        let extra = "";
        let new_status = status;
        if(status === "RESULT_READY_DNA"){
          extra = "DNA"
          new_status = "RESULT_READY"
        }
        if(status === "RESULT_READY_EPI"){
          extra = "EPIGENETICS"
          new_status = "RESULT_READY"
        }
        console.log("UpdateKitStatus", kit_id, status, date, notify)
       
        await axiosCall('post', LAMDA_APICALL, {
          url: ADD_KIT_TO_QUEUE,
          method: "POST",
          data: [{
            kit_id: kit_id,
            status: new_status,
            date: date,
            notify: notify,
            note: "Updated via SuperAdmin",
            extra: extra
          }]
        }, (res)=>{
          console.log("res", res)
          this.context.showNotification("Sucessfully Updated", 'success', true);
          this.trackingHistory(kit_id)
          return res
        }).catch((error)=>{
          console.log("error ", error)
          this.context.showNotification("Some error occured !...", 'error', false);
        })
      }
    
    changeParent = async () => {
        this.setState({parent_loader: true})
        console.log("function", this.state.parent_kit, this.props.user._id)
        if(this.state.parent_kit){
            await axiosCall('post', LAMDA_APICALL, {
                url: ADD_AS_PARENT,
                method: "POST",
                data: {
                  kit_id: this.state.parent_kit,
                  user_id: this.props.user._id
                }
              }, async (response) => {
                // console.log(response,"kit history response")
                if(response.data){
                    console.log(response.data)
                    this.context.showNotification("Sucessfully Updated", 'success', true);
                }else{
                    console.log(response.message)
                    this.context.showNotification("Already has been updated !...", 'error', false);
                }
              });
        }else{
            this.setState({error: "Please select a kit"})
            setTimeout(() =>{
                this.setState({error: ""})
            }, 10000)
        }
        this.setState({parent_loader: false})
        
    }
      
    trackingHistory = async (kit_id) => {
       await axiosCall('post', LAMDA_APICALL, {
          url: KIT_TRACKING_HISTORY,
          method: "POST",
          data: {
            kit_id: kit_id
          }
        }, async (response) => {
          console.log(response,"kit history response")
            console.log('this.context',this.context)
            await this.context.showTrackingHistoryModal({
              title: 'Kit Tracking History : '+kit_id,
              kitId : kit_id,
              data:response.data,
              update: this.updateKitStatus.bind()
            });
        });

    }


    releaseKit = async () => {
        if (this.props.user.kit_id) {
            await this.setState({ loading: true })
            let response = await releaseKit({
                user_id: this.props.user._id
            })
            if(response.code == 400){
                await this.setState({
                    error: response.message,
                    loading: false,
                })
            }else{
                await this.setState({
                    error: response.message,
                    loading: false,
                    kit_id:''
                })
            
                
            console.log("reelase kit")
            let _user = this.props.user
                _user.kit_id = ''
                _user.kit_activated = false
                await this.props.updateUserAction(_user)                
            let that = this
            await setTimeout(() => that.resetError(), 2000)
            }
            console.log("reelase kit")
        }
    }
    resetError = () => {
        this.setState({ error: '' })
    }
    deleteUser = async () => {
        this.setState({ loading: true })
        let response = await deleteUser({ user_id: this.props.user._id, username: this.props.user.username, email: this.props.user.email });
        this.setState({
            error: response
        })

        window.location.reload(false);
    }
    addKit = async () => {
        if (!this.state.error) {
            if (this.state.kit_ids.length) {
                console.log("add kit kit",this.state,this.props,"partnerrrr",this.props.user.partner_id)
                await this.setState({ loading: true })
                let res = await addKit({ id: this.state.kit_ids, user_id: btoa(this.props.user._id), gender: this.props.user.gender},this.props.user.partner_id).
                catch(err=>{
                    this.setState({
                        error: res.message.message,
                        loading: false,
                        kit_id: ""
                    })
                })
                this.setState({
                    error: res.message,
                    loading: false,
                    kit_id: res.data ? res.data.kit_id : ""
                })
                let _user = this.props.user
                _user.kit_id = this.state.kit_ids
                _user.kit_activated = false                
                await this.props.updateUserAction(_user)
                let that = this
                await setTimeout(() => that.resetError(), 2000)
            }
            else {
                this.setState({
                    error: "Enter Kit Id"
                })
            }
        }

    }
    render() {
        return (

            <div>
                <button className="closeBtn" onClick={this.props.close}>
                    <Icon color="dark" image="x" />
                </button>

                <div style={{ display: "inline-flex" }}>
                    <h2>Account Profile</h2>
                    <span style={{ "marginLeft": "7px" }}>
                        {this.props.user.email}
                    </span>
                </div>
                <TabView
                    name="Billing"
                    labels={[
                        "Edit/Save",
                        "Free/Assign Kit ID",
                        "Edit Child Kit ID",
                        // "View Raw Data",
                        "Access Control",
                        "Delete"
                    ]}
                >
                    {/* USER SECTION */}
                    <Card><UpdateUser user={this.props.user} access={this.props.access.includes('update')} confirmed={true} /></Card>
                    {/* KIT ID SECTION */}
                    <Card>
                        <div>
                            <div style={{ marginTop: "20px", marginLeft: "25%", marginRight: "25%" }}>
                                {
                                    this.state.loading ? (<div className='stat card'>
                                        <Loader />
                                    </div>) : !this.state.kit_id ? (<div>
                                        <div class="ipBox"><input type="text" placeholder="Kit Id"  value={this.state.kit_ids} onChange={this.validateKit} /></div>
                                        <button className="addbtn" onClick={this.addKit} disabled={this.state.error} disabled={!this.props.access.includes('kitEdit')}>
                                            Assign KIT
                                        </button>
                                        <p style={{ fontSize: "12px" }}>{this.state.error}</p>
                                    </div>) : (
                                                <div>
                                                    <button className="addbtn" onClick={this.releaseKit} disabled={this.state.error} disabled={!this.props.access.includes('kitEdit')} style={{ fontSize: "12px" , width:'140px', height: '40px'}}>
                                                        Free KIT</button>
                                                    <p style={{ fontSize: "15px" }}>{this.state.error}</p>
                                                </div>
                    
                                                
                                            )
                                }
                            </div>
                            <div>
                            <DataTable
                                // title="Users"
                                columns={columns(this.handleButtonClick)}
                                data={this.state.parent_kit_data}
                                progressPending={this.state.loading}
                                onSort={this.handleSort}
                                sortServer
                                progressComponent={<DataLoader />}
                                className="superDtableWrap"
                                theme="solarized"
                            />
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <>
                        <div className='stat card' style={{ marginTop: "20px", marginLeft: "25%", marginRight: "25%" }}>
                                <div style={{display: "flex"}}>
                                    <div class="ipBox">
                                        {/* <input type="text" placeholder="Kit Id"  value={this.state.kit_ids} onChange={this.validateKit} /> */}
                                        {this.state.data.length>0?
                                        <>
                                            <select name="kits" id="kits" onChange={(e) => this.setState({parent_kit: e.target.value})} style={{ margin: "10px", width: "150px", height: "50px", textAlign: "center"}}>
                                                <option value={""}>Select one kit</option>
                                                {
                                                this.state.data.map((items) => (
                                                        <option value={items.kit_id}>{items.kit_id}</option>
                                                )) 
                                              }
                                            </select>
                                            {
                                                this.state.parent_loader ? (<div className='stat-right card'>
                                                    <Loader />
                                                </div>) :
                                                (
                                                    <>
                                                    <button
                                                            onClick={()=> this.changeParent()} 
                                                            disabled={this.state.data.length < 1 || this.state.parent_loader}
                                                            style={{width: "100px",
                                                                height: "50px",
                                                                margin: "10px",
                                                                borderRadius: "20px",
                                                                backgroundColor: this.state.data.length < 1 || this.state.parent_loader? "grey": '#1494c5',
                                                                color: "white"
                                                                }}>
                                                            Set As Parent
                                                        </button>
                                                        <p style={{ fontSize: "12px", color: "red" }}>{this.state.error}</p>
                                                    </>
                                                )
                                            }
                                        </>
                                            :
                                            null}
                                        
                                      
                                        </div>
                                    </div>
                                </div>

                                <DataTable
                                    // title="Users"
                                    columns={columns(this.handleButtonClick)}
                                    data={this.state.data}
                                    progressPending={this.state.loading}
                                    onSort={this.handleSort}
                                    sortServer
                                    progressComponent={<DataLoader />}
                                    className="superDtableWrap"
                                    theme="solarized"
                                />
                        </>
                        
                        
                    </Card>
                    {/*<Card>*/}
                    {/*    <Rawdata user={this.props.user}/>*/}
                    {/*</Card>*/}
                    <Card>
                        <AccesControl user={this.props.user} access={this.props.access.includes('accessControl')} />
                    </Card>
                    <Card>
                        <div style={{ marginTop: "10px" }}>
                            {!this.state.loading ? (
                                <div><p>Are you sure ? </p>
                                    <button className="addbtn" onClick={this.deleteUser} disabled={!this.props.access.includes('destroy')} >
                                        Delete
                                    </button></div>) : (<div><Loader /></div>)}
                        </div>
                    </Card>
                </TabView>
            </div>

        )
    }
}
UpdateForm.contextType = ViewContext;
const mapStateToProps = state => ({
})
export default connect(mapStateToProps, { updateUserAction })(UpdateForm);
