import React from 'react';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';
import memoize from 'memoize-one';
import { axiosCall } from '../../helpers';
import { ADD_KIT_SINGLE, KIT_UPLOAD,BULK_KIT_UPLOAD, LAMDA_APICALL,KIT_TRACKING_HISTORY,
  KIT_DELETE,KIT_TRACKING_DETAILS,ADD_KIT_TO_QUEUE, EXPORT_PDF, GET_PARTNER, GET_ONE_USER } from '../../apiurl';
import { Filter } from '../../components/Filter/Filter';
import { getKitId } from '../../actions/userAction';
import { connect } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import moment from 'moment';
import { History } from '../../components/lib';
import { disableKit } from './service'

const columns = memoize(clickHandler => [
  {
    name: 'Kit Number',
    cell: (row) => <div>{(row.user_id === "NA") ? `${row.kit_id}` : <a  onClick={clickHandler} id={row.user_id} name="edit" className='kitIdManagement-id'>{row.kit_id}  </a> }</div>
  },
  {
    name: 'Upload Date',
    cell: (row) => <div>{moment(row.createdAt).format("YYYY-MM-DD hh:mm A")}</div>,
  },
  {
    name: 'Claimed',
    cell: (row) => <div>{(row.user_id === "NA") ? "No" : "Yes"}</div>,
  },
  {
    name: 'Valid',
    cell: (row) => <div>{(row.black_listed) ? "No" : "Yes"}</div>,
    hide: 'md',
  },
  {
    name: 'Kit Status',
    selector: 'latest_kit_status',
    sortable: true,
    hide: 'md',
  },{
    name: 'Result Type',
    selector: 'result_type',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Tracking Number',
    cell: (row) => <div>{row.tracking_details ? (row.tracking_details.TrackingNumber ? <a href ={row.tracking_details.TrackingLink} target="_blank" >{row.tracking_details.TrackingNumber}</a> : 'Not available') : <button className={`tblAction fas ${row.id} fa-redo`} onClick={clickHandler} name="reload" id={row.kit_id} row_id={row.id} title="Reload tracking details"></button>}</div>,
  },
  // {
  //   name: 'Raw Data',
  //   hide: 'md',
  //   cell: (row) => <div><a href="">View Data</a></div>,
  // },
  {
    name: 'Actions',
    cell: (row) => <div><button className={`tblAction fas fa-ban ${row.user_id === "NA" ? "" : "disabled"}`} onClick={clickHandler} id={row.id} name="delete" title="Disable kit"></button><button className="tblAction fas fa-eye" onClick={clickHandler} name="view" id={row.kit_id} title="View raw data"></button><button className="tblAction fas fa-history" onClick={clickHandler} name="tracking" id={row.kit_id} title="View tracking history"></button></div>,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
]);

class kitIdManagement extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      close: {
        user_id : '',
        state : false
    },
      data: [],
      loading: false,
      reload: false,
      perPage: 100,
      searchVal: '',
      prevData: [],
      blackListedState: null,
      loadmoreBtn: false,
      loadmoreValue: '',
      btnStatus: false,
      btnColorLoad: 'blue',
      btnTextLoad: 'More',
      buttonStyle: { display: 'block' },
      claimOption: [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ],
      claimVal: { value: null, label: 'All' },
      validVal: { value: null, label: 'All' },
      claimedState: null,
      validOption: [
        { value: null, label: 'All' },
        { value: false, label: 'Yes' },
        { value: true, label: 'No' }
      ],
    user:{},
    };

    this.addKitId = {
      kit_id: {
        label: 'Kit ID',
        type: 'text',
        required: true,
      },
      tenant_id: {
        type: 'hidden',
        value: '5c7e70f3ebc82935c7241e44'
      }
    };

    this.deleteUserForm = {
      is_deleted: {
        type: 'hidden',
        value: null
      }
    }

    this.addSingleKitId = this.addSingleKitId.bind(this);
    this.uploadKitId = this.uploadKitId.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.delete = this.delete.bind(this);

  }
  handleButtonClick = async (e) => {
    let actionName = e.target.name;
    let id = e.target.id;
    let className = e.target.className
    let row_id = e.target.row_id
    if (actionName === "view") {
      this.viewRawData(id)
    } else if (actionName === "disable") {
      await disableKit(id, { "is_deleted": true }).then(async response => {
        if (response.status) {
          this.context.showNotification(response.message, 'success', true)

          await this.getKitDetails();
          await this.setState({ data: this.props.kitDetails.xx.Items });
          await this.checkButtonStaus();

        } else {
          this.context.showNotification(response.message, 'error', true)
        }
      }).catch(err => {
        this.context.showNotification("Something went wrong. Please try again", 'error', true)
      })

    }else if (actionName === "tracking") {
      this.viewTrackingHistory(id)
    }else if (actionName === "delete") {
      if(className.includes("disabled")){
        this.context.showNotification("Claimed kitId cannot be Deleted", 'error', true)
      }else{
        let data ={ is_deleted: true }
        this.delete(data,id);
      }
    }else if (actionName === "reload") {
      if(id){
        let newclassName = 'tblAction fas fa-spinner fa-pulse'
        console.log(id,"iiiiiiiiiiiiiiiiiiiidddddddd")
        className = 'tblAction fas fa-redo'
        let getclassName = e.target.className
        e.target.className = newclassName
        await this.viewTrackingDetails(id)
        document.getElementsByClassName(newclassName).className = getclassName
      }else{
        this.context.showNotification("Empty kitId cannot be fetched", 'error', true)
      }
    }else if (actionName==="edit") {
      if (id !== "NA"){
      this.viewgetUserDetails(id)
        this.setState({
            close: {
                user_id : id,
                state : true
            },
        })
      }
    }
  }
  async viewRawData(kitId) {
    console.log(kitId, "modal kit")
    await this.context.showRawDataModal({
      title: 'View Raw Data : ' + kitId,
      kitId: kitId
    });
  }

  async viewTrackingHistory(kitId){
    console.log(kitId, "modal kit")
    await this.trackingHistory(kitId)
    
  }
  async viewTrackingDetails(kitId){
    console.log(kitId, "modal kit")
    await this.getTrackingDetails(kitId)
    
  }
  async viewgetUserDetails(userId){
    console.log(userId,"modal edit")
    await this.getUserDetails(userId)
  }

  async componentDidMount() {

    this.setState({ loading: true });

    await this.getKitDetails();


    this.setState({
      data: this.props.kitDetails.xx.Items,
      prevData: this.props.kitDetails.xx.Items
    });
    await this.checkButtonStaus();
    this.setState({ loading: false });
  }

  getKitDetails = async () => {
    // let data = {};
    // if (this.state.loadmoreValue !== '') {
    //   data.lastEval = { "id": this.state.loadmoreValue };
    // }
    // data.search = this.state.searchVal ? this.state.searchVal.toUpperCase() : this.state.searchVal;
    // data.limit = this.state.perPage;
    // data.blacklisted = this.state.blackListedState;
    // data.claimed = this.state.claimedState;
    console.log("getKit details",this.state.blackListedState, this.state.claimedState)
    let data = {
      ...this.state.loadmoreValue && { lastEval: { "id": this.state.loadmoreValue } },
      ...this.state.searchVal && { search: this.state.searchVal.toUpperCase() },
      ...this.state.perPage && { limit: this.state.perPage },
      ...(this.state.blackListedState != null && this.state.blackListedState !== undefined) && { blacklisted: this.state.blackListedState },
      ...(this.state.claimedState !== null && this.state.claimedState !== undefined) && { claimed: this.state.claimedState }
    }
    await this.props.getKitId(data);


  }

  getUserDetails = async (user_id) => {
    
    await axiosCall('post', LAMDA_APICALL, {
      url: GET_ONE_USER,
      method: "POST",
      data: JSON.stringify({
        "user_id":user_id
      }
      )
    }, async (response) => 
    {
    console.log(response,"kit history response")
          this.context.hideModal(true);
          console.log('this.context',this.context)
          await this.context.showKitDetailsModal({
            title: 'Account Profile : ',
            data: response.data[0],
          });
      });
  }

  checkButtonStaus = async () => {

    if (this.props.kitDetails.xx.LastEvaluatedKey) {
      this.setState({
        loadmoreValue: this.props.kitDetails.xx.LastEvaluatedKey.id,
        btnStatus: false,
        btnColorLoad: 'blue',
        btnTextLoad: 'More',
        buttonStyle: { display: 'block' }
      });

    } else {
      this.setState({
        btnStatus: true,
        btnColorLoad: 'red',
        btnTextLoad: 'End',
        buttonStyle: { display: 'none' }
      });
    }
  };

  loadMoreData = async () => {
    //let pData = this.state.prevData;
    this.setState({ loadmoreBtn: true });
    await this.getKitDetails();

    if (this.props.kitDetails.xx.Items.length > 0) {
      this.props.kitDetails.xx.Items.forEach(items => {
        this.state.data.push(items);
      });
    }

    await this.checkButtonStaus();

    this.setState({
      loadmoreBtn: false
    });
  }

  searchCall = async (val) => {
    // For handling multiple result
    if(this.state.searchVal !== val){
      await this.setState({ searchVal: val, loadmoreValue: '' });
      await this.getKitDetails();
      this.setState({ data: this.props.kitDetails.xx.Items });
      await this.checkButtonStaus();
    }
  };



  addSingleKitId = async () => {
    await this.context.showModal({
      title: 'Add Kit ID',
      form: this.addKitId,
      lamda: ADD_KIT_SINGLE,
      buttonText: 'Add Kit ID',
      url: LAMDA_APICALL,
      method: 'POST'
    });
  }

  exportPdf = async () => {
    await this.context.showModal({
      title: "Export PDF",
      exportCSV: true,
      csvCallback: this.exportCsv
    });
  }

  uploadKitId = async () => {
    await this.context.showModal({
      title: "Upload Kit IDs",
      uploadCSV: true,
      csvCallback: this.uploadCsv
    });
  }

  uploadCsv = async (json) => {
    console.log("json", json);

    if (json.length > 0) { 

      await axiosCall('post', LAMDA_APICALL, {
        url: BULK_KIT_UPLOAD,
        method: "POST",
        data: {
          kits: json,
          tenant_id: "5c7e70f3ebc82935c7241e44"
        }
      }, (response) => {
        if (response.status) {
          this.context.hideModal(true);

          this.context.showNotification("Sucessfully completed", 'success', true);

        } else {
          this.context.showNotification("Some error occured !...", 'error', false);
        }
      });

    } else {
      this.context.showNotification("Please upload a valid CSV file", 'error', false);
    }

  }
  // exportCsv = async (json, email) => {
  //   console.log("json", json, email);



  //   if (json.length > 0) {
  //     this.setState({ loading: true })

  //     await axiosCall('post', LAMDA_APICALL, {
  //       url: EXPORT_PDF,
  //       method: "POST",
  //       data: {
  //         kits: json,
  //         tenant_id: "5c7e70f3ebc82935c7241e44",
  //         email: email,
  //       }
  //     }, (response) => {
  //       if (response.status) {
  //         this.context.hideModal(true);

  //         this.context.showNotification("Sucessfully completed", 'success', true);

  //       } else {
  //         this.context.showNotification("Some error occured !...", 'error', false);
  //       }
  //     });
  //     this.setState({ loading: false })


  //   } else {
  //     this.context.showNotification(json.error, 'error', false);
  //   }

  // }


  exportCsv = async (json, email) => {
    console.log("json", json, email);

    if (json.length > 0) {

      axiosCall('post', LAMDA_APICALL, {
        url: EXPORT_PDF,
        method: "POST",
        data: {
          kits: json,
          tenant_id: "5c7e70f3ebc82935c7241e44",
          email: email,
        }
      }, (response) => {
        console.log("Response", response);
        if (!response.status && response.message.message != 'Endpoint request timed out') {
          this.context.showNotification("Some error occured !...", 'error', false);
        }
      });
      setTimeout(() => {
        this.context.hideModal(true);
        this.context.showNotification("Your request is being processed. Please check your email", 'success', true);
      }, 3000);

      

    } else {
      this.context.showNotification(json.error, 'error', false);
    }

  }
  delete = async(data,kit_id) =>{
    for (let key in this.deleteUserForm)
      this.deleteUserForm[key].value = data[key];
    this.context.showModal({


      lambda_method: 'DELETE',
      title: 'Delete Kit',
      form: this.deleteUserForm,
      buttonText: 'Delete User',
      lamda:`${KIT_DELETE}/${kit_id}`,
      text: 'Are you sure you want to delete kit?',
      url: LAMDA_APICALL,
      method: 'POST'

    }, async() => {
      this.context.showNotification(kit_id + ' was deleted', 'success', true);
      this.setState({
        loadmoreValue: ''
      });
      await this.getKitDetails();
      await this.setState({ data: this.props.kitDetails.xx.Items });
      await this.checkButtonStaus();
    });
  }

  updateKitStatus = async (kit_id, status, date, notify) => {
    let extra = "";
    let new_status = status;

    switch (status) {
      case "RESULT_READY_DNA":
        extra = "DNA"
        new_status = "RESULT_READY"
        break;

      case "RESULT_READY_EPI":
        extra = "EPIGENETICS"
        new_status = "RESULT_READY"
        break;

      case "FAILED_QC_DNA":
        extra = "DNA"
        new_status = "FAILED_QC"
        break;

      case "FAILED_QC_EPI":
        extra = "EPIGENETICS"
        new_status = "FAILED_QC"
        break;

      default:
        break;
    }
   
    console.log("UpdateKitStatus", kit_id, status, date, notify)
    this.setState({ loading: true })
    await axiosCall('post', LAMDA_APICALL, {
      url: ADD_KIT_TO_QUEUE,
      method: "POST",
      data: [{
        kit_id: kit_id,
        status: new_status,
        date: date,
        notify: notify,
        note: "Updated via SuperAdmin",
        extra: extra
      }]
    }, async (res) => {
      console.log("res", res)
      this.context.showNotification("Sucessfully Updated", 'success', true);
      await this.getKitDetails();
      this.trackingHistory(kit_id)
      this.setState({ data: this.props.kitDetails.xx.Items, loading: false })
      return res
    }).catch((error) => {
      console.log("error ", error)
      this.context.showNotification("Some error occured !...", 'error', false);
      this.setState({ loading: false })
    })
  }

  trackingHistory = async (kit_id) => {

    await axiosCall('post', LAMDA_APICALL, {
      url: KIT_TRACKING_HISTORY,
      method: "POST",
      data: {
        kit_id: kit_id
      }
    }, async (response) => {
      console.log(response, "kit history response")
      // if (response.status) {
      this.context.hideModal(true);
      console.log('this.context', this.context)
      let trackData = response.data

      trackData = trackData.map(da => {
        if (da.status == 'RECEIVED')
          return { ...da, status: 'RECEIVED_AT_LAB' }
        else
          return {
            ...da,
            status: da.result_type ? `${da.status}_${da.result_type.slice(0, 3)}` : da.status,
          }
      })

      console.log("trackData", trackData)

      await this.context.showTrackingHistoryModal({
        title: 'Kit Tracking History : ' + kit_id,
        kitId: kit_id,
        data: trackData,
        update: this.updateKitStatus.bind(),
      });

      // } else {
      //   this.context.showNotification("Some error occured !...", 'error', false);
      // }
    });


  }

  getTrackingDetails = async (kit_id) => {
    console.log(kit_id,"eeeeeeeeeeeeeeeeeeee")
    await axiosCall('post', LAMDA_APICALL, {
      url: KIT_TRACKING_DETAILS,
      method: "POST",
      data: {
        kit_id: kit_id
      }
    }, async (response) => {
      console.log(response,"kit tracking detils")
        console.log('this.context',this.context)
        console.log('this.props.kitDetails.xx.Items',this.props.kitDetails.xx.Items)
        this.setState({
          loadmoreValue: ''
        });
        await this.getKitDetails();
        await this.setState({ data: this.props.kitDetails.xx.Items });
        await this.checkButtonStaus();
    });
}


  claim_filter = async (values) => {
    console.log("Claim_filter", values.value);
    await this.setState({ claimVal: values, loadmoreValue: '', claimedState: values.value });
    await this.getKitDetails();
    if (this.props.kitDetails.xx.Items) {
      this.setState({ data: this.props.kitDetails.xx.Items });
    }
    await this.checkButtonStaus();

    this.setState({
      loadmoreBtn: false
    });
  }

  valid_filter = async (values) => {
    console.log("valid filter")
    await this.setState({ validVal: values, loadmoreValue: '', blackListedState: values.value });
    await this.getKitDetails();
    if (this.props.kitDetails.xx.Items) {
      this.setState({ data: this.props.kitDetails.xx.Items });
    }

    await this.checkButtonStaus();

    this.setState({
      loadmoreBtn: false
    });


    console.log("valid_filter", values);
  }



  render() {

    const { loading, data, totalRows } = this.state;

    createTheme('solarized', {
      text: {
        primary: '#717171',
        secondary: '#268bd2',
      },
      background: {
        default: '#ffffff',
      },
      divider: {
        default: '#1595c5',
      },
      button: {
        default: 'rgb(38, 139, 210)',
        hover: 'rgba(0,0,0,.08)',
        focus: 'rgba(255,255,255,.12)',
        disabled: 'rgba(0,0,0,.12)',
      },
    });
    return (
      <div>
        <div className="tableHeadMain">
          <div className="tsecOne">
            <SearchDatatable searchAll={this.searchCall} />
          </div>
          <div className="tsecTwo">
            <Button className='sUserAdd' text=' Add Kit ID' action={this.addSingleKitId} />
            <Button className='sUserAdd upKit' text=' Upload Kit IDs' action={this.uploadKitId} />
            <Button className='sUserAdd upKit' text=' Export PDF' action={this.exportPdf} />

          </div>
        </div>

        <div className="superDataTable kitIdTable">
          <div className="filterItemsKit">
            <Grid cols={3}>
              <Filter options={this.state.claimOption} selected={this.claim_filter} label={'Claimed'} value={this.state.claimVal} />
              <Filter options={this.state.validOption} selected={this.valid_filter} label={'Valid / Invalid'} value={this.state.validVal} />
            </Grid>
          </div>
          <DataTable
            // title="Users"
            columns={columns(this.handleButtonClick)}
            data={data}
            progressPending={loading}
            onSort={this.handleSort}
            sortServer
            progressComponent={<DataLoader />}
            className="superDtableWrap"
            theme="solarized"
          />
          <div className="centerLoadBtn" style={this.state.buttonStyle}>
            <LoadingButton
              disabled={this.state.btnStatus}
              color={this.state.btnColorLoad}
              className='loadMore'
              text={this.state.btnTextLoad}
              action={this.loadMoreData}
              loading={this.state.loadmoreBtn}
            />
          </div>
        </div>

      </div>
    );
  }
}
kitIdManagement.contextType = ViewContext;

const mapStateToProps = state => ({
  kitDetails: state.admin.kitDetails
})
export default connect(mapStateToProps, { getKitId })(kitIdManagement);
