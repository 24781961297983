/***
*
*   FEATURES
*   Feature list for use on home/landing pages.
*
*   PROPS
*   color: dark/light
*   items: array of objects containing keys: name & icon
*
**********/

import React from 'react';

import { Icon } from 'components/lib';
import './features.scss';

export class FeatureList extends React.Component {

  render(){
    return(

      <ul className='feature-list'>

        { this.props.items &&
          this.props.items.map((feature, index) => {

            return (
              <li key={ feature.name }>

                <Icon
                  image={ feature.icon }
                  color={ this.props.color }
                />

                { feature.name }

              </li>
            );
          })
        }

      </ul>
    );
  }
}
