import React from 'react';
import memoize from 'memoize-one';
import DataTable, {createTheme} from 'react-data-table-component';
import {SearchDatatable} from 'datatableComponents/SearchDatatable';
import {DataLoader} from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import {getUsers} from '../../actions/adminAction';
import {addUser, addPartnerId} from '../../actions/userAction';
import {connect} from "react-redux";
import {Filter} from '../../components/Filter/Filter';
import {
    Chart, Grid,
    ViewContext, Button,
    Tabs,
    TabView, LoadingButton
} from '../../components/lib';
import {ACCOUNT_TYPE as account_type, STATUS as status, TIME as time, SUBSCRIBED as subscribed, NEW_ACCOUNT_TYPE as new_account_type} from '../../constants/Constants';
import moment from 'moment';
import UpdateForm from '../../components/form/UpdateForm';
import {axiosCall} from '../../helpers';
import {LAMDA_APICALL, EXPORT_USERS} from '../../apiurl';

const sevendays = time.filter((item) => item.value == '')[0];//Last 7 days as default
const ALL = 'ALL'

const columns = [
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Director',
      selector: 'director',
      sortable: true,
      omit: true,
    },
    {
      name: 'Year',
      selector: 'year',
      sortable: true,
    },
  ]
class confirmedUser extends React.Component {
    constructor(props) {
        super(props);
        this.access = [];
        this.component = "ACCOUNT_MGMT"
        this.subComponent ="AD_CONFIRMED"
        this.state = {
            search: "",
            close: {
                user_id : null,
                state : false
            },
            current_user: {},
            data: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            searchVal: '',
            columnSort: '',
            sortType: '',
            users: [],
            account: '',
            status: '',
            subscribed:'',
            time: '',
            per_page: 10,
            page: 1,
            active:sevendays.value? {
                start: moment().subtract(parseInt(sevendays.value), 'hours').format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD")
            }:'',
            new: sevendays.value? {
                start: moment().subtract(parseInt(sevendays.value), 'hours').format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD")
            }:'',
            loadmoreBtn: false,
            loadmoreValue: '',
            btnStatus: false,
            btnColorLoad: '',
            btnTextLoad: 'Export Account Data',
            buttonStyle: { display: 'block' }
        };
    }
    columns = memoize(close => [
        {
            name: 'First Name',
            selector: 'first_name',
            sortable: true,
            omit: true,
        },
        {
            name: 'Last Name',
            selector: 'last_name',
            sortable: true,
            omit: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            hide: !this.state.close ? 'sm' : 'lg',
        },
        {
            name: 'Acount Type',
            selector: 'account_type',
            sortable: false,
            omit: true,
        },
        // {
        //     name: 'Last Login',
        //     selector: 'last_login',
        //     sortable: true,
        //     hide: 'md',
        //     cell: (row) => moment(row.last_login).format('YYYY/MM/DD hh:mm A')
        // },
        {
            name: 'Phone',
            selector: 'phone',
            hide: 'md',
            omit: true,
        },
        {
            name: 'Kit ID',
            hide: 'md',
            selector: 'kit_id',
            omit: true,
        },
        {
            name: 'Kit Status',
            selector: 'latest_kit_status',
            sortable: true,
            hide: 'md',
        },
        {
            name: 'Action',
            cell: (row) => <div><button className="tblAction fas fa-edit" onClick={close} id={row._id} name="edit"></button></div>,
            ignoreRowClick: false,
            allowOverflow: true,
            button: true,
        },
    ]);
    componentDidMount = async () => {
        let user = JSON.parse(localStorage.getItem('user'))
        this.access = user.permissions.find(roles => roles.key == this.component).sub.find(sub => sub.key = this.subComponent).inner;

        await this.fetchUsers();
    }
    handlePerRowsChange = async (perPage, page) => {
        await this.setState({ per_page: perPage, page: page });
        await this.fetchUsers();
    }
    handlePageChange = async (page) => {
        await this.setState({ page: page });
        await this.fetchUsers();
    }
    search = async (val) => {
        await this.setState({ search: val });
        await this.fetchUsers();
    }
    fetchUsers = async () => {
        let data = {};
        data.account_type = this.state.account;
        data.active = this.state.active;
        data.new = this.state.new;
        data.status = this.state.status;
        data.per_page = this.state.per_page;
        data.page = this.state.page;
        data.search = this.state.search;
        data.subscribed = this.state.subscribed;

        let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd'))|| parseInt(localStorage.getItem('partner_id'))
        if (partnerId) {
            data.partner_id = partnerId;
        }
        await this.setState({ loading: true });
        console.time('users')
        await this.props.getUsers(data);
        console.timeEnd('users')
        await this.setState({loading: false});
    }
    account_filter = async (values) => {
        await this.setState({account: values.value});
        await this.fetchUsers();
    }
    status_filter = async (values) => {
        await this.setState({status: values.value});
        await this.fetchUsers();
    }
    time_filter = async (values) => {
        await this.setState({
            active: values.value ? {
                start: moment().subtract(parseInt(values.value), 'hours').format("YYYY-MM-DD"),
                end: moment().add(1,'days').format("YYYY-MM-DD")
            } : ''
        });
        await this.fetchUsers();
    }
    new_user_filter = async (values) => {
        await this.setState({
            new: values.value ? {
                start: moment().subtract(parseInt(values.value), 'hours').format("YYYY-MM-DD"),
                end: moment().add(1,'days').format("YYYY-MM-DD")
            } : ''
        });
        await this.fetchUsers();
    }
    subscribed_filter = async (values) => {
        await this.setState({subscribed: values.value});
        await this.fetchUsers();
    }
    componentDidUpdate(prevProps) {
        if (this.props.error != prevProps.error) {
            this.context.showNotification(this.props.error, 'error', true)
        }
    }
    close = (e) => {
        let user_id = e.target.id;
        let current_user = user_id ? this.props.user.data.filter(data => data._id == e.target.id)[0] : {};
        this.setState({
            close: {
                user_id : user_id,
                state : false
            },
            current_user: current_user
        })
    }
    open = (e) => {
        let user_id = e.target.id;
        let current_user = user_id ? this.props.user.data.filter(data => data._id == e.target.id)[0] : {};
        let state = true
        if(this.state.close.state && this.state.close.user_id === user_id){
            state = false
        }
        this.setState({
            close: {
                user_id : user_id,
                state : state
            },
            current_user: current_user
        })
    }
    export = async () => {
        this.setState({loadmoreBtn: true});
        let {email, partnerId, name} = JSON.parse(localStorage.getItem('user'))

        if (parseInt(localStorage.getItem('partner_idd'))) {
            partnerId = parseInt(localStorage.getItem('partner_idd'));
        }

        await axiosCall('post', LAMDA_APICALL, {
            url: EXPORT_USERS,
            method: "POST",
            data: {
                email, name, "partner_id": partnerId,confirmed : true
            }
        }, (response) => {
            if (response.status) {
                this.context.showNotification(response.message, 'success', true)
            } else {
                this.context.showNotification(this.props.error, 'error', true)
            }
        });
        this.setState({loadmoreBtn: false});
    }

    async viewTrackingHistory(kitId){
        console.log(kitId, "modal kit")
        await this.trackingHistory(kitId)
        
      }
      
    render() {
        const {loading, data, totalRows} = this.state;

        createTheme('solarized', {
            text: {
                primary: '#717171',
                secondary: '#268bd2',
            },
            background: {
                default: '#ffffff',
            },
            divider: {
                default: '#1595c5',
            },
            button: {
                default: 'rgb(38, 139, 210)',
                hover: 'rgba(0,0,0,.08)',
                focus: 'rgba(255,255,255,.12)',
                disabled: 'rgba(0,0,0,.12)',
            },
        });
        return (
            <section className="cnfAccount-wrp">
                <div className="tableHeadMain">
                    <div className="tsecOne">
                        <SearchDatatable searchAll={this.search} />
                    </div>
                    <div className="tsecTwo">
                        <LoadingButton
                            disabled={this.state.btnStatus}
                            color={this.state.btnColorLoad}
                            className='sUserAdd1 loading-blue'
                            text={this.state.btnTextLoad}
                            action={this.export}
                            loading={this.state.loadmoreBtn}
                        />

                    </div>
                </div>

                {/* Add class open to "cnfAccountList" to show Account Profile */}

                <div className={`cnfAccountList ${this.state.close.state ? 'open' : ''}`}>    
                    <div className="accountTable card">
                        <Grid cols={this.props.admin.partner == 1 ? 5 : 4}>
                            <Filter options={this.props.admin.partner == 1 ?new_account_type: account_type} selected={this.account_filter} label={'ACCOUNT TYPE'} value={sevendays} />
                            <Filter options={status} selected={this.status_filter} label={'RESULT PROGRESS'} value={sevendays} />
                            <Filter options={time} selected={this.time_filter} label={'ACTIVE'} value={sevendays} />
                            <Filter options={time} selected={this.new_user_filter} label={'NEW USERS'} value={sevendays} />
                            {this.props.admin.partner == 1 &&
                            <Filter options={subscribed} selected={this.subscribed_filter} label={'SUBSCRIBED'} value={sevendays} />
                            }
                        </Grid>
                        <DataTable
                            columns={this.columns(this.open)}
                            data={this.props.user.data}
                            progressPending={this.state.loading}
                            pagination
                            paginationServer
                            paginationTotalRows={this.props.user.total}
                            onChangeRowsPerPage={this.handlePerRowsChange}
                            onChangePage={this.handlePageChange}
                            progressComponent={<DataLoader />}
                            className="superDtableWrap"
                            theme="solarized"
                        />
                    </div>

                    <div className="accountProfile card card2">
                        <UpdateForm close={this.close} user={this.state.current_user} access ={this.access}/>
                    </div>
                </div>
            </section>
        );
    }
}
confirmedUser.contextType = ViewContext;
const mapStateToProps = state => ({
    user: state.users.user,
    error: state.error.error,
    admin: state.admin
})
export default connect(mapStateToProps, { getUsers, addUser, addPartnerId })(confirmedUser);
