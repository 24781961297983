/***
*
*   PROFILE
*   Enables the user to update their name and email address
*   or close their account.
*
**********/

import React from 'react';
import Axios from 'axios';

import {
  AuthContext, ViewContext, Form, Card,
  AccountNav
} from 'components/lib';

export class Profile extends React.Component {

  constructor(props) {

    super(props);
    this.state = {

      loading: false,
      upUrl: null,
      profile: {
        name: {
          label: 'Name',
          type: 'text',
          required: true,
          errorMessage: 'Please enter your name',
        },
        email: {
          label: 'Email',
          type: 'email',
          required: true,
          errorMessage: 'Please enter your email address'
        },
        phone: {
          label: 'Phone',
          type: 'phone',
          required: true,
          errorMessage: 'Please enter your Phone'
        }
      },
    }
  }

  async componentDidMount() {

    // get the data

    try {

      this.setState({ loading: true });
      let user = JSON.parse(localStorage.getItem('user'));

      let res = await Axios.get(`/api/user/` + user.userId);

      if (res.data.status) {
        let user = res.data.data;
        this.setState({
          upUrl: `/api/user/profile/` + user.userId,
          loading: false,

          profile: {
            ...this.state.profile,

            name: {
              ...this.state.profile.name,
              value: user.name
            },
            email: {
              ...this.state.profile.email,
              value: user.email
            },
            phone: {
              ...this.state.profile.phone,
              value: user.phone
            }
          }
        });
      }

    }
    catch (err) {

      this.context.handleError(err);

    }
  }


  render() {

    return (
      <>
        <AccountNav />

        <AuthContext.Consumer>
          {(context) => (

            <Card
              title="Edit Your Profile Information"
              loading={this.state.loading}>

              <Form
                className='restrict-width'
                url={this.state.upUrl}
                method='PUT'
                data={this.state.profile}
                buttonText='Save'
              />


            </Card>

          )}
        </AuthContext.Consumer>
      </>
    );
  }
}

Profile.contextType = ViewContext;
