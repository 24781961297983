/***
*
*   RESET PASSWORD
*   Activated from the link send in the user email.
*   Triggers a reset on the server when opened.
*
**********/

import React from 'react';
import Axios from 'axios';
import { ViewContext, Link } from 'components/lib';

export class ResetPassword extends React.Component {

  componentDidMount(){

    try {

      Axios.post('/api/user/password/reset', {
        email: window.location.hash.substring(1)
      });

    }
    catch (err){

      this.context.handleError(err);

    }
  }

  render(){
    return(

      <div class='message'>

        <h1>Password Reset</h1>
        <p>Your new password is on it's way – please check your inbox</p>
        <Link url='/signin' className='btn' text='Sign In' />

      </div>
    );
  }
}

ResetPassword.contextType = ViewContext;
