/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*
**********/

import React from 'react'
import './header.scss';
import i18n from '../../i18n';
import PartnerDropdown from "components/partners/partnerDropdown";


export class Header extends React.Component {

  render(){
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    return (
      <header>
        
        { this.props.title &&
          <h1>{ this.props.title }</h1>
        }

        <PartnerDropdown></PartnerDropdown>
        
        {/* <div style={{position: "absolute",top: "27px",right: "35%"}}>
          <button style={{marginRight: "20px"}} className="btn lang-btn" onClick={() => changeLanguage('en')}>en</button>
          <button style={{marginRight: "20px"}} className="btn lang-btn" onClick={() => changeLanguage('de')}>de</button>
        </div> */}

        { this.props.children }

      </header>

    );
  }
}
