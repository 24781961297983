import React from 'react';
import { connect } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';

import memoize from 'memoize-one';
import moment from 'moment';

import { axiosCall } from '../../helpers';
import { DOB_CSV_EXPORT, LAMDA_APICALL } from '../../apiurl';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';
import { getKitId } from '../../actions/userAction';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';

const columns = memoize((clickHandler, success) => [
  {
    name: 'Kit Number',
    selector: 'kit',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'First Name',
    cell: (row) => <div>{row.firstName}</div>,
  },
  {
    name: 'Last Name',
    cell: (row) => <div>{(row.lastName)}</div>,
  },
  {
    name: 'Email',
    cell: (row) => <div>{(row.email)}</div>,
    hide: 'md',
  },
  {
    name: 'Date of Birth',
    cell: (row) => <div>{row.dob == '-' ? '-' : moment(row.dob).format("YYYY-MM-DD")}</div>,
    hide: 'md',
  },
  {
    name: 'Kit Status',
    cell: (row) => <div>{row.kit_status}</div>,
    hide: 'md',
  },
  {
    name: 'Partner',
    cell: (row) => <div>{row.partner}</div>,
    hide: 'md',
  }
]);

export default class dobExport extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      data: [],
      loading: false,
      reload: false,
      perPage: 100,
      searchVal: '',
      prevData: [],
      blackListedState: null,
      loadmoreValue: '',
      btnStatus: false,
      btnColorLoad: 'blue',
      btnTextLoad: 'Download CSV',
      btnTextLoading: 'Downloading..',
      uploadSuccess: false,
      buttonStyle: { display: 'block' },
      claimOption: [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ],
      claimVal: { value: null, label: 'All' },
      validVal: { value: null, label: 'All' },
      claimedState: null,
      validOption: [
        { value: null, label: 'All' },
        { value:false , label: 'Yes' },
        { value:true, label: 'No' }
      ]

    };

    this.exportDobData = this.exportDobData.bind(this);
  }

  async componentDidMount() {
    console.log("composnet did mount", this.state.data)
    this.setState({ loading: true });

    this.setState({
      data: [],
      prevData: []
    });
    // await this.checkButtonStaus();
    this.setState({ loading: false });
  }


  checkButtonStaus = async (val) => {
    console.log("check button status")
    console.log("value", val)
    let result = []
    this.state.prevData.map((items)=>{
      if(items.kit_id.includes(val)){
        result.push(items)
      }
      // if(val.includes(items.email)){
      //   console.log("pushedd")
      //   result.push(items)
      // }
    })
    this.setState({data:result})
  };

  downloadCSV = async () => {
    const { data } = this.state;
        //define the heading for each row of the data
        let csv = 'Kit Id, First Name, Last Name, Email, DOB, Kit Status, Partner\n';
        
        //merge the data with CSV
        data.forEach(function (row) {
            let rowData = [
                row.kit,
                row.firstName,
                row.lastName,
                row.email,
                row.dob != '-' ? moment(row.dob).format("YYYY-MM-DD"): '-',
                row.kit_status,
                row.partner
            ];
            csv += rowData.join(',');
            csv += "\n";
        });
     
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        
        //provide the name for the CSV file to be downloaded
        hiddenElement.download = 'DOB Export.csv';
        hiddenElement.click();
  }

  // searchCall = async (val) => {
  //   console.log("search ", val)
  //   console.log(this.state.prevData, this.state.data)
  //   if(this.state.data.length < 1){
  //     this.context.showNotification("No data present to search!...", 'error', false);
  //   }
  //   if(val === ""){
  //     this.setState({data: this.state.prevData })
  //   }
  //   await this.setState({ searchVal: val, loadmoreValue: '' });
  //   // this.setState({ data: this.props.kitDetails.xx.Items });
  //   await this.checkButtonStaus(val);
  // };

  exportDobData = async () => {
    await this.context.showModal({
      title: "Export DOB Data",
      dobExport: true,
      csvCallback: this.importJson
    });
  }

  importJson = async (json) => {
    console.log("json", json);

    if(json.report){
        console.log("was printed")
        this.context.showNotification(json.error, 'error', false);
    } else if (json.length > 0) {

      await axiosCall('post', LAMDA_APICALL, {
        url: DOB_CSV_EXPORT,
        method: "POST",
        data: {
          kits: json
        }
      }, (response) => {
        if (response.status) {
          this.context.hideModal(true);
          this.context.showNotification("Successfully completed", 'success', true);
          console.log("data", response)
          this.setState({data: response.data, prevData: response.data, uploadSuccess: false })
        } else {
          this.context.hideModal(true);
          this.context.showNotification("Some error occured !...", 'error', false);
        }
      });

    } else {
      this.context.showNotification("Please upload a valid CSV file", 'error', false);      
    }

  }


  render() {

    const { loading, data, totalRows } = this.state;

    createTheme('solarized', {
      text: {
        primary: '#717171',
        secondary: '#268bd2',
      },
      background: {
        default: '#ffffff',
      },
      divider: {
        default: '#1595c5',
      },
      button: {
        default: 'rgb(38, 139, 210)',
        hover: 'rgba(0,0,0,.08)',
        focus: 'rgba(255,255,255,.12)',
        disabled: 'rgba(0,0,0,.12)',
      },
    });
    return (
      <div>
        <div className="tableHeadMain">
          <Button className='sUserAdd upKit cntrBtn' text=' Export DOB Report' action={this.exportDobData} />
        </div>

        <div className="superDataTable kitIdTable">
         
          <DataTable
            // title="Users"
            columns={columns(this.handleButtonClick, this.state.uploadSuccess)}
            data={data}
            progressPending={loading}
            onSort={this.handleSort}
            sortServer
            progressComponent={<DataLoader />}
            className="superDtableWrap"
            theme="solarized"
          />
          <div className="centerLoadBtn" style={this.state.buttonStyle}>
            {
              this.state.data.length > 0?
              <LoadingButton
                disabled={this.state.btnStatus}
                color={this.state.btnColorLoad}
                className='upload'
                text={this.state.btnStatus? this.state.btnTextLoading : this.state.btnTextLoad}
                action={this.downloadCSV}
                loading={false}
              />
              : null
            }
          </div>
        </div>

      </div>
    );
  }
}
dobExport.contextType = ViewContext;

// const mapStateToProps = state => ({
//   kitDetails: state.admin.kitDetails
// })
// export default connect(mapStateToProps)(dobExport);
