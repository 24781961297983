/***
*
*   USERS
*   Enables an admin to manage the users in their application.
*
**********/

import React from 'react';
import Axios from 'axios';

import { ViewContext, Card, AccountNav, Table,
  DeleteTableRow, UpdateTableRow, Actions, Button, IconButton } from 'components/lib';

export class Users extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,

      users: {
        header: [
          { name: 'name', title: 'Name', sort: true },
          { name: 'email', title: 'Email', sort: true },
          { name: 'date_created', title: 'Date Created', sort: true },
          { name: 'last_login', title: 'Last Login', sort: true },
          { name: 'permission', title: 'Permission', sort: true },
        ],
        body: null
      }
    }

    // modal forms
    this.addUserForm = {
      email: {
        label: 'Email',
        type: 'email',
        required: true,
      }
    }

    this.editUserForm = {
      id: {
        type: 'hidden',
        value: null
      },
      name: {
        label: 'Name',
        type: 'text',
        required: true,
        errorMessage: 'Please enter a name'
      },
      email: {
        label: 'Email',
        type: 'email',
        required: true,
      },
      permission: {
        label: 'Permission',
        type: 'select',
        options: [],
      }
    }

    this.deleteUserForm = {
      id: {
        type: 'hidden',
        value: null
      }
    }

    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.invite = this.invite.bind(this);

  }

  async componentDidMount(){

    try {

      // get the users
      this.setState({ loading: true });

      const res1 = await Axios.get('/api/users');
      const res2 = await Axios.get('/api/user/permissions');

      // format for ui
      res2.data.permissions.forEach(perm => {
        if (perm !== 'owner'){

          this.editUserForm.permission.options.push({
            value: perm.id, name: perm.role
          });
        }
      });

      this.setState({

        loading: false,

        users: {
          ...this.state.users,
          body: res1.data.users
        }
      });

    }
    catch (err){

      this.context.handleError(err);

    }
  }

  invite(data){

    this.context.showModal({

      title: 'Add User',
      form: this.addUserForm,
      buttonText: 'Send Invite',
      text: 'To invite more than one user, seperate the emails with a comma',
      url: '/api/user/invite',
      method: 'POST'

    });
  }

  edit(data){

    // update the user
    for (let key in this.editUserForm)
      this.editUserForm[key].value = data[key]

    this.editUserForm.permission.default = data.permission;

    this.context.showModal({

      title: 'Update User',
      form: this.editUserForm,
      buttonText: 'Save',
      url: '/api/user',
      method: 'PUT'

    }, (res) => {

      this.context.showNotification(data.name + ' was updated', 'success', true);
      UpdateTableRow(this.state.users.body, res, data.id);

    });
  }

  delete(data){

    // delete the user
    this.deleteUserForm.id.value = data.id

    this.context.showModal({

      title: 'Delete User',
      form: this.deleteUserForm,
      buttonText: 'Delete User',
      text: 'Are you sure you want to delete ' + data.name + '?',
      url: '/api/user',
      method: 'DELETE'

    }, () => {

      this.context.showNotification(data.name + ' was deleted', 'success', true);
      DeleteTableRow(this.state.users.body, data.id);

    });
  }

  render(){
    return (

      <>
      <AccountNav />

      <section className="title-row">

        <h2>Manage Users</h2>
        <Button className='small' text='Add User' action={ this.invite }/>

      </section>

      <Card loading={ this.state.loading }>

        <Table
          className='restrict-width'
          data={ this.state.users }
          loading={ this.state.loading }
          badge={{ col: 'permission', color: 'blue' }}>
          <Actions>
            <IconButton image='edit' color='dark' action={ this.edit }/>
            <IconButton image='trash' color='dark' action={ this.delete }/>
          </Actions>
        </Table>
      </Card>
      </>

    );
  }
}

Users.contextType = ViewContext;
