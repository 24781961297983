/***
 *
 *   APP NAV
 *   The primary navigation used inside the main application.
 *
 *   PROPS
 *   type: fixed or slideout
 *   items: array of objects containing label and link keys
 *   hasSubNav: true/false – used for routing sub routes eg. /account/billing
 *
 **********/

import React from 'react';
import { NavLink } from "react-router-dom";
import { Logo, IconButton } from 'components/lib';
import SubNav from './subNav';
import ClassNames from 'classnames';
import './app.scss'

import { connect } from 'react-redux';
import { addPartnerId, addPartnerName, setMenuKey } from '../../../actions/userAction';

class AppNav extends React.Component {
    className;

    constructor(props) {

        super(props);

        this.state = {

            open: this.props.type === 'fixed' ? true : false,
            isClick: false,
            menuItems: this.props.items
        }
        

    }


    async componentDidUpdate() {
        var pName = this.props.partner_name;
        if (this.props.items) {
            let menu = this.props.items;
            let MainMenu = menu.filter((fmenu) => fmenu.link === "/adminDashboard");
            let remArray = menu.filter((fmenu) => fmenu.link !== "/adminDashboard");
            if (MainMenu.length > 0) {
                MainMenu[0].link = "/adminDashboard/" + pName
                remArray.forEach(mmm => {

                    MainMenu.push(mmm);
                });
                this.setState({ menuItems: MainMenu });
            }
        }
    }

    toggle() {

        this.setState({ open: this.state.open ? false : true });

    }

    menuClick = (key) => {
        this.props.setMenuKey(key)
    }

    render() {


        let userLocal = JSON.parse(localStorage.getItem('user'));
        let partnerId
        if (userLocal && userLocal.Partner && userLocal.Partner.partnerId) {
            partnerId = userLocal.Partner.partnerId
        }
        if (JSON.parse(localStorage.getItem('partner_idd'))) {
            partnerId = JSON.parse(localStorage.getItem('partner_idd'))
        }

        const type = 'mobile-' + this.props.type;

        const cssClass = ClassNames({

            'sidebar': true,
            'open': this.state.open,
            [type]: this.props.type,

        });

        return (
            <nav className={cssClass}>

                <IconButton
                    image={this.state.open ? 'cross' : 'nav'}
                    color='dark'
                    className='btn-togglenav'
                    action={this.toggle}
                />
                <div className="logo-container">
                <Logo />
                </div>
                <section className='nav-links'>
                    {this.props.items &&
                        this.props.items.map((item, index) => {

                            if (item.hasSubNav) {
                                return (
                                    <SubNav key={item.label} allItems={this.props.items} item={item}></SubNav>
                                )
                            } else {
                                return (
                                    <NavLink
                                        key={item.label}
                                        exact to={item.link}
                                        style={{ width: (100 / this.props.items.length) + '%' }}
                                        onClick={() => this.menuClick(item.key)} >
                                        {item.label}
                                    </NavLink>
                                );
                            }
                        })}
                </section>
            </nav>
        );
    }
}



const mapStateToProps = state => ({
    partner: state.admin.partner,
    partner_name: state.admin.kitDetails
});

export default connect(mapStateToProps, { addPartnerId, addPartnerName, setMenuKey })(AppNav);
