import React from 'react';
import { Label } from '../label/label';
import './radio.scss';

export class Radio extends React.Component {

  render(){

    let cssClass = 'radio'
    let option, label;

    if (this.props.className) cssClass += ' ' + this.props.className;

    if (this.props.option.value) option = this.props.option.value;
    else option = this.props.option;

    if (this.props.option.label) label = this.props.option.label;
    else label = this.props.option;

    return (
      <div className={ cssClass }>
        <input
          type="radio"
          name={ this.props.name }
          id={ option }
          value={ option }
          checked={ this.props.checked ? 'checked' : '' }
          onChange={ event =>
            this.props.callback(this.props.index, this.props.checked, option)
          }
        />

        <Label
          text={ label }
          required={ this.props.required }
          for={ option }
        />

      </div>
    );
  }
}
