/***
*
*   SETUP LAYOUT
*   You can delete this after completing the setup process.
*
**********/

import React from 'react';

import { Logo } from 'components/lib';
import '../layout.scss';
import './setup.scss';

export class SetupLayout extends React.Component {

  render(){

    document.body.classList.add('color-bg');

    return (

      <main className='setup'>

        <Logo/>
        <this.props.children/>

      </main>
    );
  }
}
