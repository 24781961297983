/***
*
*   FORGOT PASSWORD
*
*
**********/

import React from 'react';
import { Form, Card } from 'components/lib';
import { FORGOT_PASSWORD, GET_PARTNER_VIA_EMAIL } from '../../apiurl';
import { ViewContext } from 'components/lib';
import { Select } from '../../components/form/select/select'
import { Link, LoadingButton, History } from 'components/lib';
import ClassNames from 'classnames';
import { apiCall, getMenu } from '../../helpers';
import { AutoFillEmailInput } from 'components/form/input/autofillEmail';

export class ForgotPassword extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      email: {
        valid: undefined,
        loading: false,
        value: ''
      },
      partner: {
        loading: false,
        visibility: false,
        data: []
      },
      button: {
        disabled: true,
        text: "Reset Password",
        loading: false,
      }
    }
  }
  userMessage = async (res) => {
    this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
  };
  getPartners = async (email) => {
    try {
      let response = await apiCall('post', GET_PARTNER_VIA_EMAIL, { email })
      if (response.status) {
        if (response.data && response.data.length > 0) {
          await this.setState({
            partner: {
              loading: false,
              visibility: true
            },
            button: {
              disabled: false,
              text: "Reset Password",
              loading: false,
            }
          });
          let data = []
          response.data.forEach(async p => {
            if (p.Partner) {
              data.push({
                value: p.Partner.partnerId, name: p.Partner.name
              });
            }
          })
          await this.setState({
            partner: {
              visibility: data.length > 0,
              data: data,
              valid: data.length > 0,
              value: data.length > 0 ? data[0].value : null
            },
            button: {
              disabled: false,
              text: "Reset Password",
              loading: false
            }
          });

        } else {
          // No user right now
          this.context.showNotification("There is no Muhdo account for this email you have entered.", "error", true);
          await this.setState({
            button: {
              disabled: true,
              text: "Reset Password",
            }
          });
        }
      } else {
        this.context.handleError(response.message);
      }

    }
    catch (err) {
      this.context.handleError(err);
    }
  }
  _onBlurEmail = async (input, value, valid) => {
    this._onChange(input, value, valid)
  }
  _onChange = async (input, value, valid) => {

    await this.setState({
      email: {
        value, valid
      },
      partner: {
        loading: true,
        visibility: false
      },
      button: {
        disabled: true,
        text: "Reset Password",
        loading: false
      }
    });

    if (valid) {
      console.log(valid)
      // calling dropdown
      await this.setState({
        button: {
          disabled: true,
          text: "Loading",
          loading: true,
        }
      });
      await this.getPartners(value)

    }
  }
  submit = async (e) => {
    var disabled = this.state.button.disabled || !this.state.email.valid

    e.preventDefault();

    if (disabled) return

    var data = {
      email: this.state.email.value,
      partner_id: this.state.partner.value ? this.state.partner.value : null,
    }
    console.log(data, "promose")
    await this.setState({
      button: {
        loading: true,
        disabled: true,
        visibility: true,
        text: "Loading",
      }
    })
    let response = await apiCall('post', FORGOT_PASSWORD, data).catch(err => {
      console.log(err)
      this.context.handleError(err.message);
    })
    await this.setState({
      button: {
        loading: false,
        disabled: false,
        visibility: true,
        text: "Reset Password",
      }
    })
    if (response) {
      if (response.status) {
        this.context.showNotification(response.message, 'success', true)
        this.setInitialState()
      } else {
        this.context.handleError(response.message);
      }
    }


  }

  setInitialState = async () => {
    await this.setState({
      email: {
        valid: undefined,
        loading: false,
        value: ''
      },
      partner: {
        loading: false,
        visibility: false,
        data: []
      },
      button: {
        disabled: true,
        text: "Reset Password",
        loading: false,
      }
    }
    )
    console.log("form clearing")
  }

  render() {
    const cssClass = ClassNames({
      'loading': this.state.loading,
      [this.props.className]: this.props.className
    })
    var disabled = this.state.button.disabled || !this.state.email.valid
    console.log(this.state.button.disabled, !this.state.email.valid)
    return (
      <>
        <h1>Reset Your Password</h1>
        <Card>
          <p>Enter your email address and we'll send you
        instructions to reset your password</p>

          <form
            method="POST"
            onSubmit={this.submit}
            className={cssClass}
            noValidate>
            <AutoFillEmailInput
              key={"email"}
              type='text'
              form="signin"
              label={"EMAIL"}
              className={''}
              name={'email'}
              required={true}
              onChange={this._onChange}
              onBlur={this._onBlurEmail}
              valid={this.state.email.valid}
              autoComplete={'off'}
            />
            <div style={this.state.partner.visibility ? {} : { display: 'none' }} ><Select
              key={"partner"}
              form="signin"
              label={"Partner"}
              className={''}
              name={'partner'}
              required={true}
              options={this.state.partner.data}
              onChange={async (input, value, valid) => {
                this.setState({
                  partner: {
                    valid: true, value: parseInt(value),
                    visibility: this.state.partner.visibility, data: this.state.partner.data
                  }
                })
              }}
            />
            </div>
            <LoadingButton
              loading={this.state.button.loading}
              text={this.state.button.text}
              // disabled={disabled}
              className={disabled ? 'grey btn-disabled' : 'blue'}
            />

          </form>

        </Card>
      </>
    );
  }
}
ForgotPassword.contextType = ViewContext;