/***
*
*   PASSWORD
*   Enables the user to update their password.
*
**********/

import React from 'react';
import { ViewContext, Card, Form, AccountNav } from 'components/lib';

export class Password extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      apiUrl: null,
    }

    this.password = {
      oldPassword: {
        label: 'Old Password',
        type: 'password',
        required: true
      },
      newPassword: {
        label: 'New Password',
        type: 'password',
        required: true
      },
      confirmPassword: {
        label: 'confirm Password',
        type: 'password',
        required: true
      }
    }
  }

  async componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.setState({ apiUrl: `/api/user/password/` + user.userId  });
  }

  render() {
    return (

      <>
        <AccountNav />

        <Card title="Update Your Password">
          <Form
            className='restrict-width'
            url= {this.state.apiUrl}
            method='PUT'
            buttonText='Save Password'
            data={this.password}
            // callback = {this.resetForm}
          />
        </Card>
      </>

    );
  }
}

Password.contextType = ViewContext;
