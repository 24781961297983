import { ADD_USER, PARTNER_DETAILS, GET_KITID, PARTNER_INFO, MENU_KEY ,UPDATE_USER_EDIT,GET_DEMO_USER} from './types';
import { axiosCall } from '../helpers';
import { LAMDA_APICALL, GET_KIT_ID,GET_DEMO_USERS } from '../apiurl';


export const addUser = (data) => dispatch => {
    if (data) {
        dispatch({
            type: ADD_USER,
            payload: data
        })
    } else {
        console.log('no data found')
    }
};

export const addPartnerId = (data) => dispatch => {
    if (data) {
        dispatch({
            type: PARTNER_DETAILS,
            payload: parseInt(data)
        })
    } else {
        console.log('no data found')
    }
};
export const addPartnerName = (data) => dispatch => {
    console.log(data, 'data from actin')
    if (data) {
        dispatch({
            type: PARTNER_INFO,
            payload: data
        })
    } else {
        console.log('no data found')
    }
}

export const getKitId = (data) => async dispatch => {
    console.log(data, 'data')
    await axiosCall('post', LAMDA_APICALL, {
        url: GET_KIT_ID,
        method: "POST",
        data: data
    }, (response) => {
        if (response.status) {
            dispatch({
                type: GET_KITID,
                payload: response.data
            })
        } else {
            console.log('Data Error')
        }
    });
};

export const getDemoUser = (data) => async dispatch => {
    console.log(data, 'data')
    await axiosCall('post', GET_DEMO_USERS, {data}, async (response) => {
        if (response.status) {
            dispatch({
                type: GET_DEMO_USER,
                payload: response.data
            })
        } else {
            console.log('Data Error')
        }
    });
};

export const setMenuKey = (data) => dispatch => {
    console.log(data, 'data from actin')
    if (data) {
        dispatch({
            type: MENU_KEY,
            payload: data
        })
    } else {
        console.log('no data found')
    }
}
export const updateUserAction = (data) => dispatch =>{
    console.log(data,UPDATE_USER_EDIT)
    dispatch({
        type: UPDATE_USER_EDIT,
        payload: data
    })
}
