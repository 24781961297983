/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
*   1. fetches sample data from the server (stats, progress and users)
*   2. renders the ui with stats, charts and table
*
**********/

import React from 'react';
import { connect } from 'react-redux';
import {
  ViewContext, Card,
  SuperAdminCharts, Grid, Selectcard, DashboardBox
} from "components/lib";
import { addUser } from '../../actions/userAction';
import { axiosCall } from '../../helpers';
import { GET_PARTNER, GET_GRAPH_DATA } from '../../apiurl';

class Dashboard extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      partnerValue: "",
      loading: false,
      progress: null,
      stats: null,
      allTypes: 'ALL',
      tVal: 'ALL',
      defaultDate: '7d',
      accountsOptions: [
        { value: 'ALL', label: 'All' },
        { value: 'DNA', label: 'DNA' },
        { value: 'EPI', label: 'EPI' },
        { value: 'EPI_PLUS', label: 'EPI+' },
      ],
      activeOptions: [
        { value: '24h', label: 'Last 24hrs' },
        { value: '7d', label: 'Last 7 days' },
        { value: '30d', label: 'Last 30 days' },
        { value: '90d', label: 'Last 90 days' },
        { value: '180d', label: 'Last 180 days' },
      ],
      graphOptions: [
        { value: '24Hrs', label: 'Last 24hrs' },
        { value: 'Last7Days', label: 'Last 7 days' },
        { value: 'Last30Days', label: 'Last 30 days' },
        { value: 'Last90Days', label: 'Last 90 days' },
        { value: 'Last180Days', label: 'Last 180 days' },
      ]
    }

    this.partnerOptions = [];
    this.partnerChange = this.partnerChange.bind(this);
    this.graphPartnerOptions = [{ value: "", label: "All" }];
  }

  async componentDidMount() {

    try {
      this.props.addUser(localStorage.getItem('user'))
      this.setState({ loading: true });

      await axiosCall('get', GET_PARTNER, {}, (response) => {
        if (response.status) {
          response.data.forEach(partner => {
            this.partnerOptions.push({
              value: partner.partnerId, label: partner.name
            });
            this.graphPartnerOptions.push({
              value: partner.partnerId, label: partner.name
            });
          });
          this.setState({
            loading: false,
          });
        } else {
          this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
        }
      });
    }
    catch (err) {

      this.context.handleError(err);

    }
  }

  partnerChange = async (e) => {
    await this.setState({ partnerValue: e.target.value });
  }


  render() {

    return (
      <>
        <DashboardBox />
        <div className="report-wrp">
          <Card name='User Graph'>
            <SuperAdminCharts
              type='line'
              legend={true}
              data={GET_GRAPH_DATA}
              filter={true}
              partnerOptions={this.graphPartnerOptions}
              filterOptions={this.state.graphOptions}
              // object='revenue'
              color={['red', 'blue', 'green', 'purple']}
            />
          </Card>
        </div>
        <div className="report-wrp">
          {/* <Card>
            <select className="select__type__II">
              <option>DNA/EPI/All</option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>Number/%</option>
              <option>All</option>
            </select>
            <span>of people who have</span>
            <select className="select__type__II">
              <option>Select Sub-mode</option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>More than/equal to/less than</option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>Select Result </option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>Age</option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>Gender</option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>Height</option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>Weight</option>
              <option>All</option>
            </select>
            <select className="select__type__II">
              <option>Pollution</option>
              <option>All</option>
            </select>
          </Card> */}
        </div>
      </>

    );
  }
}

Dashboard.contextType = ViewContext;
const mapStateToProps = state => ({
  user: state.admin.user,
});
export default connect(mapStateToProps, { addUser })(Dashboard);
