/***
*
*   MODAL
*   To display an overlay modal anywhere in your application call
*   this.context.showModal({ }) with an object containing the following props
*
*   PROPS
*   title: Title shown in the modal
    data: User data to be shown in the modal
*
**********/

import React from 'react';
import { ViewContext, Icon } from 'components/lib';
import './modal.scss';
import { Card, Grid, TabView } from '../lib';
import moment from 'moment';
import UserAccessControl from '../../components/form/UserAccessControl';
import UserDetails from '../../components/form/UserDetails';
import DataTable from 'react-data-table-component';
import { DataLoader } from 'datatableComponents/DataLoader';
import memoize from 'memoize-one';

const columns = memoize(clickHandler => [
    {
        name: 'Kit Number',
        selector: 'kit_id',
        sortable: true,
        hide: 'md',
      },
      // {
      //   name: 'Upload Date',
      //   cell: (row) => <div>{row.createdAt ? moment(row.createdAt).format("YYYY-MM-DD hh:mm A"): ''}</div>,
      // },
      {
        name: 'Latest Tracking Status',
        selector: 'latest_kit_status',
        sortable: true,
        hide: 'md',
      },
      
      {
        name: 'Valid',
        cell: (row) => <div>{(row.black_listed) ? "No" : "Yes"}</div>,
        hide: 'md',
      },
      // {
      //   name: 'Latest Tracking Status',
      //   selector: 'latest_kit_status',
      //   sortable: true,
      //   hide: 'md',
      // },{
      //   name: 'Latest Tracking Date',
      //   cell: (row) => <div>{row.latest_tracking_date? moment(row.latest_tracking_date).format("YYYY-MM-DD hh:mm A") : ''}</div>,
      // },
  ]);

export class ViewKitDetailsModal extends React.Component {

  constructor(props) {

    super(props);
}
state = {
    data: [],
    parent_kit_data:[],
    kit_ids: '',
    first_name: '',
    last_name: '',
    email: '',
    kit_id: '',
    phone: '',
    kit_relations: [],
    epigenetic_data: [],
    account_type: [],
    country: '',
    address_lane1: '',
    address_lane2: '',
    zipcode: '',
    city: '',
    newsletter: '',
    terms_and_condition: false,
    research_purpose: false,
    subscribed: false,
    birthday: '',
    gender: '',
    weight: '',
    weight_unit: '',
    height: '',
    height_unit: 'cm',
    created_at: moment().format("YYYY-MM-DD hh:mm A"),
    last_login: moment().format("YYYY-MM-DD hh:mm A"),
    loading: false,
    table: {
        body: []
    },
    parent_loader: false,
    user:{},
    access:[
      "index",
      "update",
      "kitEdit",
      "childKit",
      "showRawData",
      "accessControl",
      "destroy"
  ]
}


componentDidUpdate(prevProps){
  if (this.props.data){
    if (prevProps.data != this.props.data){
    this.setState({
      table: {
        body: this.props.data.kit_relations && this.props.data.kit_relations[0] ? this.props.data.kit_relations[0].children.map(da => (
            {
                status: da.latest_kit_status,
                kit_id: da.kit_id,
                date_of_scanning: moment(da.latest_tracking_date).format('YYYY-MM-DD'),
                // view:<button className="tblAction fas fa-history" id={da.kit_id} name="viewHistory" title="Tracking History"></button>
            }
            
            )) 
            : []},
    data:this.props.data.kit_relations && this.props.data.kit_relations[0] ?this.props.data.kit_relations[0].children : [],

    })
  }
}
}

  async clearState() {

    await this.setState({
      rawdata: {
        body: [],
      },
      kitId: '',
      loader: false,
      update_status: false,
      time: null,
      select: null,
      checkbox:false,
      checked: true
    });
    console.log(this.state)

  }

  close = async (context) => {
    context.hideKitDetailsModal(true)
    this.clearState()
  }


  render() {
    console.log(this.props, "props =========================")
    return (
      <ViewContext.Consumer>
        {(context) => (
          // To close escape and outside modal
          // <div className={ "modal " + context.modal.visible }
          // onClick={(event) => this.close(event, context) }>
          <div className={"modal " + context.kitDetailsModal.visible} style={{ overflow: 'scroll' }}>
            <div className='kit-modal-content card'style={{padding:'0.5625em'}}>
              {this.props.title &&
                <header className='viewkithead'>
                  <h1>{this.props.title} </h1>
                  <div onClick={event => this.close(context)} className="closeBtnModal"><Icon color="dark" image="x" /></div>
                </header>
              }
              {this.props.data && this.props.data.kit_id ?
              <div className="table-container">
              <div className="filter-dots" style={{ fontSize: "13px" }}>

                <Grid cols='1'>
                  <div style={{ overflowY: 'auto', height: "400px" }}>
                    <div className="accountProfile card card2">
                    <TabView
                     name="Billing"
                     labels={[
                         "User Details",
                         "Child Kit ID",
                         "Access Control",
                     ]}
                     >
                        <Card><UserDetails user={this.props.data? this.props.data : {}} access={this.state.access.includes('update')} confirmed={true}/></Card>
                        <Card>
                        <>
                                <DataTable
                                    columns={columns(this.handleButtonClick)}
                                    data={this.state.data}
                                    progressPending={this.state.loading}
                                    onSort={this.handleSort}
                                    sortServer
                                    progressComponent={<DataLoader />}
                                    className="superDtableWrap"
                                    theme="solarized"
                                />
                        </>  
                    </Card>
                        <Card>
                        <UserAccessControl user={this.props.data? this.props.data: {}} access={this.state.access.includes('accessControl')} />
                    </Card>    
                    </TabView>
                    </div>
                    </div>
                    </Grid>
                    </div>
                    </div>

              : 
              <div className="table-container">
                      <p className='viewkitDetail-p' style={{paddingLeft: "155px"}}>Account details not found</p>
              </div>  
                    }
              
            </div>
          </div>
        )}
      </ViewContext.Consumer>
    );
  }
}
ViewKitDetailsModal.contextType = ViewContext;
