/***
*
*   PARTNER DROPDOWN
*   Header section with title used in main application
*
*
**********/

import React from 'react';
import { axiosCall } from '../../helpers';
import { GET_PARTNER } from '../../apiurl';
import { connect } from 'react-redux';
import { addPartnerId, addPartnerName } from '../../actions/userAction';
import { History,ViewContext } from '../../components/lib';

class PartnerDropdown extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            partnerValueHead: "0"
        };

        this.dropdownValue = [];
    }
    getPartners = async () => {
        try {
            this.setState({ loading: true });
            await axiosCall('get', GET_PARTNER, {}, async (response) => {

                if (response.status) {
                    await this.dropdownValue.push({
                        value: "0", label: "Super Admin"
                    });
                    await response.data.forEach(partner => {

                        this.dropdownValue.push({
                            value: partner.partnerId, label: partner.name
                        });
                    });
                    this.setState({
                        loading: false,
                    });
                }
            });
        }
        catch (err) {
            this.context.handleError(err);
        }
    }

    partnerView = async (url) => {

        let accepted = ['/adminDashboard'];
        let partner = localStorage.getItem('partner') ? localStorage.getItem('partner') : History.replace('/dashboard')

        let some = url + '/' + partner;

        if (accepted.includes(url)) {
            History.replace(some)
        }
        return null
    }
    async componentDidMount() {
        await this.getPartners(History.location.pathname);
    }

    partnerChangeHead = async (e) => {
        localStorage.setItem('partner_idd', e.target.value||0);
        await this.setState({ partnerValueHead: e.target.value });
        await this.props.addPartnerId(this.state.partnerValueHead);
        if (this.state.partnerValueHead == 0) {
            History.push('/dashboard')
        }
        else {
            let partnerName = this.dropdownValue.filter((data) => data.value == this.props.partner);

            let uri = partnerName[0] ? partnerName[0].label.replace(' ', '_').toLowerCase() : null;

            await this.props.addPartnerName(uri);
            await this.partnerView('/adminDashboard');
        }

    }
    async componentDidUpdate(prevProps) {
        if (prevProps.partner != this.props.partner) {
            await this.partnerView(History.location.pathname);
        }
    }
    render() {
        const user = JSON.parse(localStorage.getItem("user"))
        const show = user && user.Partner ? false : true
        if(user.UserRoleMapping[0].roleId!==2)
        return (
            <div className='partnerDropdown' style={show ? {} : { display: 'none' }}  >
                <select className='select__type__II' onChange={this.partnerChangeHead} value={this.props.partner}>
                    {this.dropdownValue.map(function (item, i) {
                        return <option key={i} value={item.value} >{item.label}</option>
                    })}
                </select>
            </div>
        );
        else 
          return (
                <div></div>
          )
    }
}
PartnerDropdown.contextType = ViewContext;
const mapStateToProps = state => ({
    partner: state.admin.partner,
    partner_name: state.admin.partner_name
});

export default connect(mapStateToProps, { addPartnerId, addPartnerName })(PartnerDropdown);