import React from 'react';
import { Line } from 'react-chartjs-2';

export function LineChart(props){

  let options = props.options;
  options.maintainAspectRatio = false;
  options.responsive = true;

  return <Line data={ props.data } options={ options } />

}
