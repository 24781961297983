import React from 'react';

export class Label extends React.Component {

  render(){

    let cssClass = '';
    if (this.props.required) cssClass += 'required';
    if (this.props.className) cssClass += ' ' + this.props.className;

    return(

      <label className={ cssClass !== '' ? cssClass : undefined } htmlFor={ this.props.for }>
        { this.props.text }
      </label>

    );
  }
}
