/***
*
*   CHART
*   Responsive chart that supports multiple datasets and chart types.
*
*   PROPS
*   type: line/bar/pie/donut/sparkline
*   legend: true/false to toggle the legend
*   data: API endpoint OR object for the chart data
*   object: object key to use in server response object (optional)
*   color: red/blue/purple/green (pass array for multiple datasets)
*
**********/

import React from 'react';
import Axios from 'axios';

import { ViewContext, Loader, Icon } from 'components/lib';
import { LineChart } from './line';
import { BarChart } from './bar';
import { PieChart } from './pie';
import { DonutChart } from './donut';
import { SparkLineChart } from './sparkline';
import './chart.scss';

const Colors = require('./colors.json');
const Options = require('./options.json');
const Methods = require('./methods');

export class Chart extends React.PureComponent {

  constructor(props){

    super(props);

    // config the options
    this.colors = JSON.parse(JSON.stringify(Colors));
    this.options = JSON.parse(JSON.stringify(Options));
    this.options.scales.yAxes[0].ticks.callback = Methods.roundTicks;
    this.options.tooltips.callbacks.label = Methods.formatTooltipLabel;

    this.charts = {

      line: LineChart,
      bar: BarChart,
      pie: PieChart,
      donut: DonutChart,
      sparkline: SparkLineChart

    }

    this.state = {

      loading: false,
      data: null,
      color: null

    }
  }

  componentDidMount(){

    // get the data from the server
    if (typeof this.props.data === 'string'){
      if (!this.state.data){

        this.getData();

      }
    }
    else {

      // use the object prop
      this.setState({

        data: this.props.data,
        loading: false,

      }, () => {

        this.setChartColors();

      });
    }
  }

  componentDidUpdate(prevProps, prevState){

    if (this.props.data === prevProps.data)
      return false;

  }

  async getData(){

    try {

      this.setState({ loading: true });

      let data;
      const res = await Axios.get(this.props.data);

      if (res.data){

        if (this.props.object)
          data = res.data[this.props.object];
        else
          data = res.data.data;

        data.type = this.props.type;

        this.setState({

          data: data,
          loading: false,

        }, () => {

          this.setChartColors();

        });
      }
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  setChartColors(){

    // set the color
    let colors = [];

    // override with user defined color
    if (this.props.color){
      if (Array.isArray(this.props.color)){
        if (this.props.color.length){
          for (let i = 0; i < this.props.color.length; i++){
            colors[i] = this.colors[this.props.color[i]];
          }
        }
      }
      else {
        colors[0] = this.colors[this.props.color];
      }
    }

    // set default color
    for (let color in this.colors)
      colors.push(this.colors[color]);

    if (this.state.data){
      if (this.state.data.datasets){
        if (this.state.data.datasets.length){

          const type = this.props.type;
          const data = this.state.data;

          for (let i = 0; i < data.datasets.length; i++){

            data.datasets[i].borderColor = colors[i].borderColor;
            data.datasets[i].backgroundColor = colors[i].backgroundColor[i];

            if (type === "line"){
              data.datasets[i].pointBackgroundColor = colors[i].pointBackgroundColor;
              data.datasets[i].backgroundColor = colors[i].transparentColor;
              data.datasets[i].pointRadius = colors[i].pointRadius;
              data.datasets[i].pointHoverRadius = colors[i].pointHoverRadius;
              data.datasets[i].pointBorderWidth = colors[i].pointBorderWidth;
              data.datasets[i].pointBackgroundColor = colors[i].pointBackgroundColor;
              data.datasets[i].pointHoverBackgroundColor = colors[i].pointHoverBackgroundColor;
              data.datasets[i].pointHoverBorderColor = colors[i].pointHoverBorderColor;
            }

            if (type === "sparkline"){
              data.datasets[i].backgroundColor = "transparent";
              data.datasets[i].pointRadius = 0;
              data.datasets[i].lineTension = 0;
            }

            if (type === "pie" || type === "donut"){
              data.datasets[i].borderColor = "#FFFFFF";
              data.datasets[i].hoverBorderColor = "transparent";
              data.datasets[i].backgroundColor = colors[i].backgroundColor;
            }
          }
        }
      }
    }

    this.setState({ color: colors });

  }

  render(){

    // set the chart colors and type
    const chartType = this.props.type ? this.props.type : 'line';
    const Chart = this.charts[chartType];

    if (this.state.loading)
      return <Loader/>

    if (this.state.data){

      // no chart data – render blank slate
      if (this.state.data.datasets[0].data.length === 0){
        return (
          <div className="chartWrapper">
            <div className="blankslate">
              <Icon image='chartup'/>
              No data to show
            </div>
          </div>
        );
      }

      // render the chart
      let legend = [];

      if (this.state.color){

        // create the legend
        if (this.props.legend){
          if (this.props.type === 'pie' || this.props.type === 'donut'){
            this.state.data.labels.map((label, index) => {

              legend.push(
                <li key={ label }>
                  <span style={{
                    backgroundColor: this.state.data.datasets[0].backgroundColor[index]
                  }}>
                  </span>
                  { label }
                </li>
              );

              return false;

            });
          }
          else if (this.props.type === 'line' || this.props.type === 'bar'){
            this.state.data.datasets.map((data, index) => {

              legend.push(
                <li key={ data.label }>
                  <span style={{
                    backgroundColor: this.state.data.datasets[index].borderColor
                  }}>
                  </span>
                  { data.label }
                </li>
              );

              return false;

            });
          }
        }

        // render the chart
        return (

          <div className="chartWrapper">

            <div id="chart-tooltip"></div>

            { this.props.legend &&
              <ul className='legend'>{ legend }</ul>
            }

            <div className='chart'>
              <Chart data={ this.state.data } options={ this.options } />
            </div>

          </div>

        );
      }
      else {

        return false;

      }
    }
    else {

      return false;

    }
  }
}

Chart.contextType = ViewContext;
