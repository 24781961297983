import React from 'react';
import { ViewContext, Button } from 'components/lib';
import memoize from 'memoize-one';
import { axiosCall } from '../../helpers';
import { USERLIST_API, SUPER_ROLES, GET_USER, EMAIL_RESENT } from '../../apiurl';

import DataTable, { createTheme } from 'react-data-table-component';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';

const columns = memoize(clickHandler => [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: true,
  },
  {
    name: 'Role',
    hide: 'md',
    cell: (row) => <div>{row.UserRoleMapping[0].Role.role}</div>,
  },
  {
    name: 'Action',
    cell: (row) => <div>
      {(row.status === 'RESET_PASSWORD') && (<button className="tblAction fas fa-envelope" onClick={clickHandler} id={row.userId} name="emailsent"></button>)}
      <button className="tblAction fas fa-edit" onClick={clickHandler} id={row.userId} name="edit"></button><button className="tblAction fas fa-trash" onClick={clickHandler} name="delete" id={row.userId}></button></div>,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
]);

export class masterUserManagement extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      searchVal: '',
      columnSort: '',
      sortType: '',
    };

    this.editUserForm = {
      // id: {
      //   type: 'hidden'
      // },
      name: {
        label: 'Name',
        type: 'text',
        requried: true,
      },
      // email: {
      //   label: 'Email',
      //   type: 'email',
      //   required: true
      // },
      phone: {
        label: 'Phone',
        type: 'phone',
        required: true
      },
      roleId: {
        label: 'Permission',
        type: 'select',
        options: [],
        required: true
      }
    };

    this.deleteUserForm = {
      userId: {
        type: 'hidden',
        value: null
      }
    };
    // To add a new user form
    this.addAdminForm = {
      name: {
        label: 'Name',
        type: 'text',
        required: true,
      },
      // password: {
      //   label: 'password',
      //   type: 'password',
      //   required: true,
      // },
      email: {
        label: 'Email',
        type: 'email',
        requried: true,
      },
      phone: {
        label: 'Phone ',
        type: 'text',
        requried: true,
      },
      roleId: {
        label: 'Permission',
        type: 'select',
        options: [],
        required: true,
      },
    };

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.addSuperAdmin = this.addSuperAdmin.bind(this);
  }


  async componentDidMount() {
    const { perPage, searchVal, columnSort, sortType } = this.state;

    this.setState({ loading: true });

    await axiosCall('get', SUPER_ROLES, {}, (response) => {
      if (response.status) {
        response.data.forEach(permission => {

          this.addAdminForm.roleId.options.push({
            value: permission.roleId, name: permission.role
          });

          this.editUserForm.roleId.options.push({
            value: permission.roleId, name: permission.role
          });
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });

    await axiosCall('post', USERLIST_API, {
      perPage: perPage,
      page: 1,
      query: searchVal,
      sort: sortType,
      column: columnSort
    }, (response) => {
      if (response.status) {
        const responseFinal = response.data;
        console.log("responseFinal", responseFinal);
        this.setState({
          data: responseFinal.data,
          totalRows: responseFinal.total,
          loading: false,
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  }

  // edit user  
  edit(data) {
    // populate the data;
    for (let key in this.editUserForm)
      this.editUserForm[key].value = data[key];
    // set dropdown default value
    this.editUserForm.roleId.default = data.roleId;

    //show modal
    this.context.showModal({

      title: 'Edit User',
      form: this.editUserForm,
      buttonText: 'Save',
      url: GET_USER + data.userId,
      method: 'PUT'

    }, (res) => {
      // show notification message
      this.context.showNotification(data.email + ' was updated', 'success', true);
      //set state data - to update table data
      this.updateTableData();
    });
  }

  delete(data) {
    for (let key in this.editUserForm)
      this.editUserForm[key].value = data[key];

    this.context.showModal({

      title: 'Delete User',
      form: this.deleteUserForm,
      buttonText: 'Delete User',
      text: 'Are you sure you want to delete ' + data.name + '?',
      url: GET_USER + data.userId,
      method: 'DELETE'

    }, () => {
      this.context.showNotification(data.name + ' was deleted', 'success', true);
      //Need to redraw the table - update state
      this.updateTableData();
    });
  }


  handlePageChange = async page => {
    const { perPage, searchVal, columnSort, sortType } = this.state;

    this.setState({ loading: true });

    await axiosCall('post', USERLIST_API, {
      perPage: perPage,
      page: page,
      query: searchVal,
      sort: sortType,
      column: columnSort
    }, (response) => {
      if (response.status) {
        const responseFinal = response.data;
        this.setState({
          data: responseFinal.data,
          loading: false,
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  }

  handlePerRowsChange = async (perPage, page) => {
    const { searchVal, columnSort, sortType } = this.state;
    this.setState({ loading: true });
    await axiosCall('post', USERLIST_API, {
      perPage: perPage,
      page: page,
      query: searchVal,
      sort: sortType,
      column: columnSort
    }, (response) => {
      if (response.status) {
        const responseFinal = response.data;
        this.setState({
          data: responseFinal.data,
          loading: false,
          perPage: perPage,
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  }

  handleSort = async (column, sortDirection) => {
    const { perPage, searchVal } = this.state;
    // simulate server sort
    this.setState({ loading: true });
    //const { perPage } = this.state;
    // instead of setTimeout this is where you would handle your API call.

    await axiosCall('post', USERLIST_API, {
      perPage: perPage,
      page: 1,
      query: searchVal,
      sort: sortDirection,
      column: column.selector
    }, (response) => {
      if (response.status) {
        const responseFinal = response.data;
        this.setState({
          data: responseFinal.data,
          loading: false,
          columnSort: column.selector,
          sortType: sortDirection,
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  };

  searchCall = async (val) => {
    const { perPage, columnSort, sortType } = this.state;

    await axiosCall('post', USERLIST_API, {
      perPage: perPage,
      page: 1,
      query: val,
      sort: sortType,
      column: columnSort
    }, (response) => {
      if (response.status) {
        const responseFinal = response.data;
        this.setState({
          data: responseFinal.data,
          loading: false,
          searchVal: val,
          totalRows: responseFinal.total,
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  };


  handleButtonClick = async (e) => {
    let actionName = e.target.name;
    let id = e.target.id;
    await axiosCall('get', GET_USER + id, {}, (response) => {
      if (response.status) {
        if (actionName === "edit") {
          let data = {
            name: response.data.name,
            email: response.data.email,
            phone: response.data.phone,
            userId: response.data.userId,
            roleId: response.data.UserRoleMapping[0].roleId
          }

          this.edit(data);
        } else if (actionName === "delete") {
          let data = {
            name: response.data.name,
            email: response.data.email,
            userId: response.data.userId
          }

          this.delete(data);
        }
        else if (actionName === "emailsent") {
          this.resentEmail(id);
        }
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  }
  resentEmail = async (id) => {
    this.setState({ loading: true });

    axiosCall('post', EMAIL_RESENT, { userId: id }, (response) => {
      if (response.status) {
        this.setState({
          loading: false
        });
        this.context.showNotification('Verification mail has been sent.', 'success', true);
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  }

  updateTableData = async () => {
    const { perPage, searchVal, columnSort, sortType } = this.state;

    this.setState({ loading: true });

    await axiosCall('post', USERLIST_API, {
      perPage: perPage,
      page: 1,
      query: searchVal,
      sort: sortType,
      column: columnSort
    }, (response) => {
      if (response.status) {
        const responseFinal = response.data;
        this.setState({
          data: responseFinal.data,
          totalRows: responseFinal.total,
          loading: false,
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  }

  addSuperAdmin = async () => {
    await this.context.showModal({

      title: 'Add User',
      form: this.addAdminForm,
      buttonText: 'Add User',
      url: GET_USER,
      method: 'POST'
    }, this.updateTableData);
  }

  render() {
    const { loading, data, totalRows } = this.state;

    createTheme('solarized', {
      text: {
        primary: '#717171',
        secondary: '#268bd2',
      },
      background: {
        default: '#ffffff',
      },
      divider: {
        default: '#1595c5',
      },
      button: {
        default: 'rgb(38, 139, 210)',
        hover: 'rgba(0,0,0,.08)',
        focus: 'rgba(255,255,255,.12)',
        disabled: 'rgba(0,0,0,.12)',
      },
    });
    return (
      <div >
        <div className="tableHeadMain">
          <div className="tsecOne">
            <SearchDatatable searchAll={this.searchCall} />
          </div>
          <div className="tsecTwo">
            <Button className='sUserAdd' text='Add User' action={this.addSuperAdmin} />
          </div>
        </div>

        <div className="superDataTable">
          <DataTable
            // title="Users"
            columns={columns(this.handleButtonClick)}
            data={data}
            progressPending={loading}
            onSort={this.handleSort}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={this.handlePerRowsChange}
            onChangePage={this.handlePageChange}
            progressComponent={<DataLoader />}
            className="superDtableWrap"
            theme="solarized"
          />
        </div>
      </div>
    );
  }
}
masterUserManagement.contextType = ViewContext;