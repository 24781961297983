import {
    ADD_USER, PARTNER_DETAILS,
    PARTNER_INFO, GET_KITID, MENU_KEY,GET_DEMO_USER
} from '../actions/types';

const initialState = {
    user: [],
    partner: localStorage.getItem('partner_id') ? localStorage.getItem('partner_id') : 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_USER:

            return {
                ...state,
                user: action.payload
            };
        case PARTNER_DETAILS:
            localStorage.setItem('partner_id', action.payload)

            return {
                ...state,
                partner: action.payload
            };
        case PARTNER_INFO:
            localStorage.setItem('partner', action.payload)

        case GET_KITID:
            return {
                ...state,
                kitDetails: action.payload
            }
            break;
        case MENU_KEY:
            localStorage.setItem('menuKey', action.payload)
            return {
                ...state,
                menu: action.payload
            }
            break;
        case GET_DEMO_USER:
        return {
            ...state,    
            demoDetails: action.payload
        }
        break;
        default:
            return state;
    }
}
