/***
*
*   MODAL
*   To display an overlay modal anywhere in your application call
*   this.context.showModal({ }) with an object containing the following props
*
*   PROPS
*   title: (optional)
*   text: message to the user (optional)
*   form: a form object (optional: see form docs for more information)
*   url: destination to send the form
*   method: HTTP post type
*   buttonText – text for the confirm button
*
**********/

import React from 'react';
import { Form, ViewContext, Icon } from 'components/lib';

import './modal.scss';
import { getRawData,PractitionerReport } from '../../views/master/service'
import { Loader, Table, Card, Grid, Child } from '../../components/lib';
import { DNA_TYPE } from '../../../src/constants/Constants';
import { Filter } from '../../components/Filter/Filter'
import { CSVLink } from "react-csv";

const header = [
  { name: 'rsid', title: 'SNPs', sort: true },
  { name: 'index', title: 'Result', sort: true },
  { name: 'value', title: 'Value', sort: true }
]

const header_epi = [
  { name: 'key', title: 'Key', sort: true },
  { name: 'score', title: 'Score', sort: true },
  { name: 'current', title: 'Current Value', sort: true }
]
const arr1 = DNA_TYPE;

export class ViewRawDataModal extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      rawdata: {
        header: header,
        body: [],
      },
      option1: arr1,
      option2: [],
      option3: [],
      op1: '',
      op2: '',
      op3: '',
      kitId: '',
      loader: false,
      changed: true,
      csvData : []
    }
    this.csvLink = React.createRef()
  }
  async clearState() {

    await this.setState({
      rawdata: {
        header: header,
        body: [],
      },
      option1: arr1,
      option2: [],
      option3: [],
      op1: '',
      op2: null,
      op3: null,
      kitId: '',
      loader: false,
      changed: true,
      reportType: {},
      show: true,
      second_show: true,
      csvData : [],
      export_report : false,
    });
    console.log(this.state)

  }

  changeRawData = async () => {
    console.log(this.state)

    await this.setState({
      rawdata: {
        header: header,
        body: [],
      }
    })
    if (this.props.kitId && (this.state.op1 === "MY_INNERGO" || (this.state.op2 && this.state.op3) || (this.state.op1 === "PRACTITIONER_REPORT" && this.state.op2) || (this.state.op1 === "EPIGENETICS" || (this.state.op2 && this.state.op3) ))) {
      await this.setState({
        changed: false
      })
      await this.setState({ loader: true })
      await this.setState({ second_show:this.state.op1 !== "MY_INNERGO" && this.state.op1 !== "PRACTITIONER_REPORT" && this.state.op1 !== "EPIGENETICS"})
      try {
        let response = await getRawData({
          "kit_id": this.props.kitId,
          "report_type": this.state.reportType.value,
          "mode": this.state.op2,
          "sub_mode": this.state.op3
        })
        if (response) {
          if(this.state.op1 == "EPIGENETICS"){
            let result = []
            Object.entries(response.epi_summery.current).map((items, index) => {
              console.log("items",items, response.epi_summery.samples[items[0].replace("info", "score")])
              let sample_score = ""
              try {
                sample_score = response.epi_summery.samples[items[0].replace("info", "score")][0].score
              } catch (error) {
                console.log("error", error)
              }
              if(items[0] == "age_at_sample"){
                result.push({
                  "rsid": items[0],
                  "index": "",
                  "value": items[1]
                })
              }
              result.push({
                "rsid": items[1].title,
                "index": sample_score,
                "value": items[1].score
              })
            })
            result = result.filter((items) => (items.rsid))

            await this.setState({
              rawdata:{
                header: header_epi,
                body: result
              }, loader: false
            })
          }else{
            await this.setState({
              rawdata: {
                header: header,
                body: response.map(da => ({ rsid: da.rsid, index: da.index,value : da.value }))
              }, loader: false
            })
          }
          
        }
      } catch (err) {
        this.context.showNotification("No Raw Data available", 'error', true)
        await this.setState({
          loader: false
        })
      }
    } else {
      if (!this.props.kitId) {
        this.context.showNotification("Kit ID is blank", 'error', true)
      }
      this.setState({ loader: false })
    }
  }
  op1Change = async (dat) => {

    console.log(dat)
    await this.setState({ option2: dat.arr, op1: dat.value, op2: null, op3: null, option3: [], reportType: dat })
    await this.setState({ show: dat.value !== "MY_INNERGO" || dat.value !== "EPIGENETICS" })
    await this.setState({ second_show: dat.value !== "MY_INNERGO" && dat.value !== "PRACTITIONER_REPORT" && dat.value !== "EPIGENETICS" })
    await this.changeRawData();
  }
  op2Change = async (dat) => {
    await this.setState({ option3: dat.arr, op2: dat.value, op3: ''})
    await this.setState({ second_show: dat.value !== "PRACTITIONER_REPORT"})
    await this.changeRawData();
  }
  op3Change = async (dat) => {
    await this.setState({ op3: dat.value })
    await this.changeRawData();
  }
  close = async (context) => {
    context.hideRawDataModal(true)
    this.clearState()
  }

  exportCsv = async (kit_id) =>{
    console.log(kit_id,"kit_idd")
    if(kit_id){
      await PractitionerReport({
        "kit_id": this.props.kitId,
        "report_type": "PRACTITIONER_REPORT"
      }).then(async data => {
        console.log(data,'dataaa');
       
        if(!data.data){
          this.context.showNotification('DNA result does not exist for this kit' + kit_id, 'success', true);
        }
        else {
          await this.setState({ csvData:data.data,export_report:true }, () => {
            setTimeout(() => {
              this.csvLink.current.link.click()
            },);
          })
        }
      
    })
        console.log(this.csvLink,"this.state.csvLink.current.link")
    }

  }

  render() {

    return (
      <ViewContext.Consumer>
        {(context) => (
          // To close escape and outside modal
          // <div className={ "modal " + context.modal.visible }
          // onClick={(event) => this.close(event, context) }>
          <div className={"modal " + context.rawDataModal.visible} style={{overflow:'hidden'}}>

            <div className='modal-content card'>

              {this.props.title &&
                <header>
                   
                  <div style = {{maxWidth: '60%' , position:'fixed'}}><h1 style = {{marginTop : '10px'}}>{this.props.title} </h1></div>
                  <div onClick={event => this.close(context)} className="closeBtnModal"><Icon color="dark" image="x" /></div>
                  <button className="btn blue" onClick={event =>this.exportCsv(this.props.kitId)} style={{width: '100px',padding: '0','border-radius': '20px',position : 'relative', marginRight:'5px', float:"right"}} >Export</button>

                    {this.state.export_report && 
                    <CSVLink
                        data={this.state.csvData}
                        filename={this.props.kitId +'_report.csv'}
                        className="hidden"
                        ref={this.csvLink}
                        target="_blank" 
                    />
                  }
                 
                </header>
              }

              {this.props.text &&
                <p>{this.props.text}</p>
              }

              <div className="table-container">
                <div className="filter-dots" style={{ fontSize: "10px" }}>
                  <Grid cols={3}>
                    <Filter options={arr1} selected={this.op1Change} label={'Mode'} />
                    {this.state.show &&
                      <Filter options={this.state.option2} selected={this.op2Change} label={'Report'} value={this.state.op2} />
                    }
                    {this.state.second_show &&
                      <Filter options={this.state.option3} selected={this.op3Change} label={'Sub-Report'} value={this.state.op2} />
                    }
                  </Grid>

                </div>
                {this.state.changed &&
                  <Grid cols='1'><p>Please Enter In Details to View Results</p></Grid>
                }
                {!this.state.changed &&
                  <div >
                    <Grid cols='1'>
                      <div style={{overflowY:'auto',height:"400px"}}>
                        <Table
                          data={this.state.rawdata}
                          loading={this.state.loader}
                          badge={{ col: 'plan', color: 'blue' }}>
                        </Table>
                      </div>
                    </Grid>
                  </div>
                }
              </div>

            </div>
          </div>

        )}
      </ViewContext.Consumer>
    );
  }
}
ViewRawDataModal.contextType = ViewContext;
