import React from 'react';
import { DelayInput } from 'react-delay-input';
import  'datatableComponents/style.css';

export class SearchDatatable extends React.Component {
    constructor(props){

        super(props);
        this.state = {
            value: '',
        };
    }
  onFilter = async (e) => {

    this.setState({value: e.target.value});
    this.props.searchAll(this.state.value);

  };

  render() {
    const {value} = this.state;

    return (
        <div className="search_box">
          <label className="label">
            <DelayInput
              className="input"
              value={value}
              placeholder="Search"
              minLength={2}
              delayTimeout={600}
              onChange={this.onFilter} />
          </label>
        </div>
    );
  }
}