
import React from 'react';
import moment from 'moment';
import { updateUserAction} from "../../actions/userAction";
import {connect} from "react-redux";
class UserDetails extends React.Component {

    constructor(props) {

        super(props);
    }
    state = {
        loader:false,
        kit_ids: '',
        first_name: '',
        last_name: '',
        partner_name:'',
        email: '',
        kit_id: '',
        phone: '',
        kit_relations: [],
        epigenetic_data: [],
        account_type: [],
        country: '',
        address_lane1: '',
        address_lane2: '',
        zipcode: '',
        city: '',
        newsletter: false,
        terms_and_condition: false,
        research_purpose: false,
        subscribed: false,
        birthday: '',
        gender: '',
        weight: '',
        weight_unit: '',
        height: '',
        height_unit: 'cm',
        created_at: moment().format("YYYY-MM-DD hh:mm A"),
        last_login: moment().format("YYYY-MM-DD hh:mm A"),
        loading: false,
    }
    async componentDidMount() {
        if (this.props.user) {
        this.setState(this.props.user)
        }
    }
    
    render() {
        return (
          <div className="formGroup">
              <div className="ipBox">
                  <label>First Name</label>
                  <input type="text" placeholder="First Name" value={this.props.user && this.props.user.first_name} readOnly onChange={(e) => { this.setState({ first_name: e.target.value }) }} />
              </div>
              <div className="ipBox">
                <label>Email</label>
                  <input type="text" placeholder="Email" value={this.state.email} readOnly onChange={(e) => { this.setState({ email: e.target.value }) }} />
              </div>
              <div className="ipBox">
                  <label>Last Name</label>
                  <input type="text" placeholder="Last name" value={this.state.last_name} readOnly onChange={(e) => { this.setState({ last_name: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Kit ID</label>
                  <input type="text" placeholder="Account Kit ID"  value={this.state.kit_id} readOnly onChange={(e) => { this.setState({ kit_id: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Partner Name</label>
                  <input type="text" placeholder="Partner name"  value={this.state.partner} readOnly onChange={(e) => { this.setState({ partner_name: e.target.value }) }} />
              </div>
              <div className="ipBox">
                  <label>Country</label>
                  <input type="text" placeholder="Country name"  value={this.state.country[0]? this.state.country[0].name: " "} readOnly onChange={(e) => { this.setState({ country_name: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Account Type</label>
                  <input type="text" placeholder="Account Type" value={this.state.account_type} readOnly />
              </div>
              <div className="ipBox">
              <label>Mobile</label>
                  <input type="text" placeholder="Mobile Number" maxLength = {15}value={this.state.phone} readOnly onChange={(e) => { this.setState({ phone: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Child Kit IDs</label>
                  <input type="text" placeholder="Child Kit IDs" value={this.state.kit_relations ? this.state.kit_relations.length : 0} readOnly />
              </div>
              <div className="ipBox">
                    <label>Address Line 1</label>
                  <input type="text" placeholder="Address Line 1" value={this.state.address_lane1} readOnly onChange={(e) => { this.setState({ address_lane1: e.target.value }) }} />
              </div>
              <div className="ipBox">
                <label>Result Progressive</label>
                  <input type="text" placeholder="Result Progressive" readOnly value={this.props.user.account_type} />
              </div>
              <div className="ipBox">
              <label>Address Line 2</label>
                  <input type="text" placeholder="Address Line 2" value={this.state.address_lane2} readOnly onChange={(e) => { this.setState({ address_lane2: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>DOB</label>
                  <input type="date" placeholder="D.O.B" value={moment(this.state.birthday).format("YYYY-MM-DD")} readOnly onChange={(e) => { this.setState({ birthday: moment(e.target.value).utc().format() }) }} />
              </div>
              <div className="ipBox">
              <label>Post Code</label>
                  <input type="text" placeholder="Post Code" value={this.state.zipcode} readOnly onChange={(e) => { this.setState({ zipcode: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Gender</label>
                  <input type="text" placeholder="Gender" value={this.state.gender} readOnly onChange={(e) => { this.setState({ gender: e.target.value }) }} />
              </div>
              <div className="ipBox">
                  <label>Country Code</label>
                  <input type="text" placeholder="Country Code" readOnly  onChange={(e) => { this.setState({ email: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Height</label>
                  <input type="text" placeholder="Height" value={this.state.height} readOnly onChange={(e) => { this.setState({ height: e.target.value }) }} />
                  <ul class="radiolist" >
                      <li>
                          <label>
                              cm <input type="radio" value="cm" name="ht" disabled={true} onChange={(e) => {                     
                                  this.setState({
                                      height_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.height_unit === 'cm' || this.state.height_unit === 'CM'} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              ft
                              <input type="radio" name="ht" value="ft" disabled={true} onChange={(e) => {
                                  this.setState({
                                      height_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.height_unit === 'ft' || this.state.height_unit === 'FT'} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                  </ul>
              </div>
              <div className="ipBox">
              <label>Accepted T&Cs</label>
                  <input type="text" placeholder="Accepted T&Cs" readOnly />

                  <ul class="radiolist">
                      <li>
                          <label>
                              Yes <input type="radio" name="tc" value="true" disabled={true} onChange={(e) => {
                                  this.setState({
                                      terms_and_condition: true
                                  })
                              }} checked={this.state.terms_and_condition } />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              No<input type="radio" name="tc" value="false" disabled={true} onChange={(e) => {
                                  this.setState({
                                      terms_and_condition: false
                                  })
                              }} checked={!this.state.terms_and_condition } />
                              <span class="checkmark" ></span>
                          </label>
                      </li>
                  </ul>
              </div>
              <div className="ipBox">
              <label>Weight</label>
                  <input type="text" placeholder="Weight" value={this.state.weight} readOnly onChange={(e) => { this.setState({ weight: e.target.value }) }}/>
                  <ul class="radiolist">
                      <li>
                          <label>
                              kgs <input type="radio" name="wt" value="kg" disabled={true} onChange={(e) => {
                                  this.setState({
                                      weight_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.weight_unit === 'kg' || this.state.weight_unit === 'KG'} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              lbs
                                  <input type="radio" name="wt" value="lbs" disabled={true} onChange={(e) => {
                                  this.setState({
                                      weight_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.weight_unit === 'lbs' || this.state.weight_unit === 'LBS'} />
                              <span class="checkmark" ></span>
                          </label>
                      </li>
                  </ul>
              </div>
              <div className="ipBox">
              <label>Newsletter & Offers</label>
                  <input type="text" placeholder="Newsletter & Offers" readOnly />
                  <ul class="radiolist">
                      <li>
                          <label>
                              Yes <input type="radio" name="nl" value="true" disabled={true} checked={this.state.newsletter} onChange={(e) => {
                                  this.setState({
                                      newsletter:true
                                  })
                                  console.log(e.target.value)
                              }} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              No
              <input type="radio" name="nl" value="false" disabled={true} checked={!this.state.newsletter} onChange={(e) => {
                                  this.setState({
                                      newsletter: false
                                  })
                              }} />
                              <span class="checkmark" ></span>
                          </label>
                      </li>
                  </ul>
              </div>
              
              {this.props.confirmed === true &&
                <div className="ipBox">
                <label>Research Purposes</label>
                    <input type="text" placeholder="Research Purposes" readOnly />

                    <ul class="radiolist">
                        <li>
                            <label>
                                Yes <input type="radio" name="research" disabled={true} value="true" onChange={(e) => {
                                    this.setState({
                                    research_purpose: true
                                    })
                                }} checked={this.state.research_purpose } />
                                <span class="checkmark"></span>
                            </label>
                        </li>
                        <li>
                            <label>
                                No<input type="radio" name="research" disabled={true} value="false" onChange={(e) => {
                                    this.setState({
                                    research_purpose: false
                                    })
                                }} checked={!this.state.research_purpose} />
                                <span class="checkmark" ></span>
                            </label>
                        </li>
                    </ul>
                </div>
              }
              <div className="ipBox">
              <label>Created on</label>
                  <input type="text" placeholder="Created on" value={this.state.created_at} readOnly/>
              </div>
              <div className="ipBox">
              <label>Last Login</label>
                  <input type="text" placeholder="Last Log in" value={this.state.last_login} readOnly/>
              </div>
              <div className="ipBox">
              <label>Last Failed login</label>
                  <input type="text" placeholder="Last Failed log in" readOnly />
              </div>
          </div>

        )
    }
}
const mapStateToProps = state => ({
})
export default connect(mapStateToProps, { updateUserAction })(UserDetails);