/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not.
*
**********/

import React from 'react';
import { AuthContext, Row, Link } from 'components/lib';
import './home.scss';

export class HomeNav extends React.Component {

  render(){
    return(

      <AuthContext.Consumer>
      {(context) => (

        <Row color='brand' className='nav-header'>

          {/* <Logo/> */}

          <nav>

            {/* <Link url="/pricing" text='Pricing' /> */}

            { context.user.token ?
              <Link url="/dashboard" className="btn" text='Dashboard' /> :
              <>
              {/* <Link url="/signin" text='Sign In' /> */}
              </>
            }
          </nav>
        </Row>
      )}
      </AuthContext.Consumer>

    );
  }
}
