import React from 'react';

import { Radio } from "../radio/radio";
import { Checkbox } from "../checkbox/checkbox";
import { Error } from '../error/error';
import { Legend } from './legend';

import './fieldset.scss';

export class Fieldset extends React.Component {

  constructor(props){

    super(props);

    // initialize states
    let checked = [], defaultIndex;
    this.props.options.forEach(option => checked.push(false));

    // set default
    if (this.props.default){

      if (typeof this.props.options[0] === 'string'){
        defaultIndex =
        this.props.options.indexOf(this.props.default);
      }
      else if (typeof this.props.options[0] === 'object') {
        defaultIndex =
        this.props.options.findIndex(x => x.value === this.props.default);
      }

      checked[defaultIndex] = true;
      this.props.onChange(this.props.name, this.props.default, true);

    }

    this.state = {

      checked: checked,

    }

    this.defaultErrorMessage = 'Please select an option'
    this.change = this.change.bind(this);
    this.validate = this.validate.bind(this);

  }

  change(index, checked, option){

    // update state
    let state = Object.assign({}, this.state);

    // reset radios on select
    if (this.props.type === 'radio'){

      for (let i = 0; i < state.checked.length; i++)
        state.checked[i] = false

    }

    // toggle the checked state
    if (state.checked[index])
      state.checked[index] = false;
    else
      state.checked[index] = true;

    // update & validate
    this.setState(state);
    this.validate(option);

  }

  validate(option){

    let valid = undefined;
    let checkedCount = 0;

    for (let i = 0; i < this.state.checked.length; i++)
      if (this.state.checked[i] === true) checkedCount++;

    // validate radio
    if (this.props.type === "radio"){
      if (this.props.required){
        checkedCount ? valid = true : valid = false;
      }
      else {
        valid = true;
      }
    }

    // validate checkbox
    if (this.props.type === 'checkbox'){
      if (this.props.min && !this.props.max){

        // check for min value
        if (checkedCount >= this.props.min){
          valid = true;
        }
        else {
          valid = false;
          this.errorMessage = "Please select at least " + this.props.min + " option";
          if (this.props.min > 1) this.errorMessage += "s";
        }
      }

      else if (!this.props.min && this.props.max){

        // check for max value
        if (checkedCount <= this.props.max){
          valid = true;
        }
        else {
          valid = true;
          this.errorMessage = "Please select a maximum of " + this.props.max + " option"
          if (this.props.max > 1) this.errorMessage += "s";
        }
      }

      else if (this.props.min && this.props.max){

        // check for min and max value
        if (checkedCount >= this.props.min && checkedCount <= this.props.max){
          valid = true;
        }
        else {
          valid = false;
          this.errorMessage = "Please choose between " +
          this.props.min + " and " + this.props.max + " options ";
        }
      }
      else {
        if (this.props.required){
          checkedCount > 0 ? valid = true : valid = false;
        }
        else {
          valid = true;
        }
      }
    }

    // update the parent form state
    this.props.onChange(this.props.name, option, valid);

  }

  render(){

    let inputs = [];
    let cssClass = ''
    let showError = false;

    if (this.props.className) cssClass = this.props.className + ' ' + cssClass;
    if (this.props.valid === false) showError = true;

    this.props.options.map((option, index) => {

      if (this.props.type === 'radio'){
        inputs.push(
          <Radio
            key={ index }
            name={ this.props.name }
            index={ index }
            option={ option }
            checked={ this.state.checked[index] }
            callback={ this.change }
          />
        );
      }

      if (this.props.type === 'checkbox'){

        inputs.push(
          <Checkbox
            key={ index }
            name={ this.props.name }
            index={ index }
            option={ option }
            checked={ this.state.checked[index] }
            callback={ this.change }
          />
        );
      }

      return false;

    });

    return(

      <fieldset className={ showError ? cssClass + ' error' : cssClass }>

        <Legend text={ this.props.label } required={ this.props.required }/>

        { inputs }

        { showError && <Error message={ this.props.errorMessage ?
          this.props.errorMessage : this.defaultErrorMessage }/>
        }

      </fieldset>

    );
  }
}
