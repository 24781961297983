import React from 'react';
import { Label } from '../label/label';
import './checkbox.scss';

export class Checkbox extends React.Component {

  render(){
    return (

      <div className="checkbox">
        <input
          type="checkbox"
          name={ this.props.name }
          id={ this.props.option }
          value={ this.props.option }
          checked={ this.props.checked ? 'checked' : '' }
          onChange={ event =>
            this.props.callback(this.props.index, this.props.checked, this.props.option)
          }
        />

        <Label
          text={ this.props.option }
          required={ this.props.required }
          for={ this.props.option }
        />

      </div>

    );
  }
}
