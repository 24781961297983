import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './app/app';

ReactDOM.render(
  <App></App>,
  document.getElementById('root')
);
