import React from 'react';
import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import { getUsers } from '../../actions/adminAction';
import { connect } from "react-redux";
import { Filter } from '../../components/Filter/Filter';
import UpdateFormUnconfirmed from '../../components/form/UpdateFormUnconfirmed';
import {
    Card, Chart, Grid, Stat,
    Search,
    ViewContext, Button,
    Icon,
    Tabs,
    TabView,LoadingButton
} from '../../components/lib';
import { ACCOUNT_TYPE as account_type, STATUS as status, TIME as time } from '../../constants/Constants';
import moment from 'moment';
import UpdateForm from '../../components/form/UpdateForm';
import {axiosCall} from '../../helpers';
import {LAMDA_APICALL, EXPORT_USERS} from '../../apiurl';

const sevendays = time.filter((item) => item.value == '168')[0];//Last 7 days as default

class unconfirmed extends React.Component {

    constructor(props) {

        super(props);
        this.access = [];
        this.component = "ACCOUNT_MGMT"
        this.subComponent = "Unconfirmed"
        this.state = {
            search:"",
            close:false,
            current_user:{},
            data: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            searchVal: '',
            columnSort: '',
            sortType: '',
            users: [],
            account: '',
            status: '',
            time: '',
            per_page: 10,
            page: 1,
            active: {
                start: moment().subtract(parseInt(sevendays.value), 'hours').format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD")
            },
            new: {
                start: moment().subtract(parseInt(sevendays.value), 'hours').format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD")
            },
            loadmoreBtn: false,
            loadmoreValue: '',
            btnStatus: false,
            btnColorLoad: '',
            btnTextLoad: 'Export Account Data',
            buttonStyle: { display: 'block' },
        };
    }
    columns = memoize(close => [
        {
            name: 'First Name',
            selector: 'first_name',
            sortable: true,
            hide: 'md',
        },
        {
            name: 'Last Name',
            selector: 'last_name',
            sortable: true,
            hide: 'md',
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            hide: !this.state.close ? 'sm':'lg',
        },
        // {
        //     name: 'Last Login',
        //     selector: 'last_login',
        //     sortable: true,
        //     hide: 'md',
        //     cell: (row)=>moment(row.last_login).format('YYYY/MM/DD hh:mm A')
        // },
        {
            name: 'Phone',
            selector: 'phone',
            hide: 'md',
            sortable: false,
        },
        {
            name: 'Action',
            cell: (row) => <div><button className="tblAction fas fa-edit" onClick={close} id={row._id} name="edit"></button></div>,
            ignoreRowClick: false,
            allowOverflow: true,
            button: true,
        },
    ]);
    componentDidMount = async () => {
        let user = JSON.parse(localStorage.getItem('user'))
        this.access = user.permissions.find(roles => roles.key == this.component).sub[1].inner;
        console.log(this.access,'this.access')
        await this.fetchUsers();
    }
    handlePerRowsChange = async (perPage, page) => {
        await this.setState({ per_page: perPage, page: page });
        await this.fetchUsers();
    }
    handlePageChange = async (page) => {
        await this.setState({ page: page });
        await this.fetchUsers();
    }
    search = async(val) =>{
        await this.setState({search:val});
        await this.fetchUsers();
    }
    fetchUsers = async () => {
        let data = {};
        data.search = this.state.search;
        data.per_page = this.state.per_page;
        data.page = this.state.page;
        data.confirmed= false;
        let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd'))
        if (partnerId) {
            data.partner_id = partnerId;
        }
        await this.setState({ loading: true });
        await this.props.getUsers(data);
        await this.setState({ loading: false });
    }
    componentDidUpdate(prevProps) {
        if (this.props.error != prevProps.error) {
            this.context.showNotification(this.props.error, 'error', true)
        }
    }
    export = async () => {
        this.setState({loadmoreBtn: true});
        let {email, partnerId, name} = JSON.parse(localStorage.getItem('user'))

        if (parseInt(localStorage.getItem('partner_idd'))) {
            partnerId = parseInt(localStorage.getItem('partner_idd'));
        }

        await axiosCall('post', LAMDA_APICALL, {
            url: EXPORT_USERS,
            method: "POST",
            data: {
                email, name, "partner_id": partnerId,confirmed : false
            }
        }, (response) => {
            if (response.status) {
                this.context.showNotification(response.message, 'success', true)
            } else {
                this.context.showNotification(this.props.error, 'error', true)
            }
        });
        this.setState({loadmoreBtn: false});
    }
    close = (e) =>{
        let user_id = e.target.id;
        let current_user =  user_id ? this.props.user.data.filter(data=>data._id == e.target.id)[0] : {};
        this.setState({
            close:!this.state.close,
            current_user:current_user
        })
    }
    render() {
        const { loading, data, totalRows } = this.state;

        createTheme('solarized', {
            text: {
                primary: '#717171',
                secondary: '#268bd2',
            },
            background: {
                default: '#ffffff',
            },
            divider: {
                default: '#1595c5',
            },
            button: {
                default: 'rgb(38, 139, 210)',
                hover: 'rgba(0,0,0,.08)',
                focus: 'rgba(255,255,255,.12)',
                disabled: 'rgba(0,0,0,.12)',
            },
        });
        return (
            <section className="cnfAccount-wrp">
                <div className="tableHeadMain">
                    <div className="tsecOne">
                        <SearchDatatable searchAll={this.search} />
                    </div>
                    <div className="tsecTwo">
                    <LoadingButton
                            disabled={this.state.btnStatus}
                            color={this.state.btnColorLoad}
                            className='sUserAdd1 loading-blue'
                            text={this.state.btnTextLoad}
                            action={this.export}
                            loading={this.state.loadmoreBtn}
                        />
                    </div>
                </div>

                {/* Add class open to "cnfAccountList" to show Account Profile */}

                <div className={`cnfAccountList ${this.state.close ? 'open' : ''}`}>
                    <div className="accountTable card">
                    
                        <DataTable
                            columns={this.columns(this.close)}
                            data={this.props.user.data}
                            progressPending={this.state.loading}
                            pagination
                            paginationServer
                            paginationTotalRows={this.props.user.total}
                            onChangeRowsPerPage={this.handlePerRowsChange}
                            onChangePage={this.handlePageChange}
                            progressComponent={<DataLoader />}
                            className="superDtableWrap"
                            theme="solarized"
                        />
                    </div>

                    <div className="accountProfile card card2">
                        <UpdateFormUnconfirmed close={this.close} user ={this.state.current_user} access ={this.access}/>
                    </div>
                </div>
            </section>
        );
    }
}
unconfirmed.contextType = ViewContext;
const mapStateToProps = state => ({
    user: state.users.user,
    error: state.error.error,
    partner: state.admin.partner,
})
export default connect(mapStateToProps, { getUsers })(unconfirmed);
