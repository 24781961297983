import React from 'react';
import { Label } from '../label/label';
import './switch.scss';

export class Switch extends React.Component {

  constructor(props){

    super(props);

    let label;

    // set the initial label
    if (this.props.handleLabel){
      if (this.props.handleLabel.length){
        label = this.props.handleLabel[0];
      }
      else {
        label = 'No';
      }
    }

    this.state = {

      on: false,
      activeLabel: label

    }

    this.change = this.change.bind(this);

  }

  change(){

    let on, label;

    this.state.on ? on = false : on = true;

    if (this.props.handleLabel){
      if (this.props.handleLabel.length){

        if (this.props.handleLabel[0] === this.state.activeLabel)
          label = this.props.handleLabel[1];
        else
          label = this.props.handleLabel[0];

      }
      else {

        if (this.state.activeLabel === 'No')label = 'Yes';
        else label = 'No'

      }
    }

    this.props.onChange(this.props.name, on, true);
    this.setState({ on: on, activeLabel: label });

  }

  render(){

    let cssClass = 'switch';
    if (this.props.handleLabel) cssClass += ' with-labels';
    if (this.state.on) cssClass += ' on';

    return(
      <div class={ cssClass }>

        <Label
          text={ this.props.label }
          required={ this.props.required }
        />

        <div class='track' onClick={ this.change } >
          <div class='handle'>{ this.props.handleLabel && this.state.activeLabel }</div>
        </div>
      </div>
    );
  }
}
