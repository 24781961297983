import React from 'react';
import { getRawdata } from './services'
import { Loader, Table, Card ,Grid} from '../lib';
import { DNA_TYPE } from '../../../src/constants/Constants';
import { Filter } from '../../components/Filter/Filter'
const header = [
  { name: 'rsid', title: 'SNPs', sort: true },
  { name: 'index', title: 'Result', sort: true },
  { name: 'value', title: 'Value', sort: true }
]
const arr1 = DNA_TYPE;
export default class Rawdata extends React.Component {

  constructor(props) {
    super(props);
  }
  state = {
    rawdata: {
      header: header,
      body: [],
      option1: arr1,
      option2: [],
      option3: [],
      op1: '',
      op2: '',
      op3: '',
      kit_id:''
    },
    loader: false
  }
  async componentDidUpdate(prevProps) {

    if (prevProps.user != this.props.user) {
      await this.setState({kit_id:this.props.user.kit_id})
      await this.changeRawData();
  }
}
  changeRawData = async()=>{
    if(this.state.kit_id&&this.state.op2&&this.state.op3){
      await this.setState({loader:true})
      let response = await getRawdata({
        "kit_id": this.state.kit_id,
        "mode": this.state.op2,
        "sub_mode": this.state.op3
      })
      if(response){
       await this.setState({
         rawdata: {
           header: header,
           body: response.map(da => ({ rsid: da.rsid, index: da.index, value: da.value }))
         }, loader: false
       })
      }
    }else{
      this.setState({loader:false})
    }
  }
  op1Change = async (dat) => {
    await this.setState({ option2: dat.arr,op1:dat.value })
    await this.changeRawData();
  }
  op2Change = async(dat) => {
    await this.setState({ option3: dat.arr,op2:dat.value })
    await this.changeRawData();
  }
  op3Change = async(dat) => {
    await this.setState({op3:dat.value})
    await this.changeRawData();
  }
  render() {
    return (
      <div>
        <div className="filter-dots" style={{fontSize:"10px"}}>
          <Grid cols={3}>
            <Filter options={arr1} selected={this.op1Change} />
            <Filter options={this.state.option2} selected={this.op2Change} />
            <Filter options={this.state.option3} selected={this.op3Change} />
          </Grid>

        </div>
        <Table
          data={this.state.rawdata}
          loading={this.state.loader}
          badge={{ col: 'plan', color: 'blue' }}>
        </Table>
      </div>
    )
  }
}
