import React from 'react';

import { Label } from '../label/label';
import { Error } from '../error/error';

import './input.scss';

export class EmailInput extends React.Component {

  constructor(props) {

    super(props);
    this.defaultErrorMessage = 'Please enter an email address';
    this.validate = this.validate.bind(this);
    this.inputRef = React.createRef();
  }

  validate(event) {

    let value, valid = undefined;
    event ? value = event.target.value : value = '';

    // input is required and value is blank
    if (this.props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!this.props.required && value === '')
      valid = true;

    // now test for a valid email
    const rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (rex.test(value)) {

      valid = true;

    }
    else {

      valid = false;
      this.defaultErrorMessage = "Please enter a valid email address";

    }
    console.log("onblur")
    console.log(this.props.name, value, valid)
    // update the parent form
    this.props.onChange(this.props.name, value, valid);

  }
  keypressHandler = event => {
    if (event.key === "Enter") {
      this.setState({ value: this.inputRef.current.value });
      this.inputRef.current.blur();
    }
  };

  render() {

    let cssClass = 'textbox';
    let showError = false;

    if (this.props.valid === false) showError = true;
    if (this.props.valid === true) cssClass += ' success'
    if (this.props.className) cssClass += ' ' + this.props.className;
    let ac = "on"
    if (this.props.autoComplete !== undefined) {
      ac = this.props.autoComplete ? 'on' : 'off'
    }
    return (

      <>
        <Label
          text={this.props.label}
          required={this.props.required}
          for={this.props.name}
        />

        <input
          ref={this.inputRef}
          autoComplete={ac}
          type={this.props.type}
          id={this.props.name}
          name={this.props.name}
          className={showError ? cssClass + ' error' : cssClass}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={event => this.props.onChange(this.props.name, event.target.value, undefined)}
          onBlur={event => this.validate(event)}
          onKeyPress={event => this.keypressHandler(event)}
        />

        {showError && <Error message={this.props.errorMessage ?
          this.props.errorMessage : this.defaultErrorMessage} />
        }

      </>

    );
  }
}
