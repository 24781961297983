/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value.
*
*   PROPS
*   label: string
*   loading: true/false to toggle loading animation (optional)
*   value:  numeric value
*   icon: icon image to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import { Loader, Card, ViewContext } from 'components/lib';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, GET_ACCOUNT_COUNT } from '../../apiurl';
import './selectcard.scss';
import "components/new-global.scss";
import Moment from 'moment'

export class Selectcard extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      loading: false,
      countValue: 0,
      partner: this.props.partner,
      types: this.props.types,
      defaultVal: this.props.defaultVal,
      defaultDays: this.getDate(7)
    }
  }

  async componentDidMount() {

    try {
      console.log("props", this.props);
      this.setState({ loading: true });
      //const result = this.apiCall();
      this.setState({
        loading: false,
      });
    }
    catch (err) {
      this.context.handleError(err);
    }
  }
  // async componentDidUpdate() {

  // }

  async UNSAFE_componentWillReceiveProps({ partner, defaultVal }) {

    await this.setState({ ...this.state, partner, defaultVal })
    await this.setState({defaultDays: this.getDate(7)});
    await this.apiCall();
  }

  async apiCall() {
    console.log('this.state.partner//',this.state.partner);
    await axiosCall('post', LAMDA_APICALL, {
      url: GET_ACCOUNT_COUNT,
      method: "POST",
      data: {
        category: this.props.category,
        partner_id:parseInt(this.state.partner),
        params: {
          start_date: this.state.defaultDays.lastDay,
          end_date: this.state.defaultDays.today,
          type: this.state.defaultVal
        }
      }
    }, (response) => {
      if (response.status) {
        this.setState({
          countValue: response.data.count,
        });
      } else {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
      }
    });
  }

  handleChange = async (e) => {
    let finalValue = e.target.value;
    await this.setState({ defaultVal: e.target.value });

    let resultDate
    if (this.state.defaultVal === '7d') {
      resultDate = {today: Moment().add(1,"days").format('YYYY-MM-DD'),lastDay :Moment().subtract(7, 'days').format('YYYY-MM-DD')}// await this.getDate(7);
    } else if (this.state.defaultVal === '24h') {
      resultDate = {today: Moment().add(1,"days").format('YYYY-MM-DD'),lastDay :Moment().subtract(1, 'days').format('YYYY-MM-DD')}// await this.getDate(1);
    } else if (this.state.defaultVal === '30d') {
      resultDate = {today: Moment().add(1,"days").format('YYYY-MM-DD'),lastDay :Moment().subtract(30, 'days').format('YYYY-MM-DD')} //await this.getDate(30);
    } else if (this.state.defaultVal === '180d') {
      resultDate = {today: Moment().add(1,"days").format('YYYY-MM-DD'),lastDay :Moment().subtract(180, 'days').format('YYYY-MM-DD')} //await this.getDate(180);
    }
    else if (this.state.defaultVal === '90d') {
      resultDate = {today: Moment().add(1,"days").format('YYYY-MM-DD'),lastDay :Moment().subtract(90, 'days').format('YYYY-MM-DD')} //await this.getDate(180);
    } else {
      await this.setState({ defaultVal: finalValue });
      resultDate = {today: Moment().add(1,"days").format('YYYY-MM-DD'),lastDay :Moment().subtract(7, 'days').format('YYYY-MM-DD')} //await this.getDate(7);
    }
    console.log(resultDate)
    await this.setState({ defaultDays: resultDate });

    await this.apiCall();
  };
  getDate(days) {
    let date = new Date();
    let today = date.toISOString();
    date.setDate(date.getDate() - days);
    let lastDay = date.toISOString();
    console.log({ today: today, lastDay: lastDay });
    return { today: today, lastDay: lastDay }
  }

  render() {
    if (this.props.loading) {
      return (
        <div className='selectcd card'>
          <Loader />
        </div>
      );
    }

    return (
      <Card>
        <h1>{this.props.title}</h1>
        <div className="selectBox">
          <h1>{this.state.countValue}</h1>
          <select className="select__type__I" onChange={this.handleChange} value={this.state.defaultVal}>
            {this.props.Options.map(function (item, i) {
              return <option key={i} value={item.value}>{item.label}</option>
            })}
          </select>
        </div>
      </Card>


    );
  }
}

Selectcard.contextType = ViewContext;