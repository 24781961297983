/***
*
*   IMAGE
*   Import the image before passing it to this component.
*
*   PROPS
*   src: imported source
*   alt: alt description
*   title: title description
*   className: apply a custom css class
*
**********/

import React from 'react';
import './image.scss';

export class Image extends React.Component {

  render(){
    return(

      <img
        src={ this.props.source }
        alt={ this.props.alt }
        title={ this.props.title }
        className={ this.props.className ? this.props.className : undefined }
      />

    );
  }
}
