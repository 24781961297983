/***
*
*   HOME LAYOUT
*   Main website layout
*
**********/

import React from 'react';
import { HomeNav, HomeFooter } from 'components/lib';
import '../layout.scss';

export class HomeLayout extends React.Component {

  render(){

    document.body.classList.remove('color-bg');

    return (

      <>
      <main className='home'>

        <HomeNav />
        <this.props.children/>

      </main>

      <HomeFooter />
      </>

    );
  }
}
