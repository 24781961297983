/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value.
*
*   PROPS
*   label: string
*   loading: true/false to toggle loading animation (optional)
*   value:  numeric value
*   icon: icon image to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import {
  Loader, Selectcard, ViewContext,
  Grid, AccountBox, DateFilterBox, PartnerBox
} from 'components/lib';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, GET_ACCOUNT_COUNT } from '../../apiurl';
import './dashboard-box.scss';
import "components/new-global.scss";
import Moment from 'moment'

export class DashboardBox extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      countValue: 0,
      partner: this.getPartnerFromLocal(),
      types: this.props.types,
      defaultVal: this.props.defaultVal,
      defaultDays: this.getDate(7)
    }
  } 

  async componentDidMount() {
    console.log('componentDidMount dashboardBox')
    try {
      console.log("props", this.props);
      this.setState({ loading: true });
      //const result = this.apiCall();
      this.setState({
        loading: false,
        partner : this.getPartnerFromLocal()
      });
    }
    catch (err) {
      this.context.handleError(err);
    }
  }

  getDate(days) {
    let date = new Date();
    let today = date.toISOString();
    date.setDate(date.getDate() - days);
    let lastDay = date.toISOString();
    console.log({ today: today, lastDay: lastDay });
    return { today: today, lastDay: lastDay }
  }

  getPartnerFromLocal = () => {
    var id = localStorage.partner_idd && parseInt(localStorage.partner_idd)
      ? parseInt(localStorage.partner_idd) : (localStorage.partner_id && parseInt(localStorage.partner_id)
        ? parseInt(localStorage.partner_id) : 0)

    // console.log(id, 'getPartnerFromLocal')
    return id
  }

  partnerChanged = (e) => {
    console.log("partner", e.target.value)
    this.setState({ partner: e.target.value })
    this.refs.child1.partnerChanged(e.target.value);
    this.refs.child2.partnerChanged(e.target.value);
    this.refs.child3.partnerChanged(e.target.value);
    this.refs.child4.partnerChanged(e.target.value);
  }

  render() {
    var gridCount = this.getPartnerFromLocal() > 0 ? 4 : 5
    return (
   
      <div className="dashboardFilter">
        <Grid cols={gridCount}>
          {this.getPartnerFromLocal() == 0 && <PartnerBox partnerChanged={this.partnerChanged} />}

          <AccountBox ref="child1" partner={this.state.partner} />
          <DateFilterBox ref="child2" category="ACTIVE" title="Active" partner={this.state.partner} />
          <DateFilterBox ref="child3" category="NEW_USERS" title="New Users" partner={this.state.partner} />
          <DateFilterBox ref="child4" category="REMOVED" title="Removed" partner={this.state.partner} />
        </Grid>
      </div>
    );
  }
}

DashboardBox.contextType = ViewContext;