/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value.
*
*   PROPS
*   label: string
*   loading: true/false to toggle loading animation (optional)
*   value:  numeric value
*   icon: icon image to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import { Loader, Card, ViewContext } from 'components/lib';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, GET_ACCOUNT_COUNT } from '../../apiurl';
import './dashboard-box.scss';
import "components/new-global.scss";
import Moment from 'moment'

export class DateFilterBox extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            count: 0,
            partner: this.props.partner,
            tVal: '7d',
            defaultDays: this.getDate(7),
            options: [
                { value: '24h', label: 'Last 24hrs' },
                { value: '7d', label: 'Last 7 days' },
                { value: '30d', label: 'Last 30 days' },
                { value: '90d', label: 'Last 90 days' },
                { value: '180d', label: 'Last 180 days' },
            ],
        }
        this.category = this.props.category
        this.title = this.props.title
        this.apiCall = this.apiCall.bind(this);
    }
    getDate(days) {
        let date = new Date();
        let today = date.toISOString();
        date.setDate(date.getDate() - days);
        let lastDay = date.toISOString();
        return { today: today, lastDay: lastDay }
    }
    async componentDidMount() {
        console.log("componentDidMount props", this.props);
        try {
            this.setState({
                loading: false,
                partner: this.props.partner
            });
            console.log(this.state)
            this.apiCall(this.state.tVal)
        }
        catch (err) {
            this.context.handleError(err);
        }
    }
    partnerChanged = async (partner) => {
        console.log(partner)
        await this.setState({ partner })
        console.log(this.state)
        this.apiCall(this.state.tVal)
    }
    async apiCall() {
        this.setState({ loading: true });
        await axiosCall('post', LAMDA_APICALL, {
            url: GET_ACCOUNT_COUNT,
            method: "POST",
            data: {
                category: this.category,
                partner_id: parseInt(this.state.partner),
                params: {
                    start_date: this.state.defaultDays.lastDay,
                    end_date: this.state.defaultDays.today,
                }
            }
        }, (response) => {
            this.setState({ loading: false });
            if (response.status) {
                this.setState({
                    count: response.data.count,
                });
            } else {
                this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
            }
        });
    }
    handleChange = async (e) => {
        let finalValue = e.target.value;
        await this.setState({ tVal: e.target.value, count: '' });

        let resultDate
        if (this.state.tVal === '7d') {
            resultDate = { today: Moment().add(1, "days").format('YYYY-MM-DD'), lastDay: Moment().subtract(7, 'days').format('YYYY-MM-DD') }// await this.getDate(7);
        } else if (this.state.tVal === '24h') {
            resultDate = { today: Moment().add(1, "days").format('YYYY-MM-DD'), lastDay: Moment().subtract(1, 'days').format('YYYY-MM-DD') }// await this.getDate(1);
        } else if (this.state.tVal === '30d') {
            resultDate = { today: Moment().add(1, "days").format('YYYY-MM-DD'), lastDay: Moment().subtract(30, 'days').format('YYYY-MM-DD') } //await this.getDate(30);
        } else if (this.state.tVal === '180d') {
            resultDate = { today: Moment().add(1, "days").format('YYYY-MM-DD'), lastDay: Moment().subtract(180, 'days').format('YYYY-MM-DD') } //await this.getDate(180);
        }
        else if (this.state.tVal === '90d') {
            resultDate = { today: Moment().add(1, "days").format('YYYY-MM-DD'), lastDay: Moment().subtract(90, 'days').format('YYYY-MM-DD') } //await this.getDate(180);
        } else {
            await this.setState({ tVal: finalValue });
            resultDate = { today: Moment().add(1, "days").format('YYYY-MM-DD'), lastDay: Moment().subtract(7, 'days').format('YYYY-MM-DD') } //await this.getDate(7);
        }
        console.log(resultDate)
        await this.setState({ defaultDays: resultDate });
        this.apiCall()
    };


    render() {
        if (this.state.loading) {
            return (
                <div className='selectcd card'>
                    <Loader />
                </div>
            );
        }

        return (
            <Card>
                <h1>{this.title}</h1>
                <div className="selectBox">
                    <h1>{this.state.count}</h1>
                    <select className="select__type__I" onChange={this.handleChange}
                        value={this.state.tVal || ''}>
                        {this.state.options.map(function (item, i) {
                            return <option key={i} value={item.value}>{item.label}</option>
                        })}
                    </select>
                </div>
            </Card>

        );
    }
}

DateFilterBox.contextType = ViewContext;