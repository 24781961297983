/***
*
*   LOADER
*   Infinite spinning animation for loading states.
*
**********/

import React from 'react';

import './loader.scss';
import ClassNames from 'classnames';
import Center from './images/center.svg';
import Orbit from './images/orbit.svg';

export class Loader extends React.Component {

  render(){

    const cssClass = ClassNames({

      'loader': true,
      [this.props.className]: this.props.className

    });

    return (

      <div className={ cssClass }>
        <img src={ Center } className='center' alt='Orbit Center'/>
        <img src={ Orbit } className='orbit' alt='Orbit Spinner'/>
      </div>

    );
  }
}
