/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';

import { Link } from 'components/lib'
import './home.scss';

export class HomeFooter extends React.Component {

  render(){
    return (

      <footer className='panel-row'>
        <div className='content'>

          <nav>
            <Link url="/" text='Home'/>
            <Link url="/pricing" text='Pricing'/>
            <Link url="/signin" text='Signin'/>
            <Link url="/signup" text='Signup'/>
            <Link url="/terms" text='Terms'/>
            <Link url="/privacy" text='Privacy'/>
            <a href="mailto:support@yourdomain.com">Contact Support</a>
          </nav>

          <span className="copyright">Copyright &copy; Gravity 2019</span>
          <address>Gravity Inc. 42 The Milky Way. The Universe</address>

        </div>
      </footer>

    );
  }
}
