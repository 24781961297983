import React from 'react';
import Axios from 'axios';

// components
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AuthProvider, { PrivateRoute } from '../app/auth';
import { History, View } from 'components/lib';
import store from '../store';
import { Provider, connect } from "react-redux";
// import i18n from '../i18n';
import { withNamespaces } from 'react-i18next';
// website views
// import { Home } from 'views/website/home/home';
// import { Pricing } from 'views/website/pricing/pricing';
// import { Terms } from 'views/website/legal/terms';
// import { Privacy } from 'views/website/legal/privacy';

// auth views
// import { Signup } from 'views/auth/signup';
// import { SignupUser } from 'views/auth/signup-user';
import { Signin } from 'views/auth/signin';
import { SigninIdentifier } from 'views/auth/identifier';
import { SigninPwd } from 'views/auth/pwd';
import { ForgotPassword } from 'views/auth/forgot-password';
import { ResetPassword } from 'views/auth/reset-password';

import { Verify } from 'views/account/verify';

// app views
import { MasterDashboard } from 'views/dashboard/dashboard';
import { Profile } from 'views/account/profile';
import { Password } from 'views/account/password';

// import { Billing } from 'views/account/billing';
import { Users } from 'views/account/users';
import { NotFound } from 'views/external/404';

// superAdmin views
import Dashboard from 'views/master/dashboard';
import { MasterAccounts } from 'views/master/accounts';
import { MasterUsers } from 'views/master/users';
import { masterUserManagement } from 'views/master/masterUserManagement';
import kitIdManagement from 'views/master/kitIdManagement';
import bulkUpload from 'views/master/bulkUpload';
import dobExport from 'views/master/dobExport';

//Admin dashboard views
import adminDashboard from 'views/admin/adminDashboard';
import adminUserManagement from 'views/admin/adminUserManagement';
import confirmedUser from 'views/admin/confirmedUser';
import unconfirmed from 'views/admin/unconfirmed';
import adminKitManagement from 'views/admin/adminKitManagement';
import AppDashboardManagement from 'views/admin/appDashboardManagement';
import AppHomeManagement from 'views/admin/appHomeManagement';
import { content } from 'views/admin/content';
import ViewRawData from 'views/master/viewRawData'
import demoUser from 'views/master/demoUser';

class App extends React.Component {

    constructor(props) {

        super(props);
        // add auth token to api header calls
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.token) {

                Axios.defaults.headers.common['Authorization'] = "Bearer " + user.token;

            }
        }

    }


    render() {
        const { t } = this.props;
        return (
            <Provider store={store}>
                <AuthProvider>
                    {/* <div style={{textAlign: "right",paddingTop: "10px",marginBottom: "15px"}}>
          <button style={{marginRight: "20px"}} className="btn" onClick={() => changeLanguage('en')}>en</button>
          <button style={{marginRight: "20px"}} className="btn" onClick={() => changeLanguage('de')}>de</button>
        </div> */}
                    <Router history={History}>
                        <Switch>
                            { /* auth routes */}

                            {/* <Route exact path='/signup'
              render={() => <View display={ Signup } layout='auth'
              title='Gravity Sign up' />}/>

            <Route exact path='/signup/user'
              render={() => <View display={ SignupUser } layout='auth'
              title='Gravity Sign up' />}/> */}
                            <Route exact path="/signin">
                                {<Redirect to="/signin/identifier" />}
                            </Route>
                            <Route exact path='/signin/identifier'
                                render={() => <View display={SigninIdentifier} layout='auth'
                                    title='Muhdo' />} />
                            <Route exact path='/signin/pwd/:email'
                                render={() => <View display={SigninPwd} layout='auth'
                                    title='Muhdo' />} />

                            <Route exact path='/forgotpassword'
                                render={() => <View display={ForgotPassword} layout='auth'
                                    title='Forgot Your Password?' />} />

                            <Route path='/user/resetpassword'
                                render={() => <View display={ResetPassword} layout='external'
                                    title='Reset Your Password' />} />

                            <Route path='/verify/:token'
                                render={() => <View display={Verify} layout='auth'
                                    title='Verify Your Account' />} />

                            <Route path='/reset/:token'
                                render={() => <View display={Verify} layout='auth'
                                    title='Password Reset' />} />

                            { /* master routes */}

                            <PrivateRoute exact path='/'
                                render={() => <View display={Dashboard} layout='master'
                                    title="t.('title-header')" language={t} />} />

                            <PrivateRoute exact path='/dashboard' storeVal={store} permission='master'
                                render={() => <View display={Dashboard} layout='master'
                                    title={t('title-header')} language={t} />} />

                            <PrivateRoute exact path='/user-management' storeVal={store} permission='master'
                                render={() => <View display={masterUserManagement} layout='master'
                                    title='User Management' language={t} />} />

                            <PrivateRoute exact path='/kit-ids' storeVal={store} permission='master'
                                render={() => <View display={kitIdManagement} layout='master'
                                    title='Manage Kit IDs' language={t} />} />
                            
                            <PrivateRoute exact path='/bulk-upload' storeVal={store} permission='master'
                                render={() => <View display={bulkUpload} layout='master'
                                    title='Bulk Upload Status' language={t} />} />

                            <PrivateRoute exact path='/dob-export' storeVal={store} permission='master'
                                render={() => <View display={dobExport} layout='master'
                                    title='Bulk Upload Status' language={t} />} />

                            <PrivateRoute exact path='/admin/demo-user' storeVal={store} permission='admin'
                                render={() => <View display={demoUser} layout='app'
                                    title='Demo Users' language={t} />} />

                            <PrivateRoute exact path='/raw-data/:kitId' storeVal={store} permission='master'
                                render={() => <View display={ViewRawData} layout='master'
                                    title='View Raw Data' language={t} />} />

                            <PrivateRoute exact path='/account' storeVal={store} permission='admin'
                                render={() => <View display={Profile} layout='master'
                                    title='Your Profile' language={t} />} />

                            <PrivateRoute exact path='/account/password' storeVal={store} permission='admin'
                                render={() => <View display={Password} layout='master'
                                    title='Your Password' language={t} />} />

                            <PrivateRoute exact path='/adminDashboard/*' storeVal={store} permission='admin'
                                render={() => <View display={adminDashboard} layout='app'
                                    title='Admin Console' language={t} />} />

                            <PrivateRoute path='/admin/adminUserManagement' storeVal={store} permission='admin'
                                render={() => <View display={adminUserManagement} layout='app'
                                    title='User Management' language={t} />} />

                            <PrivateRoute path='/admin/confirmedUsers' storeVal={store} permission='admin'
                                render={() => <View display={confirmedUser} layout='app'
                                    title='Confirmed User' language={t} />} />

                            <PrivateRoute path='/admin/unConfirmedusers' storeVal={store} permission='admin'
                                render={() => <View display={unconfirmed} layout='app'
                                    title='Unconfirmed User' language={t} />} />

                            <PrivateRoute path='/admin/kit-ids' storeVal={store} permission='admin'
                                render={() => <View display={adminKitManagement} layout='app'
                                    title='Kit ID Service' language={t} />} />

                            <PrivateRoute path='/admin/app/dashboard' storeVal={store} permission='master'
                                render={() => <View display={AppDashboardManagement} layout='app'
                                    title='App Dashboard Management' language={t} />} />

                            <PrivateRoute path='/admin/app/home' storeVal={store} permission='master'
                                render={() => <View display={AppHomeManagement} layout='app'
                                    title='App Home Page Management' language={t} />} />

                            <PrivateRoute exact path='/admin/content' storeVal={store} permission='admin'
                                render={() => <View display={content} layout='app'
                                    title='' language={t} />} />


                            <PrivateRoute exact path='/admin/account' storeVal={store} permission='admin'
                                render={() => <View display={Profile} layout='app'
                                    title='Your Profile' language={t} />} />

                            <PrivateRoute exact path='/admin/account/password' storeVal={store} permission='admin'
                                render={() => <View display={Password} layout='app'
                                    title='Your Password' language={t} />} />






                            {/* <PrivateRoute exact path='/master/accounts' permission='master'
                                render={() => <View display={MasterAccounts} layout='master'
                                    title={t('title-Accounts')} language={t} />} />

                            <PrivateRoute exact path='/master' permission='master'
                                render={() => <View display={MasterDashboard} layout='master'
                                    title={t('title-header')} language={t} />} />

                            <PrivateRoute exact path='/master/users' permission='master'
                                render={() => <View display={MasterUsers} layout='master'
                                    title='Users' language={t} />} />
                            
                            <PrivateRoute exact path='/account/users' permission='master'
                                render={() => <View display={Users} layout='master'
                                    title='Users' language={t} />} /> */}


                            { /* 404 */}

                            <Route render={() => <View display={NotFound}
                                layout='external' title="404 Not Found" />} />

                        </Switch>
                    </Router>
                </AuthProvider>
            </Provider>
        );
    }
}

export default withNamespaces()(App);
