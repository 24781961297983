import React from 'react';

import { Label } from '../label/label';
import { Error } from '../error/error';

import './input.scss';

export class URLInput extends React.Component {

  constructor(props){

    super(props);

    this.defaultErrorMessage = "Please provide a URL";
    this.validate = this.validate.bind(this);

  }

  validate(event){

    let value, valid = undefined;
    event ? value = event.target.value : value = '';

    // input is required and value is blank
    if (this.props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!this.props.required && value === '')
      valid = true;

    // test for a valid url
    const rex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

    if (rex.test(value)){

      valid = true;

    }
    else {

      valid = false;
      this.defaultErrorMessage = "Please enter a valid URL";

    }

    // update the parent form
    this.props.onChange(this.props.name, value, valid);

  }

  render(){

    let cssClass = 'textbox';
    let showError = false;

    if (this.props.valid === false) showError = true;
    if (this.props.valid === true) cssClass += ' success'
    if (this.props.className) cssClass += ' ' + this.props.className;

    return(

      <>
      <Label
        text={ this.props.label }
        required={ this.props.required }
        for={ this.props.name }
      />

      <input
        type='url'
        id={ this.props.id }
        name={ this.props.name }
        className={ showError ? cssClass + ' error' : cssClass }
        value={ this.props.value }
        placeholder={ this.props.placeholder }
        onChange={ event => this.props.onChange(this.props.name, event.target.value, undefined) }
        onBlur={ event => this.validate(event) }
      />

      { showError && <Error message={ this.props.errorMessage ?
        this.props.errorMessage : this.defaultErrorMessage }/>
      }

      </>

    );
  }
}
