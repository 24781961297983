import React from 'react';
import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import { getUsers } from '../../actions/adminAction';
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ViewContext, Button, Icon, Loader, LoadingButton } from '../../components/lib';
import { ACCOUNT_TYPE as account_type, STATUS as status, TIME as time } from '../../constants/Constants';
import moment from 'moment';
import UpdateForm from '../../components/form/UpdateForm';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, GET_DASHBOARD_ITEMS, UPDATE_DASHBOARD_ITEMS } from '../../apiurl';


class AppDashboardManagement extends React.Component {

  constructor(props) {

    super(props);
    this.access = [];
    this.component = "APP_MGMT"
    this.subComponent = "AD_APP_DASHBOARD"
    this.state = {
      loading: true,
      search: "",
      close: false,
      current_user: {},
      data: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      searchVal: '',
      columnSort: '',
      sortType: '',
      users: [],
      account: '',
      status: '',
      time: '',
      per_page: 10,
      page: 1,
      loadmoreBtn: false,
      loadmoreValue: '',
      btnStatus: false,
      btnColorLoad: '',
      btnTextLoad: 'Export Account Data',
      buttonStyle: { display: 'block' },
      // dashboardItems: [
      //   {
      //     id: "1",
      //     name: "DNA Results"
      //   },
      //   {
      //     id: "2",
      //     name: "Epigenetic Results"
      //   },
      //   {
      //     id: "3",
      //     name: "DNA 12"
      //   },
      //   {
      //     id: "4",
      //     name: "Health Insights"
      //   },
      //   {
      //     id: "5",
      //     name: "Genetic Action Plan"
      //   },
      //   {
      //     id: "6",
      //     name: "DNA Diet"
      //   },
      //   {
      //     id: "7",
      //     name: "Lifestyle Tracking"
      //   },
      //   {
      //     id: "8",
      //     name: "My Training"
      //   },
      //   {
      //     id: "9",
      //     name: "Air Quality Index"
      //   },
      //   {
      //     id: "10",
      //     name: "Virus Risk Score"
      //   },
      //   {
      //     id: "11",
      //     name: "Buy a Blood Test"
      //   },
      //   {
      //     id: "12",
      //     name: "Supplements"
      //   },
      //   {
      //     id: "13",
      //     name: "Health Monitoring"
      //   },
      //   {
      //     id: "14",
      //     name: "Shop"
      //   },
      // ],
      dashboardItems: [],
      otherItems: []
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    let partner_id = this.getPartnerFromLocal()
    await axiosCall('post', LAMDA_APICALL, {
      url: GET_DASHBOARD_ITEMS,
      method: "POST",
      data: { "partner_id": partner_id }
    }, (response) => {
      console.log("resp", response)
      if (response && response.data)
        this.setState({
          dashboardItems: response.data.dashboardItems,
          otherItems: response.data.otherItems,
          loading: false
        })
    })

  }

  componentDidUpdate(prevProps) {
    if (this.props.error != prevProps.error) {
      this.context.showNotification(this.props.error, 'error', true)
    }
  }
  getPartnerFromLocal = () => {
    var id = localStorage.partner_idd && parseInt(localStorage.partner_idd)
      ? parseInt(localStorage.partner_idd) : (localStorage.partner_id && parseInt(localStorage.partner_id)
        ? parseInt(localStorage.partner_id) : 0)

    console.log(id, 'getPartnerFromLocal--------------')
    return id
  }

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    console.log("dashboardItems", this.state.dashboardItems)
    const items = Array.from(this.state.dashboardItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({ dashboardItems: items })
  }

  removeItem = (id, index) => {
    console.log(id, index, " id number closed")
    const items = Array.from(this.state.dashboardItems);
    let newItems = items.splice(index, 1)
    console.log({ newItems, items })

    const otherItems = [...this.state.otherItems, ...newItems]
    this.setState({ dashboardItems: items, otherItems })
  }

  addItem = (id, index) => {
    console.log(id, index, " id number added")
    const items = Array.from(this.state.otherItems);
    let newItems = items.splice(index, 1)
    console.log({ newItems, items })

    const dashboardItems = [...this.state.dashboardItems, ...newItems]
    this.setState({ dashboardItems, otherItems: items })
  }

  updateItems = async () => {
    let items = this.state.dashboardItems.map((item, index) => {
      return {
        category_code: item.key,
        order: index + 1,
        enabled: true
      }
    })
    const count = items.length
    this.state.otherItems.map((item, index) => {
      items.push({
        category_code: item.key,
        order: count + index + 1,
        enabled: false
      })
    })
    const body = {
      partner_id: this.getPartnerFromLocal(),
      items
    }
    console.log("body of update", body)
    // this.setState({ loading: true })
    // await axiosCall('post', LAMDA_APICALL, {
    //   url: UPDATE_DASHBOARD_ITEMS,
    //   method: "POST",
    //   data: body
    // }, (response) => {
    //   console.log("resp", response)
    //   this.context.showNotification("Sucessfully Updated", 'success', true);
    //   this.setState({ loading: false })

    // })


    let modalForm = {
      partner_id: {
        type: 'hidden',
        value: this.getPartnerFromLocal(),
      },
      items: {
        type: 'hidden',
        value: items
      }
    }
    this.context.showModal({
      lambda_method: 'POST',
      lamda: UPDATE_DASHBOARD_ITEMS,
      method: 'POST',
      title: 'Update Menu',
      form: modalForm,
      buttonText: 'Update',
      text: 'Are you sure you want to update the menu items?',
      url: LAMDA_APICALL,
      method: 'POST'

    }, () => {
      this.context.showNotification("Sucessfully Updated", 'success', true);
    });
  }
  render() {
    const { loading, data, partner, dashboardItems, otherItems } = this.state;

    if (this.state.loading)
      return <Loader />
    else
      return (
        <section className="dragdrop-wrp">
          <h5>The following menus are shown in the Genetic Dashboard of the app.</h5>
          <div className='dashboard-container'>
            <div className='dashboard-1 mb-35'>
              <p>Drag and drop items to rearrange order of the items.</p>
              <p>Click '-' button to remove from the menu</p>
              <DragDropContext onDragEnd={this.handleOnDragEnd}>
                <Droppable droppableId="dashboardItems">
                  {(provided) => (
                    <ul className="dashboardItems" {...provided.droppableProps} ref={provided.innerRef}>
                      {dashboardItems.map(({ id, name, image_url }, index) => {
                        return (
                          <Draggable key={id} draggableId={id.toString()} index={index}>
                            {(provided) => (
                              <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div className='dashboard-button'>
                                  {/* <Button className="close" onClick={this.handleClose(id)}> x </Button> */}
                                  <button size='mini'
                                    style={{
                                      backgroundColor: '#00344f', padding: '0',
                                      float: 'right', marginLeft: '15px', top: '12px',
                                      position: 'relative', borderRadius: "5px", height: "20px"
                                    }}
                                    onClick={() => this.removeItem(id, index)}>
                                    <Icon color="light" image="minus" size="20" />
                                  </button>
                                  <div className='menu-item'>
                                    {/* <div style={{ maxWidth:'100%', width: '200px', paddingTop: '10px' }}> */}
                                    <img src={image_url} />
                                    {name}
                                  </div>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <div className='dashboard-2'>
              <p style={{ fontWeight: '600', textAlign: 'center' }}>App Preview</p>
              <ul className="dashboardPreview">
                {dashboardItems.map(({ id, name, image_url }, index) => {
                  return (
                    <li>
                      <div className='dashboard-button'>
                        <div className='menu-item'>
                          <img src={image_url} />
                          {name}
                        </div>
                      </div>
                    </li>
                  )
                }
                )}

              </ul>
            </div>
          </div>
          <Button className='sUserAdd upKit' text='Update' action={this.updateItems} />

          {otherItems.length > 0 &&
            <div className="dashboard-1">
              <h5>The following menus are not shown in the Genetic Dashboard of the app.</h5>
              <p>Click + button to add to the menu:</p>
              <ul className='dashboardItems' style={{ maxWidth: '50%' }}>
                {otherItems.map(({ id, name, image_url }, index) => {
                  return (
                    <li key={id}>
                      <div className='dashboard-button'>
                        <button size='mini'
                          style={{
                            backgroundColor: '#00344f', padding: '0',
                            float: 'right', marginLeft: '15px', top: '12px',
                            position: 'relative', borderRadius: "5px", height: "20px"
                          }}
                          onClick={() => this.addItem(id, index)}>
                          <Icon color="light" image="plus" size="20" />
                        </button>
                        {/* <div style={{ paddingTop: '10px' }}>
                          <img src={image_url} />
                          {name}</div> */}
                        <div className='menu-item'>
                          <img src={image_url} />
                          {name}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          }
        </section>
      );
  }
}
AppDashboardManagement.contextType = ViewContext;
const mapStateToProps = state => ({
  user: state.users.user,
  error: state.error.error,
  partner: state.admin.partner,
})
export default connect(mapStateToProps, { getUsers })(AppDashboardManagement);
