import React from 'react';
import './error.scss';

export class Error extends React.Component {

  render(){
    return(

      <div className='error-message'>{ this.props.message }</div>

    );
  }
}
