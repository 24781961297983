import React from 'react';
import { Link } from 'components/lib';

export class NotFound extends React.Component {

  render(){
    return(
      <div className="message">
        <h1 className="large">404</h1>
        <p>Sorry, we couldn't find that page</p>
        {/* <Link url='/dashboard' class='btn btn-green' text='Back to Dashboard' /> */}
      </div>
    );
  }
}
