/***
*
*   SUB NAV
*   Sub navigation element (located underneath the header).
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import './sub.scss';

export class SubNav extends React.Component {

  render(){
    return(

      <nav className="subnav">
        { this.props.items &&
          this.props.items.map(item => {

          return (
            <NavLink
              key={ item.label }
              to={ item.url }
              activeClassName='active'>

              { item.label }

            </NavLink>
          );
        })}
      </nav>

    );
  }
}
