import React from 'react';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';
import memoize from 'memoize-one';
import { axiosCall } from '../../helpers';
import { ADD_KIT_SINGLE, BULK_CSV_IMPORT, BULK_CSV_UPLOAD, LAMDA_APICALL,KIT_TRACKING_HISTORY,KIT_DELETE,KIT_TRACKING_DETAILS } from '../../apiurl';
import { getKitId } from '../../actions/userAction';
import { connect } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import moment from 'moment';

const columns = memoize((clickHandler, success) => [
  {
    name: 'Kit Number',
    selector: 'kit_id',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Upload Date',
    cell: (row) => <div>{moment(row.createdAt).format("YYYY-MM-DD hh:mm A")}</div>,
  },
  {
    name: 'User Present',
    cell: (row) => <div>{(row.user_present) ? "Yes" : "No"}</div>,
  },
  {
    name: 'Valid Status',
    cell: (row) => <div>{(row.statusValid) ? "Yes" : "No"}</div>,
    hide: 'md',
  },
  {
    name: 'Kit Status',
    cell: (row) => <div>{row.status}</div>,
    hide: 'md',
  },
  {
    name: 'User',
    cell: (row) => <div>{row.name}</div>,
    hide: 'md',
  },
  {
    name: 'Uploaded',
    cell: (row) => <div>{success? "Updated": "No"}</div>,
    hide: 'md',
  },
  // {
  //   name: 'Tracking Number',
  //   cell: (row) => <div>{row.tracking_details ? (row.tracking_details.TrackingNumber ? <a href ={row.tracking_details.TrackingLink} target="_blank" >{row.tracking_details.TrackingNumber}</a> : 'Not available') : <button className={`tblAction fas ${row.id} fa-redo`} onClick={clickHandler} name="reload" id={row.kit_id} row_id={row.id} title="Reload tracking details"></button>}</div>,
  // 
  // {
  //   name: 'Tracking Number',
  //   cell: (row) => <div>{row.tracking_details ? (row.tracking_details.TrackingNumber ? <a href ={row.tracking_details.TrackingLink} target="_blank" >{row.tracking_details.TrackingNumber}</a> : 'Not available') : <button className={`tblAction fas ${row.id} fa-redo`} onClick={clickHandler} name="reload" id={row.kit_id} row_id={row.id} title="Reload tracking details"></button>}</div>,
  // },
  // {
  //   name: 'Raw Data',
  //   hide: 'md',
  //   cell: (row) => <div><a href="">View Data</a></div>,
  // },
  // {
  //   name: 'Actions',
  //   cell: (row) => <div><button className={`tblAction fas fa-ban ${row.user_id === "NA" ? "" : "disabled"}`} onClick={clickHandler} id={row.id} name="delete" title="Disable kit"></button><button className="tblAction fas fa-eye" onClick={clickHandler} name="view" id={row.kit_id} title="View raw data"></button><button className="tblAction fas fa-history" onClick={clickHandler} name="tracking" id={row.kit_id} title="View tracking history"></button></div>,
  //   ignoreRowClick: true,
  //   allowOverflow: true,
  //   button: true,
  // },
]);

class bulkUpload extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      data: [],
      loading: false,
      reload: false,
      perPage: 100,
      searchVal: '',
      prevData: [],
      blackListedState: null,
      loadmoreBtn: false,
      loadmoreValue: '',
      btnStatus: false,
      btnColorLoad: 'blue',
      btnTextLoad: 'Upload',
      btnTextLoading: 'Uploading',
      uploadSuccess: false,
      buttonStyle: { display: 'block' },
      claimOption: [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ],
      claimVal: { value: null, label: 'All' },
      validVal: { value: null, label: 'All' },
      claimedState: null,
      validOption: [
        { value: null, label: 'All' },
        { value:false , label: 'Yes' },
        { value:true, label: 'No' }
      ]

    };

    this.addKitId = {
      kit_id: {
        label: 'Kit ID',
        type: 'text',
        required: true,
      },
      tenant_id: {
        type: 'hidden',
        value: '5c7e70f3ebc82935c7241e44'
      }
    };

    this.deleteUserForm = {
      is_deleted: {
        type: 'hidden',
        value: null
      }
    }
    this.uploadKitId = this.uploadKitId.bind(this);
  }

  async componentDidMount() {
    console.log("composnet did mount", this.state.data)
    this.setState({ loading: true });

    this.setState({
      data: [],
      prevData: []
    });
    await this.checkButtonStaus();
    this.setState({ loading: false });
  }


  checkButtonStaus = async (val) => {
    console.log("check button status")
    console.log("value", val)
    let result = []
    this.state.prevData.map((items)=>{
      if(items.kit_id.includes(val)){
        result.push(items)
      }
      // if(val.includes(items.email)){
      //   console.log("pushedd")
      //   result.push(items)
      // }
    })
    this.setState({data:result})
  };

  uploadJson = async () => {
    console.log("Button clicked", this.state.data)
    let data = this.state.data
    this.setState({btnStatus: true})
    // delete data.statusValid
    // delete data.user_present
    setTimeout(()=>{
      this.setState({btnStatus: false, uploadSuccess: true})
    }, 5000)
    console.log("end", data)
    for(let i =0; i< data.length;i++){
      let extra = "";
      let new_status = data[i].status;
      if(data[i].status === "RESULT_READY_DNA"){
        extra = "DNA"
        new_status = "RESULT_READY"
      }
      if(data[i].status === "RESULT_READY_EPI"){
        extra = "EPIGENETICS"
        new_status = "RESULT_READY"
      }
      data[i].status = new_status
      data[i].extra = extra
      data[i].notes = "Updated via SuperAdmin (Bulk)"
      delete data[i].statusValid
      delete data[i].user_present
    }
    console.log("result", data)
    await axiosCall('post', LAMDA_APICALL, {
      url: BULK_CSV_UPLOAD,
      method: "POST",
      data: {
        kits: data
      }
    }, (response) => {
      if (response.status) {
        this.context.showNotification("Imported sucessfully", 'success', true);
      } else {
        this.context.showNotification("Some error occured !...", 'error', false);
      }
    });
  }

  searchCall = async (val) => {
    console.log("search ", val)
    console.log(this.state.prevData, this.state.data)
    if(this.state.data.length < 1){
      this.context.showNotification("No data present to search!...", 'error', false);
    }
    if(val === ""){
      this.setState({data: this.state.prevData })
    }
    await this.setState({ searchVal: val, loadmoreValue: '' });
    // this.setState({ data: this.props.kitDetails.xx.Items });
    await this.checkButtonStaus(val);
  };

  uploadKitId = async () => {
    await this.context.showModal({
      title: "Upload Bulk Kit",
      uploadBulk: true,
      csvCallback: this.importJson
    });
  }

  importJson = async (json) => {
    console.log("json", json);

    if(json.report){
        console.log("was printed")
        this.context.showNotification(json.error, 'error', false);
    } else if (json.length > 0) {

      await axiosCall('post', LAMDA_APICALL, {
        url: BULK_CSV_IMPORT,
        method: "POST",
        data: {
          kits: json
        }
      }, (response) => {
        if (response.status) {
          this.context.hideModal(true);
          this.context.showNotification("Sucessfully completed", 'success', true);
          console.log("data", response)
          this.setState({data: response.data.results, prevData: response.data.results, uploadSuccess: false })
        } else {
          this.context.hideModal(true);
          this.context.showNotification("Some error occured !...", 'error', false);
        }
      });

    } else {
      this.context.showNotification("Please upload a valid CSV file", 'error', false);      
    }

  }


  render() {

    const { loading, data, totalRows } = this.state;

    createTheme('solarized', {
      text: {
        primary: '#717171',
        secondary: '#268bd2',
      },
      background: {
        default: '#ffffff',
      },
      divider: {
        default: '#1595c5',
      },
      button: {
        default: 'rgb(38, 139, 210)',
        hover: 'rgba(0,0,0,.08)',
        focus: 'rgba(255,255,255,.12)',
        disabled: 'rgba(0,0,0,.12)',
      },
    });
    return (
      <div>
        <div className="tableHeadMain">
          <div className="tsecOne">
            <SearchDatatable searchAll={this.searchCall} />
          </div>
          <div className="tsecTwo">
            <Button className='sUserAdd upKit' text=' Upload Bulk Kit' action={this.uploadKitId} />
          </div>
        </div>

        <div className="superDataTable kitIdTable">
          {/* <div className="filterItemsKit">
            <Grid cols={3}>
              <Filter options={this.state.claimOption} selected={this.claim_filter} label={'Claimed'} value={this.state.claimVal} />
              <Filter options={this.state.validOption} selected={this.valid_filter} label={'Valid / Invalid'} value={this.state.validVal} />
            </Grid>
          </div> */}
          <DataTable
            // title="Users"
            columns={columns(this.handleButtonClick, this.state.uploadSuccess)}
            data={data}
            progressPending={loading}
            onSort={this.handleSort}
            sortServer
            progressComponent={<DataLoader />}
            className="superDtableWrap"
            theme="solarized"
          />
          <div className="centerLoadBtn" style={this.state.buttonStyle}>
            {
              this.state.data.length > 0?
              <LoadingButton
                disabled={this.state.btnStatus}
                color={this.state.btnColorLoad}
                className='upload'
                text={this.state.btnStatus? this.state.btnTextLoading : this.state.btnTextLoad}
                action={this.uploadJson}
                loading={this.state.loadmoreBtn}
              />
              : null
            }
          </div>
        </div>

      </div>
    );
  }
}
bulkUpload.contextType = ViewContext;

const mapStateToProps = state => ({
  kitDetails: state.admin.kitDetails
})
export default connect(mapStateToProps)(bulkUpload);
