/***
*
*   VERIFY ACCOUNT
*   Activated from the link send in the user email.
*   Triggers a reset on the server when opened.
*
**********/

import React from 'react';
import { ViewContext, Card, Form, History, Button, Loader } from 'components/lib';
import { axiosCall } from '../../helpers';
import { ACCOUT_VALIDATE_TOKEN, RESET_PASSWORD } from '../../apiurl';

export class Verify extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            dataStatus: false,
            loading: false
        };

        this.passwordform = {
            id: {
                type: 'hidden'
            },
            password: {
                label: 'Password',
                type: 'password',
                required: true
            },
            confirmPassword: {
                label: 'Confirm Password',
                type: 'password',
                required: true
            }

        }
        this.pStyle = {
            textAlign: 'center'
        };
    }

    componentDidMount = async () => {
        let url = window.location.href;
        let index = (url.lastIndexOf('/') + 1);
        let token = url.substring(index);
        this.passwordform.id.value = token;
        try {
            this.setState({ loading: true })
            await axiosCall('get', ACCOUT_VALIDATE_TOKEN + token, {}, async (response) => {
                await this.setState({ loading: false })
                if (response.status) {
                    this.setState({ dataStatus: true });
                } else {
                    this.setState({ dataStatus: false });
                }
            });

        }
        catch (err) {

            this.context.handleError(err);

        }
    }
    userMessage = async (res) => {
        this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
    };
    goHome = () => {
        History.push('/signin');
    };
    render() {
        if (this.state.loading) {
            return (
                <div className='selectcd' style={{margin:"auto",width: "100%"}}>
                    <Loader />
                </div>
            )
        }
        else if (this.state.dataStatus) {
            return (
                <Card>
                    <Form
                        data={this.passwordform}
                        url={RESET_PASSWORD}
                        method='POST'
                        redirect='/signin'
                        buttonText='Save'
                        callback={this.userMessage}
                    />
                </Card>
            );
        } else {
            return (

                <div style={this.pStyle}>
                    <h2>Token has been exipred!!!.. Please try to signin</h2>
                    <Button text='Go Back' action={this.goHome} />
                </div>

            );
        }
    }
}

Verify.contextType = ViewContext;
