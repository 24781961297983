/***
*
*   BUTTON
*   Standard button with callback function.
*
*   PROPS
*   text: button label
*   action: callback function executed on click
*   params: object passed to the callback function (optional)
*   color: red/blue (default: green)
*   className: apply a custom css class (optional)
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import './button.scss';

export class Button extends React.Component {

  render(){

    const cssClass = ClassNames({

      'btn': this.props.className !== 'btn-text',
      'btn-text': this.props.className === 'btn-text',
      [this.props.color]: this.props.color,
      [this.props.className]: this.props.className

    });
    const disabled = (this.props.disabled)? true:false
    return (

      <button
        disabled={disabled}
        className={ cssClass }
        onClick={
          event => {

            this.props.action &&
            this.props.action(this.props.params);

          }
        }>

        { this.props.text }

      </button>

    );
  }
}
