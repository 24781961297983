/***
*
*   BADGE
*   Inline text badge with background color.
*
*   PROPS
*   text: string to be displayed
*   color: blue/red/green/orange (default: purple)
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import './badge.scss';

export class Badge extends React.Component {

  render(){

    const cssClass = ClassNames({

      'badge': true,
      [this.props.color]: this.props.color

    });

    return (

      <span className={ cssClass }>
        { this.props.text }
      </span>

    );
  }
}
