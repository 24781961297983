import {GET_USERS, UPDATE_USER_EDIT,GET_DEMO_USER} from '../actions/types';

const initialState = {
    user: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            console.log(state, 'action.payload')
            return {
                ...state,
                user: action.payload
            };
        case UPDATE_USER_EDIT:
             console.log(state)
             console.log(action.payload, 'action.payload')
            let user = state.user.data.map(obj => action.payload._id === obj._id ? action.payload:obj)
            return {
                ...state,
                user:{
                    data:user
                }
            };
        case GET_DEMO_USER:
            console.log(state, 'action.payload')
            return {
                ...state,    
                demoDetails: action.payload
            }

        default:
            return state;
    }
}

