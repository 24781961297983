import React from 'react';
import { Label } from '../label/label';
import { Error } from '../error/error';
import './select.scss';

export class Select extends React.Component {

  constructor(props){

    super(props);

    // set the default
    if (!this.props.default){

      // if theres no default, show a please select option
      if (this.props.options){
        if (this.props.options[0]){
          if (this.props.options[0].value === 'unselected'){
            this.props.options.shift();
          }
        }
      }

      this.props.onChange(this.props.name, 'unselected', undefined);
      this.props.options.unshift({ value: 'unselected', name: 'Please select an option' });

    }
    else {

      this.props.onChange(this.props.name, this.props.default, undefined);

    }

    this.defaultErrorMessage = 'Please select an option';

  }

  change(event, option){

    let value, valid = undefined;

    if (event) value = event.target.value
    else if (option !== false) value = option;
    else value = 'unselected';

    // validate
    if (this.props.required && value === 'unselected')
      valid = false;
    else
      valid = true;

    this.props.onChange(this.props.name, value, valid);
    if (this.props.callback) this.props.callback(value)

  }

  render(){

    let cssClass = 'select';
    let showError = false;

    if (this.props.className) cssClass += ' ' + this.props.className;
    if (this.props.valid === false) showError = true;

    return(
      <>

      <Label
        text={ this.props.label }
        required={ this.props.required }
        for={ this.props.name }
      />

      <div className={ showError ? cssClass + ' error' : cssClass }>

        <select
          defaultValue={ this.props.default }
          onChange={ event => this.change(event) }
          id={ this.props.name }
        >
          { this.props.options &&
            this.props.options.map(option => {

            return (
              <option
                key={ option.value }
                value={ option.value }>
                { option.name }
              </option>
            );

          })}

        </select>

        { showError && <Error message={ this.props.errorMessage ?
          this.props.errorMessage : this.defaultErrorMessage }/>
        }
      </div>
      </>
    );
  }
}
