import React from 'react';
import { Icon } from 'components/lib';
import './Filter.scss';
import Select from 'react-select'
//Filter select bar component
//aditya@20022020
const style = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '20px'
  })
}
export class Filter extends React.Component {

  handleChange = selectedOption => {
    this.props.selected(selectedOption)
  };
  render() {
    return (
      <div >
        <div style={{ 'height': "20px" }}>
          <h3 style={{ 'textAlign': "center", "color": "lightblue" }}>{this.props.label}</h3>
        </div>
        <Select options={this.props.options} onChange={this.handleChange} styles={style} placeholder="Select" defaultValue={this.props.value} />
      </div>
    )
  }

}
