/***
*
*   LINK
*   Routes a new view within the application router. Always use
*   this instead of <a> to avoid reloading the page.
*
*   PROPS
*   url: the destination as defined in /app/app.js
*   title: link title
*   text: link text
*   className: apply a custom css class (optional)
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";

export class Link extends React.Component {

  render(){

    return(

      <NavLink
        exact to={ this.props.url }
        className={ this.props.className ? 'link ' + this.props.className : 'link' }
        title={ this.props.title && this.props.title }
        activeClassName='active'>

        { this.props.children ? this.props.children : this.props.text }

      </NavLink>

    );
  }
}
