/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value.
*
*   PROPS
*   label: string
*   loading: true/false to toggle loading animation (optional)
*   value:  numeric value
*   icon: icon image to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import { Loader, Card, ViewContext, Grid } from 'components/lib';
import { axiosCall } from '../../helpers';
import { GET_PARTNER } from '../../apiurl';
import './dashboard-box.scss';
import "components/new-global.scss";
import Moment from 'moment'

export class PartnerBox extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            count: 0,
            partner: 0,
            tVal: null,
            options: [{
                value: "ALL", label: "All"
            }],
        }
        this.category = "Partner"
        this.apiCall = this.apiCall.bind(this);
    }

    async componentDidMount() {
        console.log('componentDidMount')
        try {
            this.setState({
                loading: false,
                partner: this.getPartnerFromLocal(),
                tVal: "ALL"
            });
            this.apiCall()
        }
        catch (err) {
            this.context.handleError(err);
        }
    }
    getPartnerFromLocal() {
        var id = localStorage.partner_idd && parseInt(localStorage.partner_idd)
            ? parseInt(localStorage.partner_idd) : (localStorage.partner_id && parseInt(localStorage.partner_id)
                ? parseInt(localStorage.partner_id) : 0)

        console.log(id, 'getPartnerFromLocal')
        return id
    }



    async apiCall() {
        this.setState({ loading: true });
        console.log("Calling API")
        await axiosCall('get', GET_PARTNER, {}, (response) => {
            this.setState({ loading: false, });
            if (response.status) {
                response.data.forEach(partner => {
                    this.state.options.push({
                        value: partner.partnerId, label: partner.name
                    });
                });

            } else {
                this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
            }
        });
    }
    changed = (e) => {
        this.setState({ tVal: e.target.value })
        console.log(e.target.value)
        console.log(this.state.tVal)
        e.persist();

        this.props.partnerChanged(e)
    }

    render() {
        if (this.state.loading) {
            return (
                <div className='selectcd card'>
                    <Loader />
                </div>
            );
        }

        return (

            <Card>
                <h1>Partner</h1>
                <div className="selectBox" style={{ minHeight: "99px" }}>
                    <h1 style={{ marginBottom: "20px" }}></h1>
                    <select className="select__type__I" style={{ textAlignLast: "center" }} onChange={this.changed} value={this.state.tVal || ''}>
                        {this.state.options.map(function (item, i) {
                            return <option key={i} value={item.value}>{item.label}</option>
                        })}
                    </select>
                </div>
            </Card>

        );
    }
}

PartnerBox.contextType = ViewContext;