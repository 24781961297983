import React from 'react';
import '../label/label.scss';

export class Legend extends React.Component {

  render(){

    let cssClass = '';
    if (this.props.className) cssClass += this.props.className;
    if (this.props.required) cssClass += ' required';

    return(
      <legend className={ cssClass }>
      { this.props.text }
      </legend>
    );
  }
}
