/***
*
*   MASTER DASHBOARD
*   See an overview of your entire application and its usage.
*
**********/

import React from 'react';
import Axios from 'axios';

import { Card, Chart, Grid, Stat } from 'components/lib';

export class MasterDashboard extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,
      stats: null

    }
  }

  async componentDidMount(){

    try {

      const res = await Axios.get('/api/metrics/accounts');
      this.setState({ stats: res.data.metrics });

    }
    catch (err){

      this.context.handleError(err);

    }
  }

  render(){
    return(

      <>
      <Grid cols='3'>
        <Stat
          loading={ this.state.loading }
          value={ this.state.stats && this.state.stats.totalAccounts }
          label='Accounts'
          icon='users'
        />
        <Stat
          loading={ this.state.loading }
          value={ this.state.stats && this.state.stats.activeAccounts }
          label='Active'
          icon='happy'
        />
        <Stat
          loading={ this.state.loading }
          value={ this.state.stats && this.state.stats.churnedAccounts }
          label='Churned'
          icon='sad'
        />
      </Grid>

      <Card title='User Growth'>
        <Chart
          loading={ this.state.loading }
          type='line'
          data='/api/metrics/accounts/growth'
          object='growth'
          color='green'
        />
      </Card>
      </>
    );
  }
}
