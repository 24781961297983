/***
*
*   AUTHENTICATION
*   Creates an auth provider to manage auth functions throughout
*   the application. Also defines a <PrivateRoute> component to
*   protect internal application routes from unauthenticated access.
*
**********/

import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addUser, addPartnerId } from '../actions/userAction';
import { getMenu } from '../helpers'
import axios from 'axios'
// import {History} from '../components/lib'
// auth context
export const AuthContext = React.createContext()
const axiosUserContext = {};;
class AuthProvider extends React.Component {

  constructor(props) {

    super(props);

    const user = JSON.parse(localStorage.getItem('user'));


    this.user = {
      name: user ? user.name : null,
      email: user ? user.email : null,
      status: user ? user.status : null,
      permission: user ? user.UserRoleMapping[0].Role.category : null,
    }


    this.signin = this.signin.bind(this);
    this.signout = this.signout.bind(this);

  }

  permission() {

    return getPermission(this.user.permission);

  }

  async signin(res) {
    if (res.data.status) {
      localStorage.setItem('user', JSON.stringify(res.data.data));

      if (res.data.data.Partner) {
        const menu = getMenu()
        let pName = res.data.data.Partner.name;
        // let pName = pNameRef.Partner.name

        let uri = pName ? pName.replace(' ', '_').toLowerCase() : null;

        var link = '/adminDashboard/' + uri;
        if (menu && menu[0]) {
          if (menu[0].link) {
            let Menulink = menu[0].link;

            if (Menulink === '/adminDashboard/') {
              link = '/adminDashboard/' + uri;
            } else {
              link = Menulink;
            }
          } else if (menu[0].sub && menu[0].sub[0] && menu[0].sub[0]['link']) {
            link = menu[0].sub[0]['link'];
          }
        }
        window.location = link
      } else if (res.data.data.UserRoleMapping[0].Role.category === 'master') {
        window.location = '/dashboard';
      }
      else {
        window.location = '/user-management'

      }
    }
  }

  signout() {

    localStorage.clear();
    window.location = '/signin'

  }
  async componentDidMount() {
    if (localStorage.getItem('user'))
      this.props.addUser(localStorage.getItem('user'))

    let userLocal = JSON.parse(localStorage.getItem('user'));

    if (userLocal && userLocal.Partner && userLocal.Partner.partnerId) {
      await this.props.addPartnerId(userLocal.Partner.partnerId);
    }
  }


  render() {

    return (

      <AuthContext.Provider value={{

        user: this.user,
        signin: this.signin,
        signout: this.signout,
        permission: this.permission,
      }}

        {...this.props} />

    );
  };
}
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return Promise.resolve(response);
}, error => {
  console.log("error.reponse-->", error.response)
  if (error.response && error.response.status === 401) {
    console.log('unauthorized, logging out ...');
    localStorage.clear();
    window.location = '/signin'
  }
  return Promise.reject(error.data ?  error.data : error.response);
}
);

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export const PrivateRoute = ({ render: Render, permission: Permission, storeVal, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.Partner == null) {
        localStorage.setItem('partner_id', 0);
        localStorage.setItem('partner', "")
      } else if (user && user.Partner) {
        localStorage.setItem('partner_id', user.Partner.partnerId);
        let pName = user.Partner.name;
        let uri = pName ? pName.replace(' ', '_').toLowerCase() : null;
        localStorage.setItem('partner', uri)
      } else {
        localStorage.setItem('partner_id', 0);
        localStorage.setItem('partner', "")
      }
      if (user) {

        let permissions = getPermission(user.UserRoleMapping[0].Role.category);
        const menu = getMenu();

        if (permissions[Permission]) {
          let reqUrl = window.location.pathname.trim();
          let reqPath = reqUrl.split("/");

          if (reqPath[1] === "admin" && storeVal.getState('user').admin.partner === "0") {
            return <Redirect to='/dashboard' />
          }
          if (reqPath[1] === "adminDashboard" && storeVal.getState('user').admin.partner === "0") {
            return <Redirect to='/dashboard' />
          }
          return <Render />

        } else {
          console.log("Permission issue");
          if (window.location.pathname === '/') {
            console.log("ooooooo-4");
            return <Redirect to='/signin' />
          } else {
            console.log("ooooooo-5");
            return <Redirect to='/404' />
          }
        }
      }
      else {

        return <Redirect to='/signin' />;

      }
    }} />
  )
};

function getPermission(userType) {

  switch (userType) {

    case 'master':
      return { master: true, owner: true, admin: true, user: true };

    case 'admin':
      return { master: false, owner: false, admin: true, user: true };

    default:
      return { master: false, owner: false, admin: false, user: false };

  }
}

const mapStateToProps = state => ({
  user: state.admin.user,
  partnerId: state.admin.partner
});
export default connect(mapStateToProps, { addUser, addPartnerId })(AuthProvider);
