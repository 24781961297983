/***
*
*   ACTIONS
*   a list of action buttons for the table row
*
**********/

import React from 'react';
import './actions.scss';

export class Actions extends React.Component {

  render(){
    return(

      this.props.children

    );
  }
}
