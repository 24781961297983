import { apiCall } from '../../helpers';
import { LAMDA_APICALL, KIT_DELETE, GET_RAWDATA,KIT_TRACKING_HISTORY,EXPORT_REPORT } from '../../apiurl';

export const getRawData = async (data) => {
    try {
        let res = await apiCall('post', LAMDA_APICALL, {
            url: GET_RAWDATA,
            method: "POST",
            data: data
        }); console.log(res, 'response ')
        return res.data;

    } catch (e) {
        return e.message.message
    }
}
export const disableKit = async (id, data) => {

    return await apiCall('POST', LAMDA_APICALL, {
        url: `${KIT_DELETE}/${id}`,
        method: "DELETE",
        data: data
    })

}

export const trackingHistory = async (kit_id) => {

    try {
        let res = await apiCall('post', LAMDA_APICALL, {
            url: KIT_TRACKING_HISTORY,
            method: "POST",
            data: {
              kit_id: kit_id
            }
          });
        console.log(res, 'response ')
        return res.data;

    } catch (e) {
        return e.message.message
    }

}

export const PractitionerReport = async (req) => {

    try {
        let res = await apiCall('post', LAMDA_APICALL, {
            url: EXPORT_REPORT,
            method: "POST",
            data: req
          });
        console.log(res, 'response ')
        return res;

    } catch (e) {
        console.log(e.message,'message')
        return e.message
    }

}
