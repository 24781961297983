import React from 'react';

export class HiddenInput extends React.Component {

  render(){

    return(

      <input
        type='hidden'
        id={ this.props.name }
        name={ this.props.name }
        value={ this.props.value }
      />

    );
  }
}
