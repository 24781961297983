import React from 'react';
import moment from 'moment';
import UpdateUser from './UpdateUser';
import AccesControl from './AccesControl';
import Rawdata from './Rawdata';
import { validateKit, resendConfirm, releaseKit, deleteUser, getCountries} from './services'
import {
    Card,
    Icon,
    TabView, Loader, Table
} from '../lib';
const table_arr = [
    { name: 'status', title: 'STATUS', sort: true },
    { name: 'kit_id', title: 'KIT_ID', sort: true },
    { name: 'date_of_scanning', title: 'Date of Scanning', sort: false }
]
export default class UpdateFormUnconfirmed extends React.Component {

    constructor(props) {

        super(props);
    }
    state = {
        error: '',
        kit_ids: '',
        first_name: '',
        last_name: '',
        email: '',
        kit_id: '',
        phone: '',
        kit_relations: [],
        epigenetic_data: [],
        account_type: [],
        country: '',
        address_lane1: '',
        address_lane2: '',
        zipcode: '',
        city: '',
        newsletter: '',
        terms_and_condition: false,
        subscribed: false,
        birthday: '',
        gender: '',
        weight: '',
        weight_unit: '',
        height: '',
        height_unit: 'cm',
        created_at: moment().format("YYYY-MM-DD hh:mm A"),
        last_login: moment().format("YYYY-MM-DD hh:mm A"),
        loading: false,
        table: {
            header: table_arr,
            body: []
        },
        countries: []
    }
    async componentDidMount() {
        let countries = await getCountries();
        this.setState({ countries: countries })
    }
    componentDidUpdate(prevProps) {
        console.log(this.props.access,'accessasfaf')
        if (this.props.user) {
            if (prevProps.user != this.props.user) {
                this.setState({
                    first_name: this.props.user.first_name,
                    last_name: this.props.user.last_name,
                    email: this.props.user.email,
                    kit_id: this.props.user.kit_id,
                    phone: this.props.user.phone,
                    kit_relations: this.props.user.kit_relations,
                    epigenetic_data: this.props.user.epigenetic_data,
                    account_type: this.props.user.account_type,
                    status: this.props.user.status,
                    created_at: moment(this.props.user.createdAt).format("YYYY-MM-DD hh:mm A"),
                    last_login: moment(this.props.user.last_login).format("YYYY-MM-DD hh:mm A"),
                    country: this.props.user.country,
                    address_lane1: this.props.user.address_lane1,
                    address_lane2: this.props.user.address_lane2,
                    zipcode: this.props.user.zipcode,
                    city: this.props.user.city,
                    newsletter: this.props.user.newsletter,
                    terms_and_condition: this.props.user.terms_and_condition,
                    subscribed: this.props.user.subscribed,
                    birthday: this.props.user.birthday,
                    gender: this.props.user.gender,
                    weight: this.props.user.weight,
                    weight_unit: this.props.user.weight_unit,
                    height: this.props.user.height,
                    height_unit: this.props.user.height_unit,
                    table: {
                        header: table_arr,
                        body: this.props.user.kit_relations && this.props.user.kit_relations[0] ? this.props.user.kit_relations[0].children.map(da => (
                            {
                                status: da.status,
                                kit_id: da.kit_id,
                                date_of_scanning: moment(da.date_of_scanning).format('YYYY-MM-DD')
                            })) : []
                    }
                })
            }
        }
    }
 

    deleteUser = async () => {
        this.setState({ loading: true })
        let response = await deleteUser({ user_id: this.props.user._id, username: this.props.user.username, email: this.props.user.email });
        this.setState({
            error: response
        })

        window.location.reload(false);
    }
    resend = async () => {
        await this.setState({ loading: true })
        let res = await resendConfirm({"user_id":this.props.user._id,"username":this.props.user.username});
        this.setState({
            error: res
        })

        await this.setState({ loading: true })

    }
    render() {
        return (

            <div>
                <button className="closeBtn" onClick={this.props.close}>
                    <Icon color="dark" image="x" />
                </button>

                <h2>Account Profile</h2>
                <TabView
                    name="Billing"
                    labels={[
                        "Edit/Save",
                        "Resend Email",
                        "Delete"
                    ]}
                >
                    {/* USER SECTION */}
                    <Card><UpdateUser user={this.props.user} access = {this.props.access.includes('update')} confirmed={false}/></Card>
                    {/* KIT ID SECTION */}
                    <Card>
                        <div style={{ marginTop: "20px", marginLeft: "30%" }}>
                            {
                                this.state.loading ? (<div className='stat card'>
                                    <Loader />
                                </div>) : (
                                    <button className="addbtn" onClick={this.resend} disabled={this.state.error||!this.props.access.includes('resend')}>
                                        Resend Confirmation Email
                                    </button>)
                            }<p style={{ fontSize: "12px" }}>{this.state.error}</p>
                        </div>
                    </Card>
                    <Card>
                        <div style={{ marginTop: "10px" }}>
                            {!this.state.loading ? (
                                <div><p>Are you sure ? </p>
                                    <button className="addbtn" onClick={this.deleteUser} disabled={!this.props.access.includes('destroy')}>
                                        Delete
                                    </button></div>) : (<div><Loader /></div>)}
                        </div>
                    </Card>
                </TabView>
            </div>

        )
    }
}
