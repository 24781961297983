
import React from 'react';
import moment from 'moment';
import { getCountries, updateUser } from './services';
import { updateUserAction} from "../../actions/userAction";
import {connect} from "react-redux";
class UpdateUser extends React.Component {

    constructor(props) {

        super(props);
    }
    state = {
        loader:false,
        error: '',
        kit_ids: '',
        first_name: '',
        last_name: '',
        email: '',
        kit_id: '',
        phone: '',
        kit_relations: [],
        epigenetic_data: [],
        account_type: [],
        country: '',
        address_lane1: '',
        address_lane2: '',
        zipcode: '',
        city: '',
        newsletter: false,
        terms_and_condition: false,
        research_purpose: false,
        subscribed: false,
        birthday: '',
        gender: '',
        weight: '',
        weight_unit: '',
        height: '',
        height_unit: 'cm',
        created_at: moment().format("YYYY-MM-DD hh:mm A"),
        last_login: moment().format("YYYY-MM-DD hh:mm A"),
        loading: false,
        countries: []
    }
    async componentDidMount() {
        let countries = await getCountries();

        this.setState({ countries: countries })
    }
    componentDidUpdate(prevProps) {
        console.log(this.props.user,'country')
        if (this.props.user) {
            if (prevProps.user != this.props.user) {
              const {first_name,last_name,email,kit_id,phone,kit_relations,epigenetic_data
              ,account_type, status, country, address_lane1, address_lane2, zipcode, city, newsletter, terms_and_condition,research_purpose
              ,subscribed, birthday,gender, weight, weight_unit, height, height_unit} = this.props.user ; 
                this.setState({first_name,last_name,email,kit_id,phone,kit_relations,epigenetic_data
                  ,account_type, status, address_lane1, address_lane2, zipcode, city, newsletter, terms_and_condition,research_purpose
                  ,subscribed, birthday,gender, weight, weight_unit, height, height_unit,created_at: moment(this.props.user.createdAt).format("YYYY-MM-DD hh:mm A"),
                  last_login: moment(this.props.user.last_login).format("YYYY-MM-DD hh:mm A"),});
                this.setState({country:this.props.user.country&&this.props.user.country[0]?  this.props.user.country[0]._id:this.props.user.country})
            }
        }
    }
    updateUser = async () => {
        this.setState({loader:true})
        let data;
        if(this.props.confirmed === true) {
            console.log('ggggggggggg')
            data = {
                user_id:this.props.user._id,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                // kit_id: this.state.kit_id,
                phone: this.state.phone,
                country: this.state.country,
                address_lane1: this.state.address_lane1,
                address_lane2: this.state.address_lane2,
                zipcode: this.state.zipcode,
                city: this.state.city,
                newsletter: this.state.newsletter? this.state.newsletter : false,
                terms_and_condition: this.state.terms_and_condition ? this.state.terms_and_condition : false,
                research_purpose: this.state.research_purpose ? this.state.research_purpose : false,
                subscribed: this.state.subscribed? this.state.subscribed : false,
                birthday: this.state.birthday,
                gender: this.state.gender,
                weight: this.state.weight,
                weight_unit: this.state.weight_unit||'kg',
                height: this.state.height,
                height_unit: this.state.height_unit||'cm',
            };
        } else {
            data = {
                user_id:this.props.user._id,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                // kit_id: this.state.kit_id,
                phone: this.state.phone,
                country: this.state.country,
                address_lane1: this.state.address_lane1,
                address_lane2: this.state.address_lane2,
                zipcode: this.state.zipcode,
                city: this.state.city,
                newsletter: this.state.newsletter? this.state.newsletter : false,
                terms_and_condition: this.state.terms_and_condition ? this.state.terms_and_condition : false,
                subscribed: this.state.subscribed? this.state.subscribed : false,
                birthday: this.state.birthday,
                gender: this.state.gender,
                weight: this.state.weight,
                weight_unit: this.state.weight_unit||'kg',
                height: this.state.height,
                height_unit: this.state.height_unit||'cm',
            };
        }
       console.log(data)
        console.log(data,'adDDAWEAWE')
        let res = await updateUser(data);
       if (res.data){res.data.country = this.state.countries.filter((country) => country._id == res.data.country);
               await this.props.updateUserAction(res.data)}
        await this.setState({error:res.message,loader:false})
        let that = this;
        await setTimeout(()=>{
            that.setState({error:''})
        },2000)


    }

    
    render() {
        return (
          <div className="formGroup">
              <div className="ipBox">
                  <label>First Name</label>
                  <input type="text" placeholder="First Name" value={this.state.first_name} onChange={(e) => { this.setState({ first_name: e.target.value }) }} />
              </div>
              <div className="ipBox">
                <label>Email</label>
                  <input type="text" placeholder="Email" value={this.state.email} readOnly onChange={(e) => { this.setState({ email: e.target.value }) }} />
              </div>
              <div className="ipBox">
                  <label>Last Name</label>
                  <input type="text" placeholder="Last name" value={this.state.last_name} onChange={(e) => { this.setState({ last_name: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Kit ID</label>
                  <input type="text" placeholder="Account Kit ID"  value={this.state.kit_id} onChange={(e) => { this.setState({ kit_id: e.target.value }) }}  disabled/>
              </div>
              <div className="ipBox">
                  <label>Country</label>
                  <select className="ipSelect" onChange={(e) => { this.setState({ country: e.target.value }) }}>{this.state.countries.map((c) => <option key ={c._id}value={c._id} selected={c._id == this.state.country}>{c.name}</option>)}</select>
              </div>
              <div className="ipBox">
              <label>Account Type</label>
                  <input type="text" placeholder="Account Type" value={this.state.account_type} readOnly />
              </div>
              <div className="ipBox">
              <label>Mobile</label>
                  <input type="text" placeholder="Mobile Number" maxLength = {15}value={this.state.phone} onChange={(e) => { this.setState({ phone: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Child Kit IDs</label>
                  <input type="text" placeholder="Child Kit IDs" value={this.state.kit_relations ? this.state.kit_relations.length : 0} readOnly />
              </div>
              <div className="ipBox">
                    <label>Address Line 1</label>
                  <input type="text" placeholder="Address Line 1" value={this.state.address_lane1} onChange={(e) => { this.setState({ address_lane1: e.target.value }) }} />
              </div>
              <div className="ipBox">
                <label>Result Progressive</label>
                  <input type="text" placeholder="Result Progressive" value={this.props.user.account_type} />
              </div>
              <div className="ipBox">
              <label>Address Line 2</label>
                  <input type="text" placeholder="Address Line 2" value={this.state.address_lane2} onChange={(e) => { this.setState({ address_lane2: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>DOB</label>
                  <input type="date" placeholder="D.O.B" value={moment(this.state.birthday).format("YYYY-MM-DD")} onChange={(e) => { console.log("dob",e.target.value); this.setState({ birthday: moment(e.target.value).format("YYYY-MM-DD") }) }} />
              </div>
              <div className="ipBox">
              <label>Post Code</label>
                  <input type="text" placeholder="Post Code" value={this.state.zipcode} onChange={(e) => { this.setState({ zipcode: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Gender</label>
                  <select className="ipSelect" onChange={(e) => { this.setState({ gender: e.target.value }) }}><option value="male" selected={'male' == this.state.gender}>Male</option><option value="female" selected={'female' == this.state.gender}>Female</option></select>
              </div>
              <div className="ipBox">
                  <label>Country Code</label>
                  <input type="text" placeholder="Country Code"  onChange={(e) => { this.setState({ email: e.target.value }) }} />
              </div>
              <div className="ipBox">
              <label>Height</label>
                  <input type="text" placeholder="Height" value={this.state.height} onChange={(e) => { this.setState({ height: e.target.value }) }} />
                  <ul class="radiolist">
                      <li>
                          <label>
                              cm <input type="radio" value="cm" name="ht" onChange={(e) => {                     
                                  this.setState({
                                      height_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.height_unit === 'cm' || this.state.height_unit === 'CM'} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              ft
                              <input type="radio" name="ht" value="ft" onChange={(e) => {
                                  this.setState({
                                      height_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.height_unit === 'ft' || this.state.height_unit === 'FT'} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                  </ul>
              </div>
              <div className="ipBox">
              <label>Accepted T&Cs</label>
                  <input type="text" placeholder="Accepted T&Cs" readOnly />

                  <ul class="radiolist">
                      <li>
                          <label>
                              Yes <input type="radio" name="tc" value="true" onChange={(e) => {
                                  this.setState({
                                      terms_and_condition: true
                                  })
                              }} checked={this.state.terms_and_condition } />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              No<input type="radio" name="tc" value="false" onChange={(e) => {
                                  this.setState({
                                      terms_and_condition: false
                                  })
                              }} checked={!this.state.terms_and_condition } />
                              <span class="checkmark" ></span>
                          </label>
                      </li>
                  </ul>
              </div>
              <div className="ipBox">
              <label>Weight</label>
                  <input type="text" placeholder="Weight" value={this.state.weight} onChange={(e) => { this.setState({ weight: e.target.value }) }}/>
                  <ul class="radiolist">
                      <li>
                          <label>
                              kgs <input type="radio" name="wt" value="kg" onChange={(e) => {
                                  this.setState({
                                      weight_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.weight_unit === 'kg' || this.state.weight_unit === 'KG'} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              lbs
                                  <input type="radio" name="wt" value="lbs" onChange={(e) => {
                                  this.setState({
                                      weight_unit: e.currentTarget.value
                                  })
                              }} checked={this.state.weight_unit === 'lbs' || this.state.weight_unit === 'LBS'} />
                              <span class="checkmark" ></span>
                          </label>
                      </li>
                  </ul>
              </div>
              <div className="ipBox">
              <label>Newsletter & Offers</label>
                  <input type="text" placeholder="Newsletter & Offers" />
                  <ul class="radiolist">
                      <li>
                          <label>
                              Yes <input type="radio" name="nl" value="true" checked={this.state.newsletter} onChange={(e) => {
                                  this.setState({
                                      newsletter:true
                                  })
                                  console.log(e.target.value)
                              }} />
                              <span class="checkmark"></span>
                          </label>
                      </li>
                      <li>
                          <label>
                              No
              <input type="radio" name="nl" value="false" checked={!this.state.newsletter} onChange={(e) => {
                                  this.setState({
                                      newsletter: false
                                  })
                              }} />
                              <span class="checkmark" ></span>
                          </label>
                      </li>
                  </ul>
              </div>
              
              {this.props.confirmed === true &&
                <div className="ipBox">
                <label>Research Purposes</label>
                    <input type="text" placeholder="Research Purposes" readOnly />

                    <ul class="radiolist">
                        <li>
                            <label>
                                Yes <input type="radio" name="research" value="true" onChange={(e) => {
                                    this.setState({
                                    research_purpose: true
                                    })
                                }} checked={this.state.research_purpose } />
                                <span class="checkmark"></span>
                            </label>
                        </li>
                        <li>
                            <label>
                                No<input type="radio" name="research" value="false" onChange={(e) => {
                                    this.setState({
                                    research_purpose: false
                                    })
                                }} checked={!this.state.research_purpose} />
                                <span class="checkmark" ></span>
                            </label>
                        </li>
                    </ul>
                </div>
              }
              <div className="ipBox">
              <label>Created on</label>
                  <input type="text" placeholder="Created on" value={this.state.created_at} />
              </div>
              <div className="ipBox">
              <label>Last Login</label>
                  <input type="text" placeholder="Last Log in" value={this.state.last_login} />
              </div>
              <div className="ipBox">
              <label>Last Failed login</label>
                  <input type="text" placeholder="Last Failed log in" />
              </div>
              <div className={this.props.confirmed === true && "button_tab"}>
              <button className="addbtn" onClick={this.updateUser} disabled={!this.props.access}>
                  {this.state.loader?"Updating":"Update"}
              </button>
              </div>
              <div className={this.props.confirmed === true && "button_tab"}>
              <p>{this.state.error}</p>
              </div>
          </div>

        )
    }
}
const mapStateToProps = state => ({
})
export default connect(mapStateToProps, { updateUserAction })(UpdateUser);