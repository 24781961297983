import React from 'react';
import { updateAccess } from './services';
import { updateUserAction } from "../../actions/userAction";
import { connect } from "react-redux";
class AccesControl extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            loader: false,
            dna_results: true,
            stress: true,
            sleep: true,
            anti_ageing: true,
            injury_risk: true,
            gut_health: true,
            mental_health: true,
            skin_health: true,
            eye_health: true,
            muscle_health: true,
            heart_health: true,
            addiction: true,
            skin_ageing: true,
            action_plan: true,
            meal_giude: true,
            my_training: true,
            heart_health: true,
            lifestyle: true,
            epigenetic: true,
            on_call_doctor: false,
        }

        console.log(this.state, "controls")
    }
    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            console.log(this.props.user.access_controls, "this.props.user")
            if (this.props.user.access_controls) {
                const {
                    dna_results,
                    stress,
                    sleep,
                    anti_ageing,
                    injury_risk,
                    gut_health,
                    mental_health,
                    skin_health,
                    eye_health,
                    muscle_health,
                    heart_health,
                    addiction,
                    skin_ageing,
                    action_plan,
                    meal_giude,
                    my_training,
                    immunity,
                    lifestyle,
                    epigenetic,
                    on_call_doctor
                } = this.props.user.access_controls;
                this.setState({
                    dna_results : dna_results === undefined ? true : dna_results,
                    stress : stress === undefined ? true : stress,
                    sleep : sleep === undefined ? true : sleep,
                    anti_ageing : anti_ageing === undefined ? true : anti_ageing,
                    injury_risk : injury_risk === undefined ? true : injury_risk,
                    gut_health : gut_health === undefined ? true : gut_health,
                    mental_health : mental_health === undefined ? true : mental_health,
                    skin_health : skin_health === undefined ? true : skin_health,
                    eye_health : eye_health === undefined ? true : eye_health,
                    muscle_health : muscle_health === undefined ? true : muscle_health,
                    heart_health : heart_health === undefined ? true : heart_health,
                    addiction : addiction === undefined ? true : addiction,
                    skin_ageing : skin_ageing === undefined ? true : skin_ageing,
                    action_plan : action_plan === undefined ? true : action_plan,
                    meal_giude : meal_giude === undefined ? true : meal_giude,
                    my_training : my_training === undefined ? true : my_training,
                    immunity : immunity === undefined ? true : immunity,
                    lifestyle : lifestyle === undefined ? true : lifestyle,
                    epigenetic : epigenetic === undefined ? true : epigenetic,
                    on_call_doctor : on_call_doctor === undefined ? false : on_call_doctor
                });
            } else {
                this.setState({
                    dna_results: true,
                    stress: true,
                    sleep: true,
                    anti_ageing: true,
                    injury_risk: true,
                    gut_health: true,
                    mental_health: true,
                    skin_health: true,
                    eye_health: true,
                    muscle_health: true,
                    heart_health: true,
                    addiction: true,
                    skin_ageing: true,
                    action_plan: true,
                    meal_giude: true,
                    my_training: true,
                    loader: false,
                    immunity: true,
                    lifestyle: true,
                    epigenetic: true,
                    on_call_doctor: false
                });

            }
            console.log(this.state, " did update state now")
        }
    }

    updateAccess = async () => {
        let data = {};
        data.controls = this.state;
        data.user_id = this.props.user._id;
        this.setState({ loader: true })
        let response = await updateAccess(data);
        this.props.updateUserAction(response.data)
        await this.setState({ error: response.message, loader: false });
        let that = this
        await setTimeout(() => that.resetError(), 2000)
    }
    resetError = () => {
        this.setState({ error: '' })
    }
    render() {
      console.log("state", this.state)
        return (
            <div className="access-control">
        <div className="access-form">
          <p className="access-header">DNA results</p>
          <div className="dots">
            <div>
              <input type="checkbox" checked={this.state.dna_results} onClick={(e) => this.setState({ dna_results: !this.state.dna_results })} />
              <br></br>
              <span>DNA results</span>
            </div></div>
        </div>
        <div className="access-form">
          <p className="access-header">Health Insights</p>
          <div className="dots">
            <div>
              <input type="checkbox" checked={this.state.stress} onClick={(e) => this.setState({ stress: !this.state.stress })} />
              <br></br>
              <span>Stress</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.sleep} onClick={(e) => this.setState({ sleep: !this.state.sleep })} />
              <br></br>
              <span>Sleep</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.anti_ageing} onClick={(e) => this.setState({ anti_ageing: !this.state.anti_ageing })} />
              <br></br>
              <span>Anti-Ageing </span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.injury_risk} onClick={(e) => this.setState({ injury_risk: !this.state.injury_risk })} />
              <br></br>
              <span>Injury Risk</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.gut_health} onClick={(e) => this.setState({ gut_health: !this.state.gut_health })} />
              <br></br>
              <span>Gut Health</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.mental_health} onClick={(e) => this.setState({ mental_health: !this.state.mental_health })} />
              <br></br>
              <span>Mental Health</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.skin_health} onClick={(e) => this.setState({ skin_health: !this.state.skin_health })} />
              <br></br>
              <span>Skin Health</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.eye_health} onClick={(e) => this.setState({ eye_health: !this.state.eye_health })} />
              <br></br>
              <span>Eye Health</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.muscle_health} onClick={(e) => this.setState({ muscle_health: !this.state.muscle_health })} />
              <br></br>
              <span>Muscle Health</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.heart_health} onClick={(e) => this.setState({ heart_health: !this.state.heart_health })} />
              <br></br>
              <span>Heart Health</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.addiction} onClick={(e) => this.setState({ addiction: !this.state.addiction })} />
              <br></br>
              <span>Addiction</span>
            </div> <div>
              <input type="checkbox" value="option1" checked={this.state.skin_ageing} onClick={(e) => this.setState({ skin_ageing: !this.state.skin_ageing })} />
              <br></br>
              <span>Skin Ageing</span>
            </div>
             <div>
              <input type="checkbox" value="option1" checked={this.state.immunity} onClick={(e) => this.setState({ immunity: !this.state.immunity })} />
              <br></br>
              <span>Immunity</span>
            </div>
          </div>
        </div>
        <div className="access-form">
          <p className="access-header">
            Genetic Action Plan
              </p>
          <div className="dots">
            <div>
              <input type="checkbox" value="option1" checked={this.state.action_plan} onClick={(e) => this.setState({ action_plan: !this.state.action_plan })} />
              <br></br>
              <span>Action Plan</span>
            </div></div>
        </div>
        <div className="access-form">
          <p className="access-header">
            Meal Guide
              </p>
          <div className="dots">
            <div>
              <input type="checkbox" value="option1" checked={this.state.meal_giude} onClick={(e) => this.setState({ meal_giude: !this.state.meal_giude })} />
              <br></br>
              <span>Meal Guide</span>
            </div></div>
        </div>
        <div className="access-form">
          <p className="access-header"> My Training </p>
          <div className="dots">
            <div>
              <input type="checkbox" value="option1" checked={this.state.my_training} onClick={(e) => this.setState({ my_training: !this.state.my_training })} />
              <br></br>
              <span>My Training</span>
            </div>
            </div>
        </div>
        <div className="access-form">
          <p className="access-header">Lifestyle</p>
          <div className="dots">
            <div>
              <input type="checkbox" checked={this.state.lifestyle} onClick={(e) => this.setState({ lifestyle: !this.state.lifestyle })} />
              <br></br>
              <span>Lifestyle</span>
            </div></div>
        </div>

        <div className="access-form">
          <p className="access-header">Epigenetic Results</p>
          <div className="dots">
            <div style={{width : "18.6%"}}>
              <input type="checkbox" checked={this.state.epigenetic} onClick={(e) => {
                console.log(this.state.epigenetic) ;
                this.setState({ epigenetic: !this.state.epigenetic })
              }
            }
                 />
              <br></br>
              <span>Epigenetic Results</span>
            </div></div>
        </div>

        <div className="access-form">
          <p className="access-header">On Call Doctor</p>
          <div className="dots">
            <div style={{width : "18.6%"}}>
              <input type="checkbox" checked={this.state.on_call_doctor} onClick={(e) => {
                this.setState({ on_call_doctor: !this.state.on_call_doctor })
              }}
              />
              <br></br>
              <span>On Call Doctor</span>
            </div></div>
        </div>
        <button className="addbtn" onClick={this.updateAccess} disabled={this.state.error || !this.props.access}>
          {this.state.loader ? "Updating" : "Update"}</button>
        <p style={{ fontSize: "12px" }}>{this.state.error}</p>
      </div>

        )
    }
}
const mapStateToProps = state => ({})
export default connect(mapStateToProps, { updateUserAction })(AccesControl);