/***
*
*   PRICE PLANS
*   Pricing table for your landing or pricing page.
*
*   PROPS
*   plans: array of objects containing: name, plan_id (from Stripe),
*   price (integer), currency (eg. $) and features (checklist array)
*
*   See https://docs.usegravity.app/ui/pricing for full example
*
**********/

import React from 'react';

import { Card, CheckList, Link } from 'components/lib';
import './plans.scss';

export class PricePlans extends React.Component {

  render(){
    return(

      <section className='plans'>
      { this.props.plans &&
        this.props.plans.map((plan) => {

          return (
            <Card className='plan' key={ plan.name }>

              <div className='name'>{ plan.name }</div>

              <div className='price'>
                <div className='value'>
                  { plan.currency }{ plan.price }
                </div>
                <div className='period'>{ plan.period }</div>
              </div>

              <CheckList items={ plan.features }/>
              <Link url={ '/signup#' + plan.id } className='btn' text='Sign Up'/>

            </Card>
          );

      })}
      </section>
    );
  }
}
