/***
*
*   BREADCRUMBS
*   Bavigation trail for nested pages.
*
*   PROPS
*   items: array of objects containing keys: name and url
*
**********/

import React from 'react';
import { Link } from 'components/lib';
import './breadcrumbs.scss';

export class Breadcrumbs extends React.Component {

  render(){
    return(
      <nav className="breadcrumb">
        { this.props.items &&
          this.props.items.map(item => {

          return (
            <Link
              key={ item.name }
              url={ item.url }
              text={ item.name }
            />
          );

        })}
      </nav>
    );
  }
}
