/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React from 'react';
import { AuthContext, ViewContext, Form, Card, Logo } from 'components/lib';
import { Select } from '../../components/form/select/select'
import { AutoFillEmailInput } from "../../components/form/input/autofillEmail";
import { PasswordInput } from "../../components/form/input/password";
import { axiosCall, getMenu } from '../../helpers';
import { GET_PARTNER_VIA_EMAIL, LOGIN } from '../../apiurl';
import { Link, LoadingButton, History } from 'components/lib';
import ClassNames from 'classnames';
import CryptoJS, { AES } from 'crypto-js'



export class SigninIdentifier extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      email: {
        valid: undefined,
        loading: false,
        value: '',
        previousValue: ''
      },
      partner: {
        loading: false,
        visibility: false,
        data: []
      },
      button: {
        disabled: true,
        text: "Next",
        loading: false,
      },
      form: {
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        },
        forgotpassword: {
          type: 'link',
          url: '/forgotpassword',
          text: 'Forgot your password?'
        }
      }
    }
    var enc = this.encrypt({ email: "nithin", value: 123 })
    console.log(enc)
    console.log(this.decrypt(enc))

  }

  getPartners = async (email) => {
    try {
      await axiosCall('post', GET_PARTNER_VIA_EMAIL, { email }, async (response) => {

        if (response.status) {
          if (response.data && response.data.length > 0) {

            await this.setState({
              partner: {
                loading: false,
                visibility: true
              },
              button: {
                disabled: false,
                text: "Next",
                loading: false,
              }
            });

            let data = []
            response.data.forEach(async p => {
              if (p.Partner) {
                data.push({
                  value: p.Partner.partnerId, name: p.Partner.name
                });
              }
            })
            await this.setState({
              partner: {
                visibility: data.length > 0,
                data: data,
                valid: data.length > 0,
                value: data.length > 0 ? data[0].value : null
              },
              button: {
                disabled: false,
                text: "Next",
                loading: false
              }
            });

          } else {
            // No user right now
            this.context.showNotification("There is no Muhdo account for this email you have entered.", "error", true);

            await this.setState({
              button: {
                disabled: true,
                text: "Next",
              }
            });


          }
        } else {
          this.context.handleError(response.message);
        }
      });
    }
    catch (err) {
      this.context.handleError(err);
    }
  }

  _onChange = async (input, value, valid) => {
    await this.setState({
      email: {
        value, valid,
      },
      partner: {
        loading: true,
        visibility: false
      },
      button: {
        disabled: true,
        text: "Next",
        loading: false
      }
    });
  }

  _onBlurEmail = async (input, value, valid) => {
    console.log("on _onBlurEmail calling")
    if (this.state.email.previousValue === value) {
      console.log("email not changed")
      return
    }
    await this.setState({
      email: {
        value, valid,
      },
      partner: {
        loading: true,
        visibility: false
      },
      button: {
        disabled: true,
        text: "Next",
        loading: false
      }
    });

    if (valid) {
      console.log(valid)
      // calling dropdown
      await this.setState({
        button: {
          disabled: true,
          text: "Loading",
          loading: true,
        },
        email: { value, valid, previousValue: value }
      });
      await this.getPartners(value)

    }
  }

  submit = async (e) => {

    var disabled = this.state.button.disabled || !this.state.email.valid

    e.preventDefault();

    if (disabled) return
    var enc = this.encrypt(this.state.email.value)
    console.log(this.encrypt(this.state.email.value))
    console.log(this.decrypt(enc))
    History.push(`/signin/pwd/${encodeURIComponent(this.encrypt({ email: this.state.email.value, partner: this.state.partner.value ? this.state.partner.value : 0 }))}`)
  }

  encrypt = (str) => {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(str), 'vfs^*%rt');
    return ciphertext.toString()
  }
  decrypt = (ciphertext) => {
    var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), 'vfs^*%rt');
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  async signin(res) {
    if (res.status) {
      localStorage.setItem('user', JSON.stringify(res.data));

      if (res.data.Partner) {
        const menu = getMenu()
        let pName = res.data.Partner.name;
        // let pName = pNameRef.Partner.name

        let uri = pName ? pName.replace(' ', '_').toLowerCase() : null;

        var link = '/adminDashboard/' + uri;
        if (menu && menu[0]) {
          if (menu[0].link) {
            let Menulink = menu[0].link;

            if (Menulink === '/adminDashboard/') {
              link = '/adminDashboard/' + uri;
            } else {
              link = Menulink;
            }
          } else if (menu[0].sub && menu[0].sub[0] && menu[0].sub[0]['link']) {
            link = menu[0].sub[0]['link'];
          }
        }
        window.location = link
      } else if (res.data.UserRoleMapping[0].Role.category === 'master') {
        window.location = '/dashboard';
      }
      else {
        window.location = '/user-management'

      }
    }
  }
  render() {
    const cssClass = ClassNames({
      'loading': this.state.loading,
      [this.props.className]: this.props.className
    })
    var disabled = this.state.button.disabled || !this.state.email.valid
    // console.log(this.state.button.disabled, !this.state.email.valid, !this.state.password.valid)

    console.log(this.state.partner, this.state.email)
    return (

      <>
        <Logo />
        <Card>
          <AuthContext.Consumer>
            {(context) => {

              // return <Form
              //   data={this.signinForm}
              //   url='/api/user/auth'
              //   method='POST'
              //   buttonText='Next'
              //   callback={context.signin}
              // />
              return (
                <form
                  method="POST"
                  onSubmit={this.submit}
                  className={cssClass}
                  noValidate>
                  <AutoFillEmailInput
                    key={"email"}
                    type='text'
                    form="signin"
                    label={"EMAIL"}
                    className={''}
                    name={'email'}
                    required={true}
                    onChange={this._onChange}
                    onBlur={this._onBlurEmail}
                    valid={this.state.email.valid}
                    autoComplete={'off'}
                    autoFocus={true}
                  />
                  <div style={{ minHeight: '0px' }} >
                    <div style={this.state.partner.visibility ? {} : { display: 'none' }} ><Select
                      key={"partner"}
                      form="signin"
                      label={"Partner"}
                      className={''}
                      name={'partner'}
                      required={true}
                      options={this.state.partner.data}
                      onChange={async (input, value, valid) => {
                        this.setState({
                          partner: {
                            valid: true, value: parseInt(value),
                            visibility: this.state.partner.visibility, data: this.state.partner.data
                          }
                        })
                      }}
                    />
                    </div>
                  </div>

                  <div style={{ display: 'none' }} >
                    <PasswordInput
                      key={"password"}
                      form="signin"
                      label={"password"}
                      className={''}
                      name={'Password'}
                      required={true}
                      onChange={async (input, value, valid) => { }}
                    ></PasswordInput>
                  </div>
                  <Link
                    url='/forgotpassword'
                    text='Forgot your password?'
                  />
                  <LoadingButton
                    loading={this.state.button.loading}
                    text={this.state.button.text}
                    // disabled={disabled}
                    className={disabled ? 'grey btn-disabled' : 'blue'}
                  />

                </form>

              )
            }}
          </AuthContext.Consumer>
        </Card>
      </>

    );
  }
}

SigninIdentifier.contextType = ViewContext;
