import React from 'react';
import { getRawData } from './service'
import { Loader, Table, Card, Grid, ViewContext } from '../../components/lib';
import { DNA_TYPE } from '../../../src/constants/Constants';
import { Filter } from '../../components/Filter/Filter'
const header = [
  { name: 'rsid', title: 'SNPs', sort: true },
  { name: 'index', title: 'Result', sort: true },
  { name: 'value', title: 'Value', sort: true }
]
const arr1 = DNA_TYPE;
export default class ViewRawData extends React.Component {

  state = {
    rawdata: {
      header: header,
      body: [],
      option1: arr1,
      option2: [],
      option3: [],
      op1: '',
      op2: '',
      op3: '',
      kitId: ''
    },
    loader: false
  }
  componentDidMount() {
    let url = window.location.href;
    let index = (url.lastIndexOf('/') + 1);
    let kitId = url.substring(index);
    if (!kitId) {
      History.push('/kit-ids')
    }
    this.setState({
      kitId: kitId.toUpperCase()
    })
  }
  changeRawData = async () => {
    console.log(this.state)
    if (this.state.kitId && this.state.op2 && this.state.op3) {
      await this.setState({ loader: true })
      try {
        let response = await getRawData({
          "kit_id": this.state.kitId,
          "mode": this.state.op2,
          "sub_mode": this.state.op3
        })
        if (response) {
          await this.setState({
            rawdata: {
              header: header,
              body: response.map(da => ({ rsid: da.rsid, index: da.index,value : da.value }))
            }, loader: false
          })
        }
      } catch (err) {
        this.context.showNotification("No raw data available", 'error', true)
        await this.setState({
          loader: false
        })
      }
    } else {
      this.setState({ loader: false })
    }
  }
  op1Change = async (dat) => {
    this.setState({ option2: dat.arr, op1: dat.value, op2: null, op3: null })
    await this.changeRawData();
  }
  op2Change = async (dat) => {
    await this.setState({ option3: dat.arr, op2: dat.value, op3: '' })

    await this.changeRawData();
  }
  op3Change = async (dat) => {
    await this.setState({ op3: dat.value })
    await this.changeRawData();
  }
  render() {
    return (
      <div>
        <div className="filter-dots" style={{ fontSize: "10px" }}>
          <Grid cols={3}>
            <Filter options={arr1} selected={this.op1Change} />
            <Filter options={this.state.option2} selected={this.op2Change} />
            <Filter options={this.state.option3} selected={this.op3Change} />
          </Grid>

        </div>
        <Table
          data={this.state.rawdata}
          loading={this.state.loader}
          badge={{ col: 'plan', color: 'blue' }}>
        </Table>
      </div>
    )
  }
}
ViewRawData.contextType = ViewContext;